import { API } from "aws-amplify";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import Pagination from "../components/Pagination";
import { formattedTime, limit, selectColor } from "../utils/utils";
import { promiseInstructors } from "./AddCourse";
import UpdateCredits from "./UpdateCredits";
import { promiseUsers } from "./Subscriptions";

const searchUserHotlineCredits = /* GraphQL */ `
  query SearchUserHotlineCredits(
    $filter: SearchableUserHotlineCreditsFilterInput
    $sort: [SearchableUserHotlineCreditsSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchUserHotlineCredits(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        userId
        user {
          id
          username
          name
        }
        description
        credits
      }
      nextToken
      total
    }
  }
`;
const searchCallLogs = /* GraphQL */ `
  query SearchCallLogs(
    $filter: SearchableCallLogFilterInput
    $sort: [SearchableCallLogSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchCallLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        callStart
        callEnd
        callDuration
        callAmount
        payoutAmount
        expert {
          name
          username
        }
        user {
          name
          username
        }
      }
      nextToken
      total
    }
  }
`;

const Transactions = () => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const btnRef = useRef();

  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [dateFilters, setDateFilters] = useState(null);
  const [user, setUser] = useState(null);
  const [showUpdateCredits, setShowUpdateCredits] = useState(false);
  const navigate = useNavigate();
  const [expert, setExpert] = useState(null);
  const [payoutDate, setPayoutDate] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const handleDateApplied = (event, picker) => {
    setTokens([]);
    const fromDate = Math.floor(
      moment(picker.startDate).startOf("day").valueOf()
    );
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setDateFilters({ start, end });
  };
  const handlePayoutDate = (event, picker) => {
    setTokens([]);
    const fromDate = Math.floor(
      moment(picker.startDate).startOf("day").valueOf()
    );
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setPayoutDate({ start, end });
  };
  const handleDownloadReport = async () => {
    try {
      setDownloading(true);
      let filter = {};
      if (payoutDate && expert) {
        filter = {
          createdAt: {
            lte: payoutDate?.end,
            gte: payoutDate?.start,
          },
          expertId: { eq: expert?.value },
        };
      }
      let token = null;
      let data = [];
      do {
        let res = await API.graphql({
          query: searchCallLogs,
          variables: {
            filter: filter,
            nextToken: token,
            sort: { direction: "desc", field: "createdAt" },
          },
        });

        token = res.data.searchCallLogs.nextToken;
        data = data.concat(res.data.searchCallLogs.items);
      } while (token);

      if (data.length === 0) return toast.error("No Data Found.");

      data = data.filter((item) => item.payoutAmount > 0);
      const total = data.reduce((acc, curr) => ({
        payoutAmount: acc.payoutAmount + curr.payoutAmount,
      }));

      let xlData = data.map((item) => {
        return {
          ["Call Start"]: moment(item?.callStart).format(
            "MM-DD-YYYY, h:mm:ss a"
          ),
          User: item?.user?.username || item?.user?.name,
          ["Call Duration (Mins)"]: item?.callDuration,
          ["Call Amount ($)"]: item.callAmount,
          ["Payout Amount ($)"]: item?.payoutAmount,
        };
      });
      const totalPayoutRow = {
        ["Call Start"]: "Total Payout",
        ["Payout Amount ($)"]: total?.payoutAmount,
      };

      xlData.push(totalPayoutRow);

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(
        wb,
        `Payout Report of ${expert?.label} - ${moment(payoutDate?.start).format(
          "MM-DD-YYYY"
        )} to ${moment(payoutDate?.end).format("MM-DD-YYYY")}.xlsx`
      );
      setExpert(null);
      setPayoutDate(null);
      btnRef.current.click();
    } catch (error) {
      console.log(error);
      toast.error("Failed to download report !");
    } finally {
      setDownloading(false);
    }
  };

  async function getData() {
    setLoading(true);
    try {
      let filter;
      if (user) {
        filter = { userId: { eq: user?.value } };
      }
      if (dateFilters) {
        filter = {
          createdAt: {
            lte: dateFilters?.end,
            gte: dateFilters?.start,
          },
        };
      }
      if (dateFilters && user) {
        filter = {
          createdAt: {
            lte: dateFilters?.end,
            gte: dateFilters?.start,
          },
          userId: { eq: user?.value },
        };
      }
      let res = await API.graphql({
        query: searchUserHotlineCredits,
        variables: {
          limit: limit,
          nextToken: nextToken,
          filter: filter,
          sort: { direction: "desc", field: "createdAt" },
        },
      });
      setLoading(false);
      setToken(res.data.searchUserHotlineCredits.nextToken);
      if (
        res.data.searchUserHotlineCredits.items.length === 0 &&
        dateFilters === null &&
        user === null
      ) {
        return;
      }
      setLogs(res.data.searchUserHotlineCredits.items);
      setTokens((prev) => [
        ...prev,
        res.data.searchUserHotlineCredits.nextToken,
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }

  useEffect(() => {
    getData();
  }, [nextToken, dateFilters, user]);

  return (
    <>
      <div className="container-fluid my-5">
        {/* header starts */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="col">
            <h6 className="header-pretitle">Overview</h6>
            <h1 className="header-title text-truncate">Transactions</h1>
          </div>
          <div>
            <button
              type="submit"
              style={{ background: "#50c7c3", border: "none" }}
              className="btn btn-primary me-2"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <span>Payout Report</span>
            </button>
          </div>
          <div>
            <button
              type="submit"
              style={{ background: "#50c7c3", border: "none" }}
              className="btn btn-primary me-2"
              onClick={() => setShowUpdateCredits("create")}
            >
              <span>Add Credits</span>
            </button>
          </div>
          <div>
            <button
              type="submit"
              style={{ background: "#50c7c3", border: "none" }}
              className="btn btn-primary "
              onClick={() => setShowUpdateCredits("delete")}
            >
              <span>Remove Credits</span>
            </button>
          </div>
        </div>
        {/* header ends */}
        <div className="recommended-experts-container-content">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="contactsListPane"
                  role="tabpanel"
                  aria-labelledby="contactsListTab"
                >
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col"></div>
                        <div className="col-auto" style={{ minWidth: 200 }}>
                          <AsyncSelect
                            isClearable
                            placeholder="Search by user"
                            key={user}
                            theme={selectColor}
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseUsers}
                            defaultValue={user}
                            onChange={(e) => {
                              setTokens([]);
                              setToken(null);
                              setUser(e);
                            }}
                          />
                        </div>
                        <div className="col-auto">
                          <DateRangePicker
                            key={dateFilters?.start}
                            initialSettings={{
                              startDate: moment(dateFilters?.start).format(
                                "MM-DD-YYYY"
                              ),
                              endDate: moment(dateFilters?.end).format(
                                "MM-DD-YYYY"
                              ),
                              linkedCalendars: true,
                              showCustomRangeLabel: true,
                              showDropdowns: true,
                              alwaysShowCalendars: true,
                              opens: "right",
                            }}
                            onApply={handleDateApplied}
                          >
                            <input
                              className={`btn ${
                                dateFilters ? "upload-btn" : "btn-white"
                              } ml-2`}
                              style={{ cursor: "pointer" }}
                            />
                          </DateRangePicker>
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-light"
                            onClick={() => {
                              setDateFilters(null);
                              setTokens([]);
                              setToken(null);
                              setNextToken(null);
                              setUser(null);
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <div className="d-flex justify-content-center m-4">
                          <img
                            src="/images/loader.svg"
                            alt="loader"
                            height={"100px"}
                          />
                        </div>
                      ) : (
                        <table className="table table-sm table-hover table-nowrap card-table">
                          <thead>
                            <tr>
                              <th>
                                <span className="text-muted">User Name</span>
                              </th>
                              <th>
                                <span className="text-muted">Date</span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">credits</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list fs-base">
                            {logs.map((item) => (
                              <tr key={item?.id}>
                                <td>
                                  <span
                                    className="item-title"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigate(`/user/${item?.user?.id}`)
                                    }
                                  >
                                    {item?.user?.username ||
                                      item?.user?.name ||
                                      "-"}
                                  </span>
                                </td>
                                <td>
                                  <span>{formattedTime(item?.createdAt)}</span>
                                </td>

                                <td className="text-center">
                                  <span
                                    className={`item-score badge ${
                                      item?.credits > 0
                                        ? "bg-success-soft"
                                        : "bg-warning-soft"
                                    }`}
                                  >
                                    <b className="fs-4">{item?.credits}</b>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                      {logs.length === 0 && !loading && (
                        <p className="text-center m-3">No Data </p>
                      )}
                      <Pagination
                        handleNext={handleNext}
                        handleFirst={handleFirst}
                        length={tokens?.length}
                        token={token}
                        total={logs?.length}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateCredits
        showUpdateCredits={showUpdateCredits}
        setShowUpdateCredits={setShowUpdateCredits}
        user={user}
        selectColor={selectColor}
        promiseUsers={promiseUsers}
        setToken={setToken}
        setTokens={setTokens}
        setUser={setUser}
        getData={getData}
        create={showUpdateCredits === "create"}
      />
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ fontSize: "20px", color: "#545454" }}
              >
                Payout Report
              </div>
            </div>
            <>
              {downloading ? (
                <img src="./images/loader.svg" alt="loader" height={100} />
              ) : (
                <>
                  <div className="modal-body">
                    <div className="mb-3 fw-bold">Select Expert</div>
                    <AsyncSelect
                      isClearable
                      placeholder="Search Expert"
                      theme={selectColor}
                      cacheOptions
                      defaultValue={expert}
                      loadOptions={promiseInstructors}
                      onChange={(e) => {
                        setExpert(e);
                      }}
                    />
                    <div className="my-3 fw-bold">Select Date</div>
                    <DateRangePicker
                      key={payoutDate?.start}
                      initialSettings={{
                        startDate: moment(payoutDate?.start).format(
                          "MM-DD-YYYY"
                        ),
                        endDate: moment(payoutDate?.end).format("MM-DD-YYYY"),
                        linkedCalendars: true,
                        showCustomRangeLabel: true,
                        showDropdowns: true,
                        alwaysShowCalendars: true,
                        opens: "right",
                      }}
                      onApply={handlePayoutDate}
                    >
                      <input
                        className={`btn ${
                          payoutDate ? "upload-btn" : "btn-white"
                        } ml-2`}
                        style={{ cursor: "pointer" }}
                      />
                    </DateRangePicker>
                  </div>
                </>
              )}
            </>
            <div className="modal-footer">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                  ref={btnRef}
                >
                  Close
                </button>
                <button
                  type="button"
                  disabled={!payoutDate || !expert || downloading}
                  style={{ background: "#50c7c3", border: "none" }}
                  className="btn btn-primary mx-3"
                  onClick={handleDownloadReport}
                >
                  <span>Download</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
