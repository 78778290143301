import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SingleCourse from "../components/SingleCourse";
import Pagination from "../components/Pagination";
import { limit, selectColor } from "../utils/utils";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { promiseInstructors } from "./AddCourse";

const searchCourses = /* GraphQL */ `
  query SearchCourses(
    $filter: SearchableCourseFilterInput
    $sort: [SearchableCourseSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchCourses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instructorId
        instructor {
          id
          username
          name
          media
        }
        lessons{
          items{
            id
          }
        }
        likes{
          items{
            id
          }
        }
        title
        duration
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

const Courses = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [instructor, setInstructor] = useState("");

  const [total, setTotal] = useState(0);
  const [tokens, setTokens] = useState([]);

  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [sort,setSort] = useState({field: "createdAt",direction : 'desc'})

  const user = useSelector((state) => state.userInfo.payload);
  let allowViewer = user.role.includes("VIEWER");

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }

  async function getData() {
    try {
    setLoading(true);
    let filter;
    let sortable = {field:sort?.field ,direction:sort?.direction}

      if(instructor){filter = {instructorId : {eq :instructor?.value}}}
      if(search.length !== 0){filter = { title: { matchPhrasePrefix: search } }}
      if(search.length !== 0 && instructor !== null){
        filter = { title: { matchPhrasePrefix: search } ,instructorId : {eq :instructor?.value}}
      }
      let res = await API.graphql({
      query: searchCourses,
      variables:{
        filter:filter,
        limit : limit,
        nextToken:nextToken,
        sort:sortable
      }
    })
      search.length === 0 && setTotal(res.data.searchCourses.total);
      setLoading(false);
      if (res.data.searchCourses.items.length < res.data.searchCourses.total ) {
        setTokens((prev) => [...prev, res.data?.searchCourses?.nextToken]);
        setToken(res.data.searchCourses.nextToken);
      }
      if (res.data.searchCourses.items.length === 0 && search.length === 0 && instructor === null) {
        return;
      }
      setCourses(res.data.searchCourses.items);
      } catch (error) {
      console.log(error)
      }
  }

  useEffect(() => {
    setLoading(true)
    let debounce = setTimeout(() => {
      getData();
    }, 1200);
    return () => clearTimeout(debounce);
  }, [search,nextToken,instructor,sort?.key,sort?.direction]);

  return (
    <div className="container-fluid" style={{ marginTop: "30px" }}>
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h6 className="header-pretitle">Overview</h6>

          <h1 className="header-title text-truncate">Courses ({total})</h1>
        </div>
        <div className="col-auto">
          <button
            onClick={() => navigate("/add-course")}
            className="btn btn-primary ms-2"
            style={{
              backgroundColor: "#50C7C3",
              border: "none",
              display: allowViewer && "none",
            }}
          >
            Add Course
          </button>
          <button
            onClick={() => navigate("/course-categories")}
            className="btn btn-primary ms-2"
            style={{ backgroundColor: "#50C7C3", border: "none" }}
          >
            Manage Categories
          </button>
        </div>
      </div>
      {/* header ends */}
      <div className="recommended-experts-container-content">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            className="form-control list-search"
                            type="search"
                            placeholder="Search by title"
                            onChange={(e) => {
                              setTokens([]);
                              setToken(null)
                              setSearch(e.target.value);
                            }}
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                      <div className="col-auto" style={{width:"300px"}} >
                        <AsyncSelect
                          isClearable
                          key={instructor} 
                          theme={selectColor}  
                          cacheOptions defaultOptions loadOptions={promiseInstructors} 
                          defaultValue={instructor}
                          placeholder="Select Instructor"
                          onChange={(e)=>{
                            setInstructor(e)
                            setToken([])
                            setToken(null)}}
                          />
                      </div>
                      <div className="col-auto">
                        <button className="btn btn-light" onClick={()=>{
                          setToken(null)
                          setTokens([])
                          setSort({field: "createdAt",direction : 'desc'})
                          setSearch("")
                          setInstructor(null)
                        }}>Reset</button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <div className="d-flex justify-content-center m-4">
                        <img
                          src="/images/loader.svg"
                          alt="loader"
                          height={"100px"}
                        />
                      </div>
                    ) : (
                      <table className="table table-sm table-hover table-nowrap card-table">
                        <thead>
                          <tr>
                            <th style={{ width: "40rem" ,cursor:"pointer"}}>
                              <span className="text-muted" data-sort="item-name" onClick={()=>{   
                              setSort({
                                field:"title",
                                direction : sort.direction === 'asc' ? 'desc' : 'asc'
                              })
                                 setTokens([]); setToken(null)
                              }}>Title</span>
                            </th>
                            <th>
                              <span className="text-muted">Instructor</span>
                            </th>
                            <th className="text-center" style={{cursor:"pointer"}} data-sort="item-name"  onClick={()=>{
                                 setSort({
                                  field:"lessonCount",
                                  direction : sort.direction === 'asc' ? 'desc' : 'asc'
                                })
                                 setTokens([]); setToken(null)
                              }}>
                              <span className="text-muted">Lessons</span>
                            </th>
                            <th className="text-center">
                              <span className="text-muted">Duration</span>
                            </th>
                            <th className="text-center" style={{cursor:"pointer"}} data-sort="item-name"  onClick={()=>{
                                  setSort({
                                    field:"likeCount",
                                    direction : sort.direction === 'asc' ? 'desc' : 'asc'
                                  })
                                 setTokens([]); setToken(null)
                              }}>
                              <span className="text-muted">Likes</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="list fs-base">
                          {courses.map((course) => (
                            <SingleCourse key={course.id} courseData={course} />
                          ))}
                        </tbody>
                      </table>
                    )}
                    {courses.length === 0 && !loading && (
                      <p className="text-center m-3">No course </p>
                    )}
                    <Pagination
                      handleNext={handleNext}
                      handleFirst={handleFirst}
                      length={tokens.length}
                      token={token}
                      total={courses?.length}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;


