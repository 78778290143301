import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DropUpload from "../components/DropUpload";
import { fileUpload } from "../utils/utils";
import Swal from "sweetalert2";

const getZoomSession = /* GraphQL */ `
  query GetZoomSession($id: String!) {
    getZoomSession(sessionId: $id) {
      sessionId
      sessionKey
      sessionName
      userCount
      stream
      record
      active
      startTime
      endTime
      recordUrls
      thumbnail
    }
  }
`;

const updateLiveStream = /* GraphQL */ `
  mutation UpdateZoomSession($input: UpdateZoomSessionInput!) {
    updateZoomSession(input: $input) {
      sessionName
      sessionKey
      stream
      record
      active
      type
      startTime
      endTime
    }
  }
`;

const EditLiveStream = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [isHoverPushButton, setIsHoverPushButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [name, setName] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const handleMouseEnter = () => {
    setIsHoverPushButton(true);
  };

  const handleMouseLeave = () => {
    setIsHoverPushButton(false);
  };

  const getData = async () => {
    setLoading(true);
    try {
      let res = await API.graphql({
        query: getZoomSession,
        variables: {
          id: decodeURIComponent(id),
        },
      });

      let data = res.data.getZoomSession;

      setName(data?.sessionName);
      setThumbnail(data?.thumbnail);
    } catch (error) {
      console.log("edit", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const updateLiveStreamAction = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await API.graphql({
        query: updateLiveStream,
        variables: {
          input: {
            sessionId: id,
            sessionName: name,
            thumbnail: thumbnail || null,
          },
        },
      });
      navigate(`/livestreams/${encodeURIComponent(id)}`);
    } catch (error) {
      console.log("update live stream error", error);
    }
  };

  async function handleDrop(e, file, setState) {
    try {
      e.preventDefault();
      setState(await fileUpload(file));
    } catch (error) {
      Swal.fire("Oops!", "Try again", "error");
      console.log(error);
    }
  }

  if (loading) {
    return <img src="/images/loader.svg" alt="loader" className="loader" />;
  }

  return (
    <>
      <div className="container-xl container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <form
              className="tab-content py-6"
              id="wizardSteps"
              onSubmit={updateLiveStreamAction}
            >
              <div
                className="tab-pane fade show active"
                id="wizardStepOne"
                role="tabpanel"
                aria-labelledby="wizardTabOne"
              >
                <div className="row justify-content-center">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-12 text-center">
                    <h1 className="mb-3">Edit Live Stream</h1>
                  </div>
                </div>

                <div className="form-group">
                  <label id="name" className="form-label mx-1">
                    Name <span className="text-danger">*</span>
                  </label>

                  <input
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>

                <DropUpload
                  name="Thumbnail"
                  state={thumbnail}
                  setState={setThumbnail}
                  handleDrop={handleDrop}
                />

                <hr className="my-5" />

                <div className="nav row align-items-center">
                  <div className="col-auto">
                    <button
                      type="submit"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className="btn btn-primary"
                      disabled={submitting}
                      style={{
                        backgroundColor: isHoverPushButton
                          ? "#44a6a2"
                          : "#50C7C3",
                        border: "none",
                      }}
                    >
                      {submitting ? (
                        <div
                          className="spinner-border text-white"
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          role="status"
                        ></div>
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                    {!submitting && (
                      <button
                        type="submit"
                        onClick={() =>
                          navigate(`/livestreams/${encodeURIComponent(id)}`)
                        }
                        className="btn mx-2 btn-light"
                        style={{
                          border: "none",
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLiveStream;
