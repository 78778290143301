import React from "react";

export default function Privacy() {
  return (
    <div>
      <div className="text-center">
        <img src="/images/Yelling.png" alt="yelling" height={"200px"} />
        <h1>Privacy Policy</h1>
        <h3 className="text-muted">Updated on August 10, 2023</h3>
      </div>
      <div className="card p-5 container">
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Clarity Privacy Policy
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            SmartDate, Inc. d/b/a Clarity (“Clarity,” “Company,” “We,” “Our”)
            respects your privacy and is committed to protecting it through our
            compliance with this policy.&nbsp;This document details the types of
            information we may collect or that you may provide when you access,
            use or register with Clarity’s website and mobile application
            (collectively “Services”) and our practices for using, maintaining,
            protecting and disclosing that information.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Please read this carefully to understand our policies and practices
            regarding your information and how we will treat it. By using or
            registering with our Services, you agree to this Privacy Policy.
            Your continued use of our Services after we make changes is deemed
            to be acceptance of those changes, so please check the policy
            periodically for updates as it may change from time to time.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Information We Collect and How We Collect It
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            We collect information from and about users of our Services:
          </span>
        </p>
        <p>
          <span> </span>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            Directly from you when you provide it to us.
          </span>
        </p>
        <p>
          <span> </span>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            Automatically when you use the Services.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Information You Provide to Us&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            When you use or register with the Services, we may ask you to
            provide information by which you may be personally identified, such
            as your name, email address, telephone number, location, and any
            other identifier by which you may be contacted or identified online
            or offline (“Personal Information”).&nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span> </span>
          <span style={{ background: "transpaernt" }}>
            This information includes:&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            Information that you provide by filling in forms in the Services.
            This includes information provided at the time of registering to use
            the Services, using the Services and requesting help.&nbsp;
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            Details of transactions you carry out through the Services and of
            the fulfillment of your orders. You may be required to provide
            financial information before placing an order through the Services
            or receiving payment.&nbsp;
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            Records and copies of your correspondence (including e-mail
            addresses and phone numbers), if you contact us. We may also ask you
            for information when you report a problem with the Services.
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            Your responses to surveys that we might ask you to complete for
            research and development purposes.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Automatic Information Collection&nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            When you download, access or use the Services, it may use technology
            to automatically collect:&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;Usage Details. When you access and use the Services, we may
            automatically collect certain details of your access to and use of
            the Services, including traffic data, location data, logs and other
            communication data and the resources that you access and use on or
            through the Services.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;Device Information. We may collect information about your
            computer, mobile device and internet connection, including the
            computer or mobile device’s unique device identifier, IP address,
            operating system, browser type, mobile network information and the
            device’s telephone number.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Local Storage, Cookies, Web Beacons, and Tracking
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Our Services use local storage and cookies, which are small text
            files that are intended to make the Services better for you to use.
            In general, cookies are used to retain preferences, store
            information for things like shopping carts, and provide tracking
            data to third-party applications like Google Analytics. You may,
            however, disable cookies on the Services. The most effective way to
            do this is to disable cookies in your browser. We suggest consulting
            the help section of your browser or doing your own web research on
            how to disable cookies.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Please note that linked third-party websites may also use cookies.
            We cannot control the use of cookies by these third-party websites.
            For example, when you link from our website or app to a third-party
            website, that website may have the ability to recognize that you
            have come from Clarity by using cookies. If you have any questions
            about how third-party websites use cookies, you should contact such
            third parties directly.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            We may also employ software technology known as “web beacons” or
            “clear GIFs,” which helps us keep track of what content on our
            service is effective. Web beacons are small graphics with a unique
            identifier that are used to track the online movements of Internet
            users. Web beacons are embedded in the web pages you review, so they
            are not stored on your hard drive. The web beacons we may use will
            not track or collect any personally identifiable information about
            you and they are in no way linked to your personally identifiable
            information.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            The Services also use third-party analysis and tracking services to
            track the performance of our services, understand how you use our
            services, and offer you an improved and safer experience.&nbsp;Such
            usage information will be deleted as soon as this information is no
            longer required for the purpose collected, and will not be shared
            with third parties until after being anonymized.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Use of Personal Information We Collect
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            We may use the information we collect from You in the following
            ways:
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;To set up your account with us.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;To perform transactions through the Services and
            third-party service providers.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;To personalize your user experience and to allow
            us to deliver the type of content and offerings in which you are
            most interested.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;To deliver service messages and other services
            and content you request and send information related to accounts and
            services, including confirmations, invoices, technical notices,
            updates, security alerts, and support and administrative messages,
            and to enable user-to user communication.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;To conduct an aggregated analysis of the
            performance of our Services, including referral data if you arrive
            at our website from an external source.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            All the data we collect at Clarity is necessary for us to deliver
            the services you use. The amount we collect has been minimized
            wherever possible to respect your privacy.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Should you still disagree with the collection and processing of this
            data, we recommend you stop using our services or contact us at{" "}
          </span>
          <span
            style={{ background: "transparent", color: "rgb(17, 85, 204)" }}
          >
            info@clarityapp.io
          </span>
          <span style={{ background: "transparent", color: "rgb(0, 0, 255)" }}>
            {" "}
          </span>
          <span style={{ background: "transpaernt" }}>
            to delete your account.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            How We May Share Information We Collect
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            We may share personal information as follows:&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;We may share personal information with your consent. For
            example, you may let us share personal information with third
            parties for their own marketing uses. Those uses will be subject to
            the third-party privacy policies.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;To provide to third party service providers that perform
            services on our behalf, such as third-party payment platforms Stripe
            and Apple Pay, or other vendors to complete purchase orders.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;We may share personal information when we do a business deal,
            or negotiate a business deal, involving the sale or transfer of all
            or a part of our business or assets. These deals can include any
            merger, financing, acquisition, or bankruptcy transaction or
            proceeding. However, this information will be in the aggregate and
            anonymized to protect your personal information.&nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;We may share information to respond to lawful requests and
            legal processes, and with banking partners as required by credit
            card association rules.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;We may share information to protect the rights and property
            of Clarity, our agents, customers, and others. This includes
            enforcing our agreements, policies, and terms of use.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;We may share information in an emergency. This includes
            protecting the safety of our employees and agents, our customers, or
            any person.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;We may share information with those who need it to do work
            for us, but we will first obtain your consent.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            We may also share aggregated and/or anonymized data with others for
            their own uses.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Legal Basis for Processing Personal Information
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Our legal basis for collecting and using the personal information
            described above will depend on the personal information concerned
            and the specific context in which we collect it.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            However, we will normally collect personal information from you only
            (i) where we need the personal information to perform a contract
            with you; (ii) where the processing is in our legitimate interests
            and not overridden by your rights; or (iii) where we have your
            consent to do so.&nbsp;We have a legitimate interest in operating
            our Services and communicating with you as necessary to provide
            these Services, for example when responding to your queries,
            improving our platform, undertaking marketing, or for the purposes
            of detecting or preventing illegal activities.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            In some cases, we may also have a legal obligation to collect
            personal information from you or may otherwise need the personal
            information to protect your vital interests or those of another
            person.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            If we ask you to provide personal information to comply with a legal
            requirement or to perform a contract with you, we will make this
            clear at the relevant time and advise you whether the provision of
            your personal information is mandatory or not (as well as of the
            possible consequences if you do not provide your personal
            information).
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>Data Retention</strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            We retain personal information we collect from You where we have an
            ongoing legitimate business need to do so (for example, to provide
            you with a service you have requested or to comply with applicable
            legal, tax, or accounting requirements).&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            When we have no ongoing legitimate business need to process personal
            information, we will either delete or anonymize it or, if this is
            not possible (for example, because personal information has been
            stored in backup archives), then we will securely store personal
            information and isolate it from any further processing until
            deletion is possible.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Security of Your Personal Information<em>&nbsp;</em>
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            The security of your Personal Information is important to us. When
            you enter sensitive information (such as credit card number) on our
            Services, we encrypt that information using secure socket layer
            technology (SSL).
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Clarity takes reasonable security measures to protect your Personal
            Information to prevent loss, misuse, unauthorized access,
            disclosure, alteration, and destruction. Please be aware, however,
            that despite our efforts, no security measures are impenetrable.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            If you use a password on the Services, you are responsible for
            keeping it confidential. Do not share it with any other person. If
            you believe your password has been misused, please notify us
            immediately.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Your Data Protection Rights Under The General Data Protection
            Regulation (GDPR)
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            If You or a User are a resident of the EEA, you have the following
            data protection rights:
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            You may access, correct, update, or request deletion of your
            personal information.
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            In addition, you can object to the processing of your personal
            information, ask us to restrict the processing of your personal
            information, or request portability of your personal information.
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            You have the right to opt-out of marketing communications we send
            you at any time. You can exercise this right by clicking on the
            “unsubscribe” or “opt-out” link in the marketing emails we send
            you.&nbsp;
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            Similarly, if we have collected and process your personal
            information with your consent, then you can withdraw your consent at
            any time.&nbsp;Withdrawing your consent will not affect the
            lawfulness of any processing we conducted prior to your withdrawal,
            nor will it affect the processing of your personal information
            conducted in reliance on lawful processing grounds other than
            consent.
          </span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            •&nbsp;&nbsp;&nbsp;
          </strong>
          <span style={{ background: "transpaernt" }}>
            You have the right to complain to a data protection authority about
            our collection and use of your personal information. For more
            information, please contact your local data protection authority.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            We respond to all requests we receive from individuals wishing to
            exercise their data protection rights in accordance with applicable
            data protection laws.&nbsp;To exercise any of these rights, please
            contact us at{" "}
          </span>
          <span style={{ background: "transparent", color: "rgb(0, 0, 255)" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transpaernt" }}>.</span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Your Data Protection Rights under the California Consumer Privacy
            Act (CCPA)
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            The California Consumer Privacy Act (“CCPA”) provides consumers with
            specific rights regarding their Personal Information. If you are a
            California resident, you have the right to:
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;Request we disclose to you free of charge the following
            information covering the 12 months preceding your request: (a) the
            categories of Personal Information about you that we collected; (b)
            the categories of sources from which the Personal Information was
            collected; (c) the purpose for collecting Personal Information about
            you; (d) the categories of third parties to whom we disclosed
            Personal Information about you and the categories of Personal
            Information that was disclosed (if applicable) and the purpose for
            disclosing the Personal Information about you; and (e) the specific
            pieces of Personal Information we collected about you;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;Request we delete Personal Information we collected from you,
            unless the CCPA recognizes an exception;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            ·&nbsp;If the business sells Personal Information, you have a right
            to opt-out of that sale.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Consistent with California law, if you choose to exercise your
            applicable CCPA rights, we won’t charge you different prices or
            provide you a different quality of services. If we ever offer a
            financial incentive or product enhancement that is contingent upon
            you providing your Personal Information, we will not do so unless
            the benefits to you are reasonably related to the value of the
            Personal Information that you provide to us. If you are a California
            resident and wish to exercise your rights under the CCPA, please
            contact us at{" "}
          </span>
          <span style={{ background: "transparent", color: "rgb(0, 0, 255)" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transpaernt" }}>.</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Updating and Accessing Your Personal Information
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            If your Personal Information changes, we invite you to correct or
            update your information. We will retain your information for as long
            as your account is active or as needed to provide you services. If
            you wish to cancel your account, request that we no longer use your
            information to provide you services, or delete your Personal
            Information, you may do so through your account settings, or contact
            us at{" "}
          </span>
          <span style={{ background: "transparent", color: "rgb(0, 0, 255)" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transpaernt" }}>
            . We will respond to your request to have your Personal Information
            updated as soon as possible. We will retain and use your information
            as necessary to comply with our legal obligations, resolve disputes,
            and enforce our agreements.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Choices About Use of Your Information
          </strong>
        </p>
        <p>
          <span>
            You can request that your account be deleted, however, certain
            financial record keeping information may be maintained in our
            systems because we have a legitimate legal and financial interest in
            maintaining such records.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            You may unsubscribe from receiving certain promotional emails from
            us. If you wish to do so, simply follow the instructions found at
            the end of the email. Even if you unsubscribe, we may still contact
            you for informational, transactional, account-related, or similar
            purposes, including messages about your current Clarity account and
            services.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Many browsers have an option for disabling cookies, which may
            prevent your browser from accepting new cookies or enable selective
            use of cookies. Please note that, if you choose not to accept
            cookies, some features and the personalization of our Services may
            no longer work for you. You will continue to receive advertising
            material but it will not be tailored to your interests.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Third-Party Disclosure and Links
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            The Services may include links to other websites whose privacy
            practices may differ from those of Clarity. If you submit Personal
            Information to any of those sites, your information is governed by
            their privacy statements. We encourage you to carefully read the
            privacy statement of any third-party websites you visit.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Notice Concerning The Information Of Children
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Clarity is not directed to children under the age of 13 and does not
            intentionally collect any information from children under the age of
            13. Please contact us if your child has provided Personal
            Information to us and we will delete the information.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            International Data Transfers
          </strong>
        </p>
        <p>
          <span>
            We may transfer personal information to countries other than the
            country in which the data was originally collected. These countries
            may not have the same data protection laws as the country in which
            you initially provided the information. When we transfer your
            personal information to other countries, we will protect that
            information as described in this Privacy Policy.
          </span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Changes to This Policy
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            Clarity may change this Policy from time to time. If we make any
            changes to this Policy, we will change the “Last Updated” date below
            and may notify you by email (sent to the e-mail address specified in
            your account). We encourage you to review this Policy whenever you
            visit the Services to understand how your Personal Information is
            used.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            Questions About This Policy
          </strong>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>
            If you have any questions about this Policy, our practices related
            to the Services, or if you would like to have us remove your
            information from our database please feel free to contact us at
          </span>
          <strong style={{ background: "transpaernt" }}> </strong>
          <span style={{ background: "transparent", color: "rgb(0, 0, 255)" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transpaernt" }}>.</span>
        </p>
        <p>
          <span style={{ background: "transpaernt" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transpaernt" }}>
            <em>Last Update: August 10, 2023</em>
          </strong>
        </p>
      </div>
    </div>
  );
}
