import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDuration } from "../utils/utils";
import moment from "moment";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const getZoomSession = /* GraphQL */ `
  query GetZoomSession($id: String!) {
    getZoomSession(sessionId: $id) {
      sessionId
      sessionKey
      sessionName
      userCount
      viewsCount
      stream
      record
      active
      startTime
      endTime
      recordUrls
      thumbnail
    }
  }
`;

const deleteZoomSession = /* GraphQL */ `
  mutation DeleteZoomSession($input: DeleteZoomSessionInput!) {
    deleteZoomSession(input: $input) {
      sessionId
    }
  }
`;

const LiveStreamDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentSession, setCurrentSession] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [id]);

  async function getData() {
    try {
      let res = await API.graphql({
        query: getZoomSession,
        variables: {
          id: decodeURIComponent(id),
        },
      });

      setCurrentSession(res.data.getZoomSession);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const downloadStream = () => {
    saveAs(currentSession.recordUrls[0], "stream.mp4", { autoBom: true });
  };

  const deleteStream = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete Stream",
      })
        .then((result) => {
          if (result.isConfirmed) {
            API.graphql({
              query: deleteZoomSession,
              variables: { input: { sessionId: id } },
            })
              .then(() => {
                Swal.fire("Voila !", "Stream has been deleted.", "success");
                navigate("/livestreams");
              })
              .catch((e) => toast.error("Failed to remove stream !"));
          }
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <>
      {currentSession && (
        <div className="mx-5 my-4">
          <div className="header">
            <div className="row justify-content-between align-items-center">
              <div className="col">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div className="avatar">
                      <img
                        src={
                          currentSession.thumbnail || "/images/clarityApp.png"
                        }
                        alt="..."
                        className="avatar-img rounded"
                      ></img>
                    </div>
                  </div>
                  <div className="col ms-n2">
                    <small className="text-muted">Live stream</small>
                    <h2
                      className="header-title text-truncate"
                      style={{ whiteSpace: "initial" }}
                    >
                      {currentSession?.sessionName}
                    </h2>
                  </div>
                </div>
              </div>
              {currentSession.recordUrls && currentSession.recordUrls[0] && (
                <>
                  <div className="col-auto">
                    {/* <a href={currentSession?.recordUrls[0]} download="test.mp4"> */}
                    <button
                      className="btn btn-primary upload-btn"
                      onClick={downloadStream}
                    >
                      Download
                    </button>
                    {/* </a> */}
                  </div>
                  <div className="col-auto">
                    <a
                      href={currentSession?.recordUrls[0]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="btn upload-btn">Watch</button>
                    </a>
                  </div>
                </>
              )}
              <div className="col-auto">
                <button
                  className="btn btn-primary upload-btn"
                  onClick={() =>
                    navigate(`/edit-livestream/${encodeURIComponent(id)}`)
                  }
                >
                  Edit Stream
                </button>
              </div>
              <div className="col-auto">
                <button className="btn btn-light" onClick={deleteStream}>
                  Delete Stream
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="row">
              <div className="card mb-6">
                <div className="card-body">
                  <h3>Overview</h3>
                  <div className="mb-3">
                    <div>
                      Date:{" "}
                      {currentSession?.active ? (
                        <span style={{ color: "red" }}>Live</span>
                      ) : (
                        moment(currentSession.endTime).format("MMM Do YYYY")
                      )}
                    </div>
                    <div>
                      Duration:{" "}
                      {formatDuration(
                        currentSession.endTime,
                        currentSession.startTime
                      ) || "-"}
                    </div>
                    <div>
                      Views:{" "}
                      {currentSession?.viewsCount || "0"}
                    </div>
                  </div>
                  <h3>Thumbnail</h3>
                  <div className="mb-3">
                    <img
                      style={{ borderRadius: ".4rem" }}
                      src={currentSession.thumbnail || "/images/clarityApp.png"}
                      alt="cover_image"
                      height={"100px"}
                      key={new Date().getMilliseconds()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LiveStreamDetails;
