import React from "react";

export default function Guidelines() {
  return (
    <div>
      <div className="text-center">
        <img src="/images/Yelling.png" alt="yelling" height={"200px"} />
        <h1>Conversation Guidelines</h1>
        <h3 className="text-muted">Updated on August 10, 2023</h3>
      </div>
      <div className="card p-5 container">
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Clarity Forum Community Guidelines
          </strong>
        </p>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <p>
          We strive to make our community forums a safe and discreet space for
          people to talk about their experiences. Please remember that you may
          choose to be anonymous on the forums, but from the backend, Clarity
          trust and safety team has access to your identity.
        </p>
        <p>
          By using Clarity, you agree to comply with our terms of use, to adhere
          to our community guidelines below, to comply with all applicable laws
          pertaining to hate speech and online conduct and you acknowledge and
          agree that Clarity has the right, in its sole discretion, to terminate
          your account or take such other action as we see fit if you breach any
          of the conditions herein or any of our Terms of Use.&nbsp;
          <strong>
            This may include taking legal/court action and/or reporting
            offending users to the relevant authorities.
          </strong>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            Please review the following guidelines, which are meant to
            facilitate a great Clarity experience.
          </span>
        </p>
        <p>
          <strong>
            The following conduct, material or content is STRICTLY PROHIBITED on
            our community forums and will subject users to being permanently
            banned, reported as well as other actions to the extent of the law:
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <em>any use of any person’s name</em>
            </strong>
          </li>
          <li>
            <strong>
              <em>
                content that is offensive, abusive, libelous, defamatory,
                slanderous, obscene, racist, ethnically or culturally offensive,
                indecent, that promotes violence, terrorism, or illegal acts,
                incites hatred on grounds of race, gender, religion or sexual
                orientation, or is otherwise objectionable in the Company’s
                reasonable discretion;
              </em>
            </strong>
          </li>
          <li>
            <strong>
              any information, content, or other material that violates,
              plagiarizes, misappropriates, or infringes the rights of third
              parties including, without limitation, copyright, trademark
              rights, rights of privacy or publicity, confidential information
              or any other right; or
            </strong>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              any content that violates, breaches, or is contrary to any law,
              rule, regulation, court order, or is otherwise is illegal or
              unlawful in the Company’s reasonable opinion;
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              any material of any kind that contains any virus, Trojan horse,
              spyware, adware, malware, bot, time bomb, worm, or other harmful
              or malicious component, which actually or potentially could
              overburden, impair or disrupt the Services or servers or networks
              forming part of, or connected to, the Services, or which actually
              or potentially could restrict or inhibit any other user’s use and
              enjoyment of the Services; or
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              any unsolicited or unauthorized advertising, promotional messages,
              spam or any other form of solicitation.
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              any deliberate impersonation of another person or entity or
              otherwise misrepresent your affiliation with a person or entity.
            </span>
          </li>
        </ul>
        <p>
          By using this software, you agree to comply with the above conditions
          and acknowledge and agree that Clarity has the right, in its sole
          discretion, to terminate your account or take such other action as we
          see fit if you breach any of the above conditions or any of the other
          terms of these Terms of Use.&nbsp;
          <strong>
            This may include taking court action and/or reporting offending
            users to the relevant authorities.
          </strong>
        </p>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            <em>Last Update: August 10, 2023</em>
          </strong>
        </p>
      </div>
    </div>
  );
}
