import { useEffect, useMemo, useRef, useState } from "react";
import { MessageList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";

const ZoomChat = ({ client }) => {
  const usedIds = useMemo(() => new Set(), []);
  const [messages, setMessages] = useState([]);

  const [chat, setChat] = useState();

  // const sendMessage = () => {
  //   console.log({ chat });
  //   chat.sendToAll("Hey everyone");
  // };

  const messageContainer = useRef();

  useEffect(() => {
    setChat(client.current.getChatClient());
  }, [client]);

  useEffect(() => {
    client.current.on("chat-on-message", (payload) => {
      if (usedIds.has(payload.id)) {
        return;
      }

      usedIds.add(payload.id);
      setMessages((d) => [
        ...d,
        { type: "text", text: payload.message, title: payload.sender.name },
      ]);
      setTimeout(() => {
        messageContainer.current.scrollTop =
          messageContainer.current.scrollHeight;
      }, 0);
    });
  }, [client, messageContainer, usedIds]);

  return (
    <div
      className="col-4 d-flex flex-column justify-content-between"
      style={{ height: "85vh" }}
    >
      <div
        className="flex-grow-1 gap-3 overflow-auto"
        style={{ maxHeight: "80vh" }}
        ref={messageContainer}
      >
        <MessageList
          className="message-list"
          lockable={true}
          dataSource={messages}
        />
      </div>
      <div className="d-flex justify-content-between gap-3">
        {/* <input
          className="form-control"
          type="text"
          placeholder="Enter your message here"
        />
        <button className="border-0 bg-transparent" onClick={sendMessage}>
          <img src="/images/sendComment.svg" alt="send" />
        </button> */}
      </div>
    </div>
  );
};

export default ZoomChat;
