import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { formattedTime } from "../utils/utils";

const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
  ) {
    deletePost(input: $input) {
      id
    }
  }
`;
const deletePostCommentReply = /* GraphQL */ `
  mutation DeletePostCommentReply(
    $input: DeletePostCommentReplyInput!
  ) {
    deletePostCommentReply(input: $input) {
      id
    }
  }
`;
const deletePostComment = /* GraphQL */ `
  mutation DeletePostComment(
    $input: DeletePostCommentInput!
  ) {
    deletePostComment(input: $input) {
      id
    }
  }
`;

function PostDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.userInfo.payload);
  let allowViewer = user.role.includes("VIEWER");

  const [loading, setLoading] = useState(true);
  const [loadComments, setLoadComments] = useState(false);
  const [loadLikes, setLoadLikes] = useState(false);

  const [commentToken, setCommentToken] = useState(null);
  const [commentNextToken, setCommentNextToken] = useState(null);

  const [likeToken, setLikeToken] = useState(null);
  const [likeNextToken, setLikeNextToken] = useState(null);

  const [post, setPost] = useState({});
  const [comments, setComments] = useState([]);
  const [likes, setLikes] = useState([]);

  const getPostQuery = `query GetPost{
    getPost(id: "${id}") {
      createdAt
      likes{
        items{
          id
        }
      }
      category{
        name
      }
        comments(limit: 25,  nextToken: ${
          commentNextToken ? `"${commentNextToken}"` : null
        }, sortDirection: DESC) {
          items {
            id
            createdAt
            comment
            likeCount
            publishedAt
            user {
                id
                username
                name
                expert
                media
                deleted
              }
              replies(sortDirection: DESC) {
                items {
                  id
                  comment
                  createdAt
                  publishedAt
                  user {
                    id
                    expert
                    name
                    username
                    media
                    deleted
                  }
                }
              }
          }
          nextToken
        }
        author {
          id
          name
          media
          expert
          deleted
          username
        }
        reports{
          items{
            id
          }
        }
        description
        likeCount
        attachments
        publishedAt
        thumbnail
        status
        title
      }
  }`;

  const getLikeQuery = `query searchPostLikes{
    searchPostLikes(limit:25, nextToken: ${
      likeNextToken ? `"${likeNextToken}"` : null
    }, sort: {direction: desc, field: publishedAt},filter: {postId: {eq:"${id}" }}) {
        items {
          createdAt
          publishedAt
          user {
            id
            media
            username
            name
            deleted
          }
        }
        nextToken
      }
  }`;
  const updatePostQuery = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
  ) {
    updatePost(input: $input) {
      id
    }
  }
`;

  async function getPostData() {
    try {
      let res = await API.graphql({
        query: getPostQuery,
      });
      setPost(res.data.getPost);
      res.data.getPost?.comments && setComments((prev) => [...prev, ...res.data.getPost?.comments?.items]);
      setLoading(false);
      setCommentToken(res.data.getPost?.comments?.nextToken);
      setLoadComments(false);
      setLoadLikes(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function getLikes() {
    try {
      let res = await API.graphql({ query: getLikeQuery });
      setLikes((prev) => [...prev, ...res.data.searchPostLikes?.items]);
      setLikeToken(res.data.searchPostLikes?.nextToken);
      setLoadLikes(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function changePostStatus(status){
    try {
      setLoading(true)
      await API.graphql({query : updatePostQuery,variables:{ input : {id : id , status}}})
      setLoading(false)
      toast.warning(`Post has been ${status === "FLAGGED" ? "flagged" : "Unflagged"}`) 
      setPost({...post,status })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function handleDeleteComment(id) {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Delete Comment.`,
      }).then((result) => {
        if (result.isConfirmed) {
          API.graphql({
            query: deletePostComment,
            variables: {
              input: {
                id: id,
              },
            },
          })
            .then((res) => {
              let temp = structuredClone(comments);
              temp = temp.filter((item) => item.id !== id);
              setComments(temp);
              toast.success("Comment has been removed");
            })
            .catch((e) => Swal.fire("Oops !", e.message, "error"));
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDeletePost(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete Post",
    })
      .then((result) => {
        if (result.isConfirmed) {
          API.graphql({
            query: deletePost,
            variables: {
              input: {
                id: id,
              },
            },
          })
            .then(() => {
              Swal.fire("Voila !", "Post has been deleted.", "success");
              navigate("/posts");
            })
            .catch((e) => toast.error("Failed to remove post !"));
        }
      })
      .catch((e) => console.log(e));
  }

  async function handleDeleteReply(id, commentIdx, commentId) {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Delete Comment`,
      }).then((result) => {
        if (result.isConfirmed) {
          API.graphql({
            query: deletePostCommentReply,
            variables: {
              input: {
                id: id,
              },
            },
          })
            .then(() => {
              toast.success("Comment reply has been removed");
              let temp = structuredClone(comments);
              let comment = temp.find((item) => item.id === commentId);
              let reply = comment.replies.items;
              reply = reply.filter((item) => item.id !== id);
              comment.replies.items = reply;
              temp[commentIdx] = comment;
              setComments(temp);
            })
            .catch((e) => toast.error("Failed to remove comment reply !"));
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  
  useEffect(() => {
    getPostData();
  }, [commentNextToken]);

  useEffect(() => {
    getLikes();
  }, [likeNextToken]);

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="mx-4 my-4">
      <div className="row row">
        <div className="col-xl-8 col-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-3">
                <div className="align-items-center row">
                  <div className="col-auto">
                    <Link
                      to={`/user/${post?.author?.id}`}
                      onClick={(e) => e.stopPropagation()}>
                      <div className="avatar">
                        <img
                          className="avatar-img rounded-circle"
                          src={post?.author?.media || "/images/user.png"}
                          alt={post?.author?.media}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="ms-n2 col">
                    <h4 className="mb-1">{post?.author?.username || post?.author?.name || "-"}
                    {post?.author?.expert && <span  className="text-warning fs-2">*</span>} {" "}
                    {post?.author?.deleted === true &&  <span className="item-score badge bg-danger mb-1">Deleted</span>}
                    </h4>
                    <p className="small text-muted card-text d-flex gap-2">
                      <time dateTime="2018-05-24">
                        {formattedTime(post?.publishedAt || post?.createdAt)}
                      </time>
                      {post?.status === "FLAGGED" && (
                        <span className="item-score badge ${data?.verified bg-danger">
                        Flagged
                        </span>
                        )}
                    </p>
                  </div>
                  {!allowViewer && (
                    <div
                      className="col-auto"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="dropdown">
                        <button
                          className="dropdown-ellipses dropdown-toggle border-0 bg-transparent"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fe fe-more-vertical"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/edit-post/${id}`)}>
                            Edit
                          </span>
                          {post?.status === "FLAGGED" ? (
                            <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => changePostStatus("NORMAL")}>
                            Unflag the post
                            </span>
                          ):(
                            <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => changePostStatus("FLAGGED")}>
                            Flag the post
                          </span>
                          )}
                         
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeletePost(id)}
                          >
                            Delete
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <strong className="mt-2 mb-1">{post?.title}</strong>
              <p className="badge rounded px-2 mb-0 post_badge ms-2">
                {post?.category?.name}
              </p>
              <p className="mb-3 small">{post?.description}</p>
              <div onClick={(e) => e.stopPropagation()} className="mb-2">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={4000}
                  showThumbs={false}
                >
                  {post?.attachments && JSON.parse(post?.attachments)?.map((item, index) => (
                    <div className="text-center" key={index}>
                      {item.type === "Image" ? (
                        <img
                          loading="lazy"
                          src={item?.url || "/images/randomImage.jpg"}
                          style={{
                            height: "500px",
                            objectFit: "cover",
                          }}
                          alt="..."
                          className="img-fluid rounded"
                        />
                      ) : (
                        <video controls height={500} width={"100%"}>
                          <source src={item?.url} type="video/mp4"></source>
                        </video>
                      )}
                    </div>
                  ))}
                </Carousel>
              </div>

              <div>
                <span className="border border-1 px-2 py-1 rounded ms-2">
                  <i className="fe fe-thumbs-up" /> {post?.likes?.items?.length || 0}
                </span>

                <span className="border border-1 px-2 py-1 rounded ms-2">
                  <i className="fe fe-message-square" />{" "}
                  {post?.comments?.items?.length || 0}
                </span>

                <span className="border border-1 px-2 py-1 rounded ms-2">
                  <i className="fe fe-flag text-danger" />{" "}
                  {post?.reports?.items?.length || 0}
                </span>
              </div>

              <hr />

              {comments?.map((item, commentIdx) => (
                <div className="comment mb-3" key={item?.id}>
                  <div className="row">
                    <div className="col-auto">
                    <Link to={`/user/${item?.user?.id}`}>
                      <span className="avatar">
                        <img
                          className="avatar-img rounded-circle"
                          src={item?.user?.media || "/images/user.png"}
                          alt="Ab Hadley"
                        />
                      </span>
                    </Link>

                    </div>
                    <div className="ms-n2 col ">
                      <div
                        className="comment-body d-flex gap-2 justify-content-between"
                        style={{ width: "fit-content" }}
                      >
                        <div>
                          <div className="row">
                            <div className="col">
                              <h5 className="comment-title">
                                {item?.user?.username || item?.user?.name || "-"}{item?.user?.expert && <span  className="text-warning fs-2">*</span>}
                                {" "} {item?.user?.deleted === true &&  <span className="item-score badge bg-danger mb-1">Deleted</span>}
                              </h5>
                            </div>
                            <div className="col-auto">
                              <time className="comment-time">
                                {formattedTime(item?.publishedAt || item?.createdAt)}
                              </time>
                            </div>
                            <div
                              className="col-auto"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <div className="col-auto d-flex"></div>
                              <div className="dropdown">
                                <a
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical"></i>
                                </a>
                                <div
                                  className="dropdown-menu dropdown-menu-end"
                                  onClick={() => handleDeleteComment(item?.id)}
                                >
                                  <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Delete
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="comment-text">{item?.comment?.startsWith("@") ? item?.comment?.split("]")[0] + "]" + item?.comment?.split(")")[1] : item?.comment}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {item?.replies?.items?.map((reply) => (
                    <div className="row ms-6 my-3" key={reply.id}>
                      <div className="col-auto">
                      <Link to={`/user/${reply?.user?.id}`}>
                        <span className="avatar">
                          <img
                            className="avatar-img rounded-circle"
                            src={reply?.user?.media || "/images/user.png"}
                            alt="Ab Hadley"
                          />
                        </span>
                      </Link>
                      </div>
                      <div className="ms-n2 col ">
                        <div
                          className="comment-body d-flex gap-2 justify-content-between"
                          style={{ width: "fit-content" }}>
                          <div>
                            <div className="row">
                              <div className="col">
                                <h5 className="comment-title">
                                  {reply?.user?.username ||  reply?.user?.name || "-"}{item?.user?.expert && <span  className="text-warning fs-2">*</span>}
                                  {" "} {reply?.user?.deleted === true &&  <span className="item-score badge bg-danger mb-1">Deleted</span>}
                                </h5>
                              </div>
                              <div className="col-auto">
                                <time className="comment-time">
                                  {formattedTime(reply?.publishedAt || reply?.createdAt)}
                                </time>
                              </div>
                              <div
                                className="col-auto"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="col-auto d-flex"></div>
                                <div className="dropdown">
                                  <a
                                    className="dropdown-ellipses dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical"></i>
                                  </a>
                                  <div
                                    className="dropdown-menu dropdown-menu-end"
                                    onClick={() => handleDeleteReply(reply?.id,commentIdx,item?.id)}>
                                    <span
                                      className="dropdown-item"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Delete
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="comment-text">{reply?.comment?.startsWith("@") ? reply?.comment?.split("]")[0] + "]" + reply?.comment?.split(")")[1] : reply?.comment}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loadComments ? (
              <img
                src="/images/loader.svg"
                alt="loader"
                width={"40px"}
                className="mx-auto"
              />
            ) : (
              <>
                {commentToken && (
                  <button
                    className="btn btn-sm upload-btn mx-auto my-3"
                    onClick={() => {
                      setLoadComments(true);
                      setCommentNextToken(commentToken);
                      setCommentToken(null);
                    }}>
                    Load More
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        <div className="col-12 col-xl-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">Likes</h4>
            </div>
            <div className="mx-4">
              {likes?.length === 0 && <p className="text-center m-2"> <b>No Likes Yet !</b></p>}
              {likes.map((item, index) => (
                <div className="list-group list-group-flush " key={index}>
                  <div className="list-group-item">
                    <div className="d-flex align-items-center gap-3">
                      <Link to={`/user/${item.user.id}`}>
                        <div className="avatar">
                          <img
                            className="avatar-img rounded-circle"
                            src={item?.user?.media || "/images/user.png"}
                            alt="user_avatar."
                          />
                        </div>
                      </Link>
                      <div className="ms-n2 col">
                        <h4 className="mb-1">
                          {item?.user?.username || item?.user?.name || "-"}
                          {" "} {item?.user?.deleted === true &&  <span className="item-score badge bg-danger">Deleted</span>}
                        </h4>
                        <p className="small text-muted card-text">
                          <time dateTime="2018-05-24">
                            {formattedTime(item?.publishedAt || item?.createdAt)}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {loadLikes ? (
              <img
                src="/images/loader.svg"
                alt="loader"
                width={"40px"}
                className="mx-auto"
              />
            ) : (
              <>
                {likeToken && (
                  <button
                    className="btn btn-sm upload-btn mx-auto my-3"
                    onClick={() => {
                      setLoadLikes(true);
                      setLikeNextToken(likeToken);
                      setLikeToken(null);
                    }}
                  >
                    Load More
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostDetails;
