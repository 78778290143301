import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const TopMenu = () => {
  const navigate = useNavigate();
  const data = useSelector((state) => state);

  const userInfo = data.userInfo.payload;
  const Logout = async () => {
    try {
      await Auth.signOut();
      navigate("/");
    } catch (error) {
      console.log(error);
      Swal.fire("Oops !", error.message, "error");
    }
  };

  return (
    <div className="navbar navbar-expand-md navbar-light d-none d-md-flex">
      <div className="container-fluid">
        <form className="form-inline me-4 d-none d-md-flex"></form>
        <div className="navbar-user me-4">
        <b className="me-2">Hi, {userInfo?.username || userInfo?.name}</b>
          <div className="dropdown">
            <a
              href="#"
              className="dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className={"avatar avatar-md mx-auto"}>
                <img
                  className="avatar-img rounded-circle"
                  alt={"avatar"}
                  src={
                    userInfo?.media ||
                    "/images/user.png"
                  }
                />
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              <Link to="/profile" className="dropdown-item">
                My Profile
              </Link>
              <Link to="/" className="dropdown-item" onClick={() => Logout()}>
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
