import React from "react";

export default function Error() {
  return (
    <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
      <div className="container">
        <div className="justify-content-center row">
          <div className="my-5 col-xl-4 col-md-5 col-12">
            <div className="text-center">
              <h6 className="text-uppercase text-muted mb-4">404 error</h6>
              <h1 className="display-4 mb-3">There’s no page here 😭</h1>
              <p className="text-muted mb-4">
                Looks like you ended up here by accident?
              </p>
              <a
                role="button"
                tabindex="0"
                className="btn btn-primary btn-lg upload-btn"
                href="/"
              >
                Return to your dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
