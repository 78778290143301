import { API, Storage } from "aws-amplify";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { KJUR } from "jsrsasign";

export const limit = 60;

const ZOOM_SDK_KEY = "CE6Z8oARQI6gotZ8q8iRQg";
const ZOOM_SDK_SECRET = "IlRs2USkSPzsYYFanw2tkAwYKG6zO1nAJtsV";

export async function fileUpload(file) {
  Swal.fire({
    title: "Uploading...",
    html: ` 
        <img src='/images/loader.svg' alt='loader'/>`,
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
  try {
    let res = await Storage.put(
      `activity-${Math.random().toString(36).substring(2, 15)}.${
        file.name.split(".")[1]
      }`,
      file,
      { contentType: file.type }
    );
    let link = await Storage.get(res?.key, { level: "public" });
    Swal.close();
    return link.substring(0, link.indexOf("?"));
  } catch (error) {
    Swal.close();
    Swal.fire("Oops!", error.message, "error");
  }
}

export async function handleDelete(id, mutation, type, getData) {
  try {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete ${type}`,
    }).then((result) => {
      if (result.isConfirmed) {
        API.graphql({
          query: mutation,
          variables: {
            input: {
              id: id,
            },
          },
        })
          .then((res) => {
            toast.success(`${type} has been removed.`);
            setTimeout(() => {
              getData();
            }, 500);
          })
          .catch((e) => toast.error(e.message));
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export function formattedTime(dateString) {
  try {
    const date = new Date(dateString);
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(
      date
    );
    const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(
      date
    );
    const hour = date.getHours();
    const minute = date.getMinutes();
    const formattedHour = hour > 12 ? `${hour - 12}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    const period = hour >= 12 ? "PM" : "AM";
    const formattedDate = `${month.slice(
      0,
      3
    )} ${day}, ${year}  ${formattedHour}:${formattedMinute} ${period}`;

    return formattedDate;
  } catch (error) {
    console.log(error);
  }
}

export const timeFormatter = (date) => {
  var ms = new Date().getTime() - new Date(date).getTime();
  var seconds = Math.floor(ms / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  var months = Math.floor(days / 30);
  var years = Math.floor(months / 12);
  if (seconds < 1) {
    return "Just now";
  }
  if (seconds < 60) {
    return seconds + "seconds";
  }
  if (minutes < 60) {
    if (minutes === 1) return minutes + " minute";
    else return minutes + " minutes";
  }
  if (hours < 24) {
    if (hours === 1) return hours + " hour";
    else return hours + " hours";
  }
  if (days < 30) {
    if (days === 1) return days + " day";
    else return days + " days";
  }
  if (months < 12) {
    if (months === 1) return months + " month";
    else return months + " months";
  } else {
    return years + "year";
  }
};
export const selectColor = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    text: "orangered",
    primary25: "#f0f0f0",
    primary: "#50c7c3",
  },
});

export const formatDuration = (endTime, startTime) => {
  if (endTime && startTime) {
    const difference = moment.duration(moment(endTime).diff(moment(startTime)));
    const duration = moment
      .utc(difference.asMilliseconds())
      .format("H:m:s")
      .split(":");

    const formattedTime =
      duration[0] !== "0"
        ? `${duration[0]}h ${duration[1]}m ${duration[2]}s`
        : `${duration[1]}m ${duration[2]}s`;
    return formattedTime;
  } else {
    return 0;
  }
};

export async function getZoomJWT(slug) {
  const JWT = generateSignature(slug, 1);
  return JWT;
}

function generateSignature(sessionName, role) {
  const iat = Math.round(new Date().getTime() / 1000) - 30;
  const exp = iat + 60 * 60 * 2;
  const oHeader = { alg: "HS256", typ: "JWT" };
  const sdkKey = ZOOM_SDK_KEY;
  const sdkSecret = ZOOM_SDK_SECRET;
  const oPayload = {
    app_key: sdkKey,
    tpc: sessionName,
    role_type: role,
    version: 1,
    iat: iat,
    exp: exp,
  };
  const sHeader = JSON.stringify(oHeader);
  const sPayload = JSON.stringify(oPayload);
  const sdkJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, sdkSecret);
  return sdkJWT;
}
