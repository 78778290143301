import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../components/Pagination";
import { limit, selectColor } from "../utils/utils";
import { useSelector } from "react-redux";
import { listExpertTypes } from "./ExpertTypes";
import Select from "react-select";
import { toast } from "react-toastify";
import { deleteUserFromCognito } from "../graphql/mutations";

const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        name
        email
        media
        payoutRate
        callRate
        online
        expertType {
          name
          callRate
        }
        verified
      }
      nextToken
      total
    }
  }
`;
const searchUsersTotal = /* GraphQL */ `
  query SearchUsers($filter: SearchableUserFilterInput) {
    searchUsers(filter: $filter) {
      total
    }
  }
`;

const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      username
      name
      role
    }
  }
`;

const Experts = () => {
  const user = useSelector((state) => state.userInfo.payload);
  let allowViewer = user.role.includes("VIEWER");
  let allowAdmin = user.role.includes("ADMIN");

  const navigate = useNavigate();

  const [expertsData, setExpertsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expertTypes, setExpertTypes] = useState([]);
  const [type, setType] = useState(null);

  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [display, setDisplay] = useState(0);

  const [total, setTotal] = useState(0);
  const [tokens, setTokens] = useState([]);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ field: "createdAt", direction: "desc" });

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }
  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }

  async function getData() {
    try {
      setLoading(true);
      let filter = { expert: { eq: true } };
      let sortable = { field: sort?.field, direction: sort?.direction };

      if (type) {
        filter = { expert: { eq: true }, expertTypeId: { eq: type?.value } };
      }
      if (type !== null && search.length !== 0) {
        filter = {
          expert: { eq: true },
          expertTypeId: { eq: type?.value },
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }

      if (display === 1) filter = { ...filter, verified: { eq: true } };
      if (display === 3) filter = { ...filter, verified: { eq: false } };
      if (display === 4)
        filter = { ...filter, online: { eq: true }, verified: { eq: true } };
      if (search.length !== 0) {
        filter = {
          expert: { eq: true },
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }
      if (search.length !== 0 && display === 1) {
        filter = {
          expert: { eq: true },
          verified: { eq: true },
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }
      if (search.length !== 0 && display === 3) {
        filter = {
          expert: { eq: true },
          verified: { eq: false },
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }
      if (search.length !== 0 && display === 4) {
        filter = {
          expert: { eq: true },
          online: { eq: true },
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }

      let res = await API.graphql({
        query: searchUsers,
        variables: {
          limit,
          nextToken,
          filter: { ...filter, deleted: { ne: true } },
          sort: sortable,
        },
      });
      setLoading(false);
      if (res.data.searchUsers.items.length < res.data.searchUsers.total) {
        setTokens((prev) => [...prev, res.data?.searchUsers?.nextToken]);
      }
      setToken(res.data.searchUsers.nextToken);
      search.length === 0 && setTotal(res.data.searchUsers?.total || 0);
      setExpertsData(res.data.searchUsers?.items);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleRemove(id, index) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Block Expert !",
    }).then((result) => {
      if (result.isConfirmed) {
        API.graphql({
          query: updateUser,
          variables: {
            input: {
              id: id,
              verified: false,
              active: false,
              online: false,
            },
          },
        })
          .then((res) => {
            toast.warning(
              `${
                res.data.updateUser.username || res.data.updateUser.name
              } has been blocked.`
            );
            let temp = structuredClone(expertsData);
            temp[index].verified = false;
            temp[index].active = false;
            temp[index].online = false;
            setLoading(true);
            setTimeout(() => {
              getData();
            }, 1200);
            setExpertsData(temp);
          })
          .catch((e) => {
            console.log(e);
            Swal.fire("Oops !", e.message, "error");
          });
      }
    });
  }
  async function handleApprove(id, payout, verified, callrate) {
    Swal.fire({
      title: "Enter Payout Rate",
      input: "text",
      inputValue: payout || "",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: `Enter payout rate for expert in $`,
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (rate) => {
        await API.graphql({
          query: updateUser,
          variables: {
            input: {
              id: id,
              verified: true,
              active: true,
              payoutRate: rate,
              callRate: callrate,
              role: ["INSTRUCTOR"],
              expertStatus: "FREE",
            },
          },
        })
          .then((res) => {
            verified
              ? toast.success("Payout Rate Updated.")
              : toast.success(
                  `${
                    res.data.updateUser.username || res.data.updateUser.name
                  } has been verified.`
                );
            let temp = structuredClone(expertsData);
            let currIdx = expertsData.findIndex((item) => item.id === id);
            temp[currIdx].active = true;
            temp[currIdx].verified = true;
            temp[currIdx].payoutRate = rate;
            setLoading(true);
            setTimeout(() => {
              getData();
            }, 1200);
            setExpertsData(temp);
          })
          .catch((err) => {
            Swal.showValidationMessage(`${"Enter valid payout rate"}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }
  async function handleUpdateCallRate(id, callRate) {
    Swal.fire({
      title: "Enter Call Rate",
      input: "text",
      inputValue: callRate || "",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: `Enter callrate for expert in $`,
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (rate) => {
        await API.graphql({
          query: updateUser,
          variables: {
            input: {
              id: id,
              callRate: rate,
            },
          },
        })
          .then((res) => {
            Swal.fire("Success", "Callrate Updated.", "success");
            let temp = structuredClone(expertsData);
            let currIdx = expertsData.findIndex((item) => item.id === id);
            temp[currIdx].callRate = rate;
            setExpertsData(temp);
          })
          .catch((err) => {
            Swal.showValidationMessage(`${"Enter valid callrate"}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }
  async function deleteExpert(id, name, idx) {
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Delete ${name || "Expert"}`,
      }).then(async (result) => {
        try {
          if (result.isConfirmed) {
            setLoading(true);
            await API.graphql({
              query: deleteUserFromCognito,
              variables: { input: { userId: id } },
            });
            let input = {
              id: id,
              deleted: true,
              active: false,
              online: false,
              verified: false,
            };
            await API.graphql({
              query: updateUser,
              variables: {
                input: input,
              },
            });
            setTimeout(async () => {
              let res = await API.graphql({
                query: searchUsersTotal,
                variables: {
                  filter: { expert: { eq: true }, deleted: { ne: true } },
                },
              });
              setTotal(res.data.searchUsers?.total || 0);
            }, 1000);

            let temp = structuredClone(expertsData);
            temp = temp.filter((item) => item.id !== id);
            setExpertsData(temp);

            toast.success(`Expert deleted successfully. `);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const turnExpertOffline = async (id) => {
    try {
      await API.graphql({
        query: updateUser,
        variables: { input: { id, online: false, available: false } },
      });
      setLoading(true);
      setTimeout(() => {
        getData();
      }, 1000);
      toast.success(`Expert turned offline successfully.`);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    setToken(null);
    setTokens([]);
    setNextToken(null);
  }, [display]);

  useEffect(() => {
    setLoading(true);
    let debounce = setTimeout(() => {
      getData();
    }, 1200);
    return () => clearTimeout(debounce);
  }, [search, nextToken, type, display, sort?.key, sort?.direction]);

  useEffect(() => {
    async function getTypes() {
      let expertTypes = await API.graphql({
        query: listExpertTypes,
      });
      setExpertTypes(expertTypes.data.listExpertTypes?.items);
    }
    getTypes();
  }, []);

  return (
    <div className="container-fluid">
      <div className="expertspage-innerContainer">
        <div className="recommended-experts-container-outer">
          {/* header starts */}
          <div className="row align-items-center">
            <div className="col">
              <h6 className="header-pretitle">Overview</h6>

              <h1 className="header-title text-truncate">Experts ({total})</h1>
            </div>
            <div className="col-auto">
              <button
                onClick={() => navigate("/expert-types")}
                className="btn btn-primary ms-2"
                style={{
                  backgroundColor: "#50C7C3",
                  border: "none",
                  display: allowViewer && "none",
                }}
              >
                Manage Expert Types
              </button>
            </div>
          </div>
          <div className="border-bottom border-1 my-1">
            <div className="row align-items-center">
              <div className="col">
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <div
                      className={`nav-link ${display === 0 ? "active" : ""}`}
                      onClick={() => setDisplay(0)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>All</b>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link ${display === 1 ? "active" : ""}`}
                      onClick={() => setDisplay(1)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>Verified</b>
                    </div>
                  </li>
                  <li className="nav-item ">
                    <div
                      className={`nav-link ${
                        display === 3 ? "active" : ""
                      } d-flex`}
                      onClick={() => setDisplay(3)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>Rejected</b>
                    </div>
                  </li>
                  <li className="nav-item ">
                    <div
                      className={`nav-link ${
                        display === 4 ? "active" : ""
                      } d-flex align-items-center`}
                      onClick={() => setDisplay(4)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>Online</b>
                      <img src={"/images/ping.gif"} alt="ping" height={16} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* header ends */}
          <div className="recommended-experts-container-content">
            {/* starts here */}
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="tab-content">
                  <div>
                    <div className="card">
                      <div className="card-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="input-group input-group-flush input-group-merge input-group-reverse">
                              <input
                                className="form-control list-search"
                                type="search"
                                placeholder="Search by name, username or email"
                                value={search}
                                onChange={(e) => {
                                  setTokens([]);
                                  setToken(null);
                                  setSearch(e.target.value);
                                }}
                              />
                              <span className="input-group-text">
                                <i className="fe fe-search"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-auto" style={{ width: "240px" }}>
                            <Select
                              isClearable
                              key={type}
                              theme={selectColor}
                              placeholder="Select Expert Type"
                              value={type}
                              options={expertTypes.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                setTokens([]);
                                setType(e);
                              }}
                            />
                          </div>
                          <div className="col-auto">
                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setToken(null);
                                setTokens([]);
                                setSearch("");
                                setType(null);
                              }}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      {loading ? (
                        <img
                          src="/images/loader.svg"
                          alt="loader"
                          height={"100px"}
                        />
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-sm table-hover table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>
                                  <span className="text-muted">UserName</span>
                                </th>
                                <th>
                                  <span className="text-muted">Name</span>
                                </th>
                                <th>
                                  <span className="text-muted">
                                    Expert Type
                                  </span>
                                </th>
                                <th>
                                  <span className="text-muted">Email</span>
                                </th>
                                <th>
                                  <span className="text-muted">Status</span>
                                </th>
                                <th style={{ cursor: "pointer" }}>
                                  <span
                                    className="text-muted"
                                    data-sort="item-name"
                                    onClick={() => {
                                      setSort({
                                        field: "callRate",
                                        direction:
                                          sort.direction === "asc"
                                            ? "desc"
                                            : "asc",
                                      });
                                      setTokens([]);
                                      setToken(null);
                                    }}
                                  >
                                    Call Rate
                                  </span>
                                </th>
                                <th style={{ cursor: "pointer" }}>
                                  <span
                                    className="text-muted"
                                    data-sort="item-name"
                                    onClick={() => {
                                      setSort({
                                        field: "payoutRate",
                                        direction:
                                          sort.direction === "asc"
                                            ? "desc"
                                            : "asc",
                                      });
                                      setTokens([]);
                                      setToken(null);
                                    }}
                                  >
                                    Payout Rate
                                  </span>
                                </th>
                                {allowAdmin && (
                                  <th>
                                    <span className="text-muted"></span>
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody className="list fs-base">
                              {expertsData.map((data, index) => (
                                <tr key={data?.id}>
                                  <td>
                                    <div
                                      className="avatar avatar-xs align-middle me-2"
                                      onClick={() =>
                                        navigate(`/expert_detail/${data?.id}`)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        className="avatar-img rounded-circle"
                                        src={data?.media || "/images/user.png"}
                                        alt="avatar"
                                      />
                                    </div>
                                    <span
                                      className="item-name text-reset"
                                      onClick={() =>
                                        navigate(`/expert_detail/${data?.id}`)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {data?.username || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="item-title">
                                      {data?.name || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="item-title">
                                      {data?.expertType?.name || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="item-email text-reset">
                                      {data?.email || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`item-score badge ${
                                        data?.verified
                                          ? "bg-success-soft"
                                          : "bg-danger-soft"
                                      }`}
                                    >
                                      {data?.verified === null && "Pending"}
                                      {data?.verified && "Verfied"}
                                      {data?.verified === false && "Rejected"}
                                    </span>
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(
                                      data?.callRate ||
                                        data?.expertType?.callRate ||
                                        0
                                    )}{" "}
                                    <i
                                      className="fe fe-edit-3 pointer"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleUpdateCallRate(
                                          data?.id,
                                          data?.callRate ||
                                            data?.expertType?.callRate
                                        );
                                      }}
                                    ></i>
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(data?.payoutRate || 0.5)}{" "}
                                    <i
                                      className="fe fe-edit-3 pointer"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleApprove(
                                          data?.id,
                                          data?.payoutRate || 0.5,
                                          data?.verified,
                                          data?.expertType?.callRate
                                        );
                                      }}
                                    ></i>
                                  </td>
                                  {allowAdmin && (
                                    <td className="text-end">
                                      <div className="dropdown">
                                        <span
                                          className="dropdown-ellipses dropdown-toggle"
                                          role="button"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fe fe-more-vertical"></i>
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <span
                                            style={{ cursor: "pointer" }}
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(`/expert/${data?.id}`);
                                            }}
                                          >
                                            Edit
                                          </span>
                                          {data?.verified ? (
                                            <span
                                              style={{ cursor: "pointer" }}
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemove(data?.id, index);
                                              }}
                                            >
                                              Block
                                            </span>
                                          ) : (
                                            <>
                                              <span
                                                style={{ cursor: "pointer" }}
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleApprove(
                                                    data?.id,
                                                    data?.payoutRate || 0.5,
                                                    data?.verified,
                                                    data?.expertType?.callRate
                                                  );
                                                }}
                                              >
                                                Approve
                                              </span>
                                              {data?.verified !== false && (
                                                <span
                                                  style={{ cursor: "pointer" }}
                                                  className="dropdown-item"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemove(
                                                      data?.id,
                                                      index
                                                    );
                                                  }}
                                                >
                                                  Reject
                                                </span>
                                              )}
                                            </>
                                          )}
                                          {data?.online && (
                                            <span
                                              style={{ cursor: "pointer" }}
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                turnExpertOffline(data?.id);
                                              }}
                                            >
                                              Turn offline
                                            </span>
                                          )}
                                          <span
                                            style={{ cursor: "pointer" }}
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              deleteExpert(
                                                data?.id,
                                                data?.name || data?.username,
                                                index
                                              );
                                            }}
                                          >
                                            Delete
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {expertsData.length === 0 && !loading && (
                        <p className="text-center m-3">No Expert found</p>
                      )}
                      <Pagination
                        handleNext={handleNext}
                        handleFirst={handleFirst}
                        length={tokens.length}
                        token={token}
                        total={expertsData?.length}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experts;
