import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DropUpload from "../components/DropUpload";
import DropVideo from "../components/DropVideo";
import { fileUpload, selectColor } from "../utils/utils";


const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      instructorId
      instructor {
        id
        username
        name
      }
      courseCategoryRelation {
        items {
          id
          courseCategory {
            id
            name
          }
        }
      }
      title
      description
      duration
      thumbnail
      cover
      trailer
      createdAt
      updatedAt
    }
  }
`;
const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        name
        expert
      }
      nextToken
      total
    }
  }
`;
const listCourseCategories = /* GraphQL */ `
  query ListCourseCategories(
    $filter: ModelCourseCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        thumbnail
        cover
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
const deleteCourseCategoryRelation = /* GraphQL */ `
  mutation DeleteCourseCategoryRelation(
    $input: DeleteCourseCategoryRelationInput!
  ) {
    deleteCourseCategoryRelation(input: $input) {
      id
    }
  }
`;
const createCourseCategoryRelation = /* GraphQL */ `
  mutation CreateCourseCategoryRelation(
    $input: CreateCourseCategoryRelationInput!
  ) {
    createCourseCategoryRelation(input: $input) {
      id
    }
  }
`;
const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
  ) {
    createCourse(input: $input) {
      id
    }
  }
`;
const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
  ) {
    updateCourse(input: $input) {
      id
    }
  }
`;

const AddCourse = () => {
  const { courseId } = useParams();

  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [instructor, setInstructor] = useState("");
  const [cover, setCover] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [duration, setDuration] = useState("");
  const [trailer, setTrailer] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [courseTags, setCourseTags] = useState([]);
  const [tagRelations, setTagRelations] = useState([]);

  const navigate = useNavigate();

  async function handleDrop(e, file, setState) {
    try {
      e.preventDefault();
      setState(await fileUpload(file));
    } catch (error) {
      Swal.fire("Oops!", "Try again", "error");
      console.log(error);
    }
  }

  const addCourse = async () => {
    setSubmitting(true);
    try {
      let res = await API.graphql({
        query: createCourse,
        variables: {
          input: {
            cover,
            description: description?.trim(),
            free: true,
            instructorId: instructor?.value,
            title: title?.trim(),
            duration,
            thumbnail,
            trailer,
            trending: false,
            publishedAt: new Date().toISOString(),
          },
        },
      });
      for (let i = 0; i < tags.length; i++) {
        await API.graphql({
          query: createCourseCategoryRelation,
          variables: {
            input: {
              courseCategoryId: tags[i].value,
              courseId: res.data.createCourse.id,
            },
          },
        });
      }
      navigate("/courses");
    } catch (e) {
      setSubmitting(false);
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
    }
  };

  const editCourse = async () => {
    setSubmitting(true);
    try {
      let res = await API.graphql({
        query: updateCourse,
        variables: {
          input: {
            id: courseId,
            cover,
            description: description?.trim(),
            free: true,
            instructorId: instructor?.value,
            title: title?.trim(),
            duration,
            thumbnail,
            trailer,
            trending: false,
          },
        },
      });
  
      for (let i = 0; i < tagRelations.length; i++) {
        await API.graphql({
          query: deleteCourseCategoryRelation,
          variables: {
            input: {
              id: tagRelations[i]?.id,
            },
          },
        });
      }

      for (let i = 0; i < tags.length; i++) {
        await API.graphql({
          query: createCourseCategoryRelation,
          variables: {
            input: {
              courseCategoryId: tags[i].value,
              courseId: res.data.updateCourse.id,
            },
          },
        });
      }
      navigate(`/courses/${courseId}`);
    } catch (e) {
      setSubmitting(false);
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
    }
  };

  async function getData() {
    try {
      let categories = await API.graphql({ query: listCourseCategories });
      setCourseTags(categories.data.listCourseCategories.items);

      if (!courseId) setIsLoading(false);
      if (courseId) {
        let res = await API.graphql({
          query: getCourse,
          variables: {
            id: courseId,
          },
        });

        let data = res.data.getCourse;
        let tags = res.data.getCourse?.courseCategoryRelation?.items;
        if (tags) {
          let temp = tags.map((item) => ({
            value: item?.courseCategory?.id,
            label: item?.courseCategory?.name,
          }));
          setTags(temp);
        }
        setTagRelations(data?.courseCategoryRelation?.items);
        setTitle(data?.title);
        setInstructor({
          value: data?.instructorId,
          label: data?.instructor?.username || data?.instructor?.name,
        });
        setDescription(data?.description);
        setCover(data?.cover);
        setDuration(data?.duration);
        setThumbnail(data?.thumbnail);
        setTrailer(data?.trailer);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, "edit");
      Swal.fire("Oops !", error.message, "error");
    }
  }


  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 1000);
  }, []);

  if (isLoading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="container">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">Course</h6>
            <h1 className="header-title">{courseId ? "Edit" : "Add new "} course</h1>
          </div>
        </div>
      </div>
      <hr />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <form
            className="tab-content py-2"
            id="wizardSteps"
            onSubmit={(e) => {
              e.preventDefault();
              if (courseId) {
                editCourse();
              } else {
                addCourse();
              }
            }}
          >
            <div>
              <div className="form-group">
                <label  className="form-label mx-1">
                  Title <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter course title"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label id="topic" className="form-label mx-1">
                  Instructor <span className="text-danger">*</span>
                </label>
                <AsyncSelect 
                theme={selectColor}  
                cacheOptions defaultOptions loadOptions={promiseInstructors} 
                defaultValue={instructor}
                onChange={(e)=>setInstructor(e)}
                />
              </div>
              <div className="form-group">
                <label id="topic" className="form-label mx-1">
                  Categories <span className="text-danger">*</span>
                </label>
                <Select
                  theme={selectColor} 
                  defaultValue={tags.map((item) => item)}
                  isMulti
                  name="colors"
                  options={courseTags.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => setTags(e)}
                />
              </div>

              <div className="form-group">
                <label  className="form-label mx-1">
                  Duration <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter course duration eg. 6 hrs"
                  required
                  value={duration || ""}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label  className="form-label mx-1">
                  Description
                </label>
                <ReactQuill
                  theme="snow"
                  value={description || ""}
                  onChange={setDescription}
                />
              </div>

              <DropUpload
                name="Thumbnail"
                state={thumbnail}
                setState={setThumbnail}
                handleDrop={handleDrop}
                required={true}
              />
              <DropUpload
                name="Cover"
                state={cover}
                setState={setCover}
                handleDrop={handleDrop}
                required={true}
              />
              <DropVideo
                name="Trailer"
                state={trailer}
                setState={setTrailer}
                handleDrop={handleDrop}
                type="video"
                accept="video/mp4"
              />

              <hr className="my-5" />

              <div className="nav row align-items-center mb-3">
                {isLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <div className="col-auto">
                    <button
                      type="submit"
                      disabled={
                        cover === null ||
                        thumbnail === null ||
                        tags?.length === 0 ||
                        submitting
                      }
                      style={{ background: "#50c7c3", border: "none" }}
                      className="btn btn-primary "
                    >
                      {submitting ? (
                        <div
                          className="spinner-border text-white"
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          role="status "
                        ></div>
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                    {!submitting && (
                      <button
                        onClick={() => courseId ? navigate(`/courses/${courseId}`) : navigate(`/courses`)}
                        type="submit"
                        className="btn btn-light mx-2"
                        disabled={submitting}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCourse;



export const filterInstructors = async(inputValue) => {
  try {
   let filter =  {expert: { eq: true }}
   if (inputValue.length !== 0) {
     filter = {
      //  expert: { eq: true },
       or: [
         {username: { wildcard: "*" + inputValue + "*",},},
         {username: {matchPhrasePrefix: inputValue,},},
         {name: {matchPhrasePrefix: inputValue,},},
         {id: {wildcard: "*" + inputValue + "*",},},
         {email: {matchPhrasePrefix: inputValue,},},
       ],
     };
   }
   let res = await API.graphql({
     query: searchUsers,
     variables: {
       limit: 1000,
       filter:filter,
     },
   });
   let values = res.data.searchUsers.items.map(item=>{
     return { label: item?.username || item?.name || "-", value: item.id };
   })
   return values
  } catch (error) {
   console.log(error)
  }
 };
 
 export const promiseInstructors = (inputValue) =>
   new Promise((resolve) => {
     setTimeout(() => {
       resolve(filterInstructors(inputValue));
     }, 1000);
   });