import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { selectColor } from "../utils/utils";

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name
      tagline
      profile
      expertTypeId
      callRate
      payoutRate
      expertType {
        id
        name
        callRate
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      id
      active
      verified
      username
      name
      expert
      callRate
    }
  }
`;
const listExpertTypes = /* GraphQL */ `
  query ListExpertTypes(
    $filter: ModelExpertTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpertTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        media
        experts{
          items{
            id
          }
        }
        callRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const EditExpert = () => {
  const  { id }  = useParams();
  const navigate = useNavigate();

  const [expert,setExpert] = useState({})
  const [expertTypes,setExpertTypes] = useState([])
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getData(){
    try {
      let res = await API.graphql({query : getUser,variables:{
        id : id
      }})

      let expertTypeRes =await API.graphql({
        query: listExpertTypes,
      })
      setExpertTypes(expertTypeRes.data.listExpertTypes?.items);
      setExpert(res.data.getUser)
      console.log(res.data.getUser)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }  

  async function updateExpert(){
    setSubmitting(true)
    try {
      let res = await API.graphql({query : updateUser,variables:{
        input :{
          id : id,
          name : expert?.name,
          username:expert?.username,
          profile:expert?.profile,
          expertTypeId:expert?.expertTypeId,
          callRate : expertTypes.find(item=>item?.id === expert?.expertTypeId)?.callRate
        }
      }})
      toast.success('Expert updated successfully !')
      navigate(-1)
      setSubmitting(false)
    } catch (error) {
      toast.error('Failed to edit expert !')
      console.log(error)
    }
  }

  useEffect(()=>{
    getData()
  },[])

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="container-xl container-fluid">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">Expert</h6>
            <h1 className="header-title">
              Edit Expert Details
            </h1>
          </div>
        </div>
      </div>
      <hr />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <form
            className="tab-content py-1"
            id="wizardSteps"
            onSubmit={(e) => {
              e.preventDefault();
              updateExpert()
            }}
          >
            <div>
              <div className="form-group">
                <label  className="form-label mx-1">
                  Username <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Enter username"
                  value={expert?.username}
                  onChange={(e)=>setExpert({...expert,username:e.target.value})}
                />
              </div>
              <div className="form-group">
                <label  className="form-label mx-1">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  value={expert?.name}
                  onChange={(e)=>setExpert({...expert,name:e.target.value})}
                />
              </div>
              <div className="form-group">
                <label  className="form-label mx-1">
                  Expert Type
                </label>
                <Select
                  theme={selectColor} 
                  defaultValue={{value:expert?.expertType?.id,label:expert?.expertType?.name}}
                  options={expertTypes.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => setExpert({...expert,expertTypeId:e.value})}
                />
              </div>

              <div className="form-group">
                <label  className="form-label mx-1">
                  Bio 
                </label>
                <textarea 
                  className="form-control"
                  placeholder="Enter Bio"
                  value={expert?.profile}
                  rows="4" cols="50"
                  onChange={(e)=>setExpert({...expert,profile:e.target.value})}
                />
              </div>
              <div className="nav row align-items-center">
                <div className="col-auto mb-3">
                  <button
                    type="submit"
                    style={{ background: "#50c7c3", border: "none" }}
                    className="btn btn-primary"
                  >
                    {submitting ? (
                      <div
                        className="spinner-border text-white"
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        role="status"
                      ></div>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  {!submitting && (
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="btn btn-light mx-2"
                      disabled={submitting}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditExpert;
