import React from "react";

export default function ContactUs() {
  return (
    <div>
      <div className="text-center">
        <img src="/images/Yelling.png" alt="yelling" height={"200px"} />
        <h1>Contact Us</h1>
        <h3 className="text-muted">Updated on August 10, 2023</h3>
      </div>
      <div className="card p-5 container">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium
        repellendus possimus voluptatum officiis, ab ipsa explicabo placeat
        quis. Ullam ab facere deleniti delectus! Esse, in nemo rerum, natus ab
        aut nulla inventore porro eligendi voluptatum vitae quasi qui vero
        excepturi, totam veritatis maxime sapiente deserunt. Excepturi, cumque
        ut accusamus commodi amet ipsam sint voluptatem nobis et quisquam
        numquam enim placeat, voluptatibus voluptate, iusto inventore?
        Necessitatibus dolorum, ad eum sed eligendi quis explicabo aut
        voluptatem veritatis ratione alias autem accusantium hic, aliquam dolor
        quas, odio consequatur sunt! Repudiandae omnis culpa accusamus commodi
        odio impedit pariatur possimus explicabo laborum modi dolor tenetur
        adipisci doloribus, facere itaque qui ullam, exercitationem rem dolorem
        placeat iusto non dolore quia. Fuga itaque atque, ipsam nostrum
        molestias a aperiam reprehenderit laboriosam quae unde. At deleniti ea.
        <br />
        <br />
        laudantium ducimus quo cupiditate. Lorem ipsum, dolor sit amet
        consectetur adipisicing elit. Nihil id, optio eaque repellendus,
        repellat provident nemo facilis temporibus odio ipsa quam totam delectus
        velit, odit sit! Velit, non. Dolor laborum nihil amet voluptas!
      </div>
    </div>
  );
}
