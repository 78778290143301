import React, { useRef } from "react";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";

export default function DropVideo({
  name,
  state,
  setState,
  handleDrop,
  type,
  accept,
}) {
  const imgInputRef = useRef();

  return (
    <div className="mb-4">
      {name && <label className="form-label mx-1">{name}</label>}
      <div
        className="card"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          e.dataTransfer.files[0].type.includes(type)
            ? handleDrop(e, e.dataTransfer.files[0], setState)
            : Swal.fire("Oops !", `Only ${type}s are allowed.`, "info");
        }}
      >
        <div className="card-body">
          <div
            className="dropzone dropzone-multiple"
            role="presentation"
            tabIndex="0"
          >
            <input
              key={new Date().getMilliseconds()}
              type="file"
              accept={accept}
              tabIndex="-1"
              hidden
              ref={imgInputRef}
              onChange={async (e) => handleDrop(e, e.target.files[0], setState)}
            />
            <div className="dz-message">
              Drop files or{" "}
              <button
                type="button"
                className="btn btn-primary btn-sm mx-2 upload-btn"
                onClick={() => imgInputRef.current.click()}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      {state && (
        <>
          {type === "audio" && (
            <audio controls key={new Date().getMilliseconds()}>
              <source src={state?.url} type="audio/mp3" />
            </audio>
          )}
          {type === "video" && (
            <ReactPlayer
              url={state?.url || state}
              controls={true}
              width="280px"
              height="160px"
            />
          )}
          {type === "img" && (
            <img
              src={state?.url || state}
              alt="image"
              width="280px"
              height="160px"
            />
          )}
        </>
      )}
    </div>
  );
}
