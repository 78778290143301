import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DropUpload from "../components/DropUpload";
import { fileUpload } from "../utils/utils";

const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name
      media
    }
  }
`;

const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      id
    }
  }
`;

const EditUser = () => {
  const navigate = useNavigate();
  const {id} = useParams()

  const [name, setName] = useState("");
  const [username,setUsername] = useState("")
  const [cover, setCover] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [loading,setLoading] = useState(true)

  const editData = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: id,
            name,
            username,
            media: cover,
          },
        },
      });
      setSubmitting(false);
      Swal.fire("Voila !", "Profile updated successfully.", "success");
      navigate(-1);
    } catch (e) {
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
      setSubmitting(false);
    }
  };

  async function handleDrop(e, file, setState) {
    try {
      e.preventDefault();
      setState(await fileUpload(file));
    } catch (error) {
      Swal.fire("Oops!", "Try again", "error");
      console.log(error);
    }
  }

  async function getData(){
    try {
        let res = await API.graphql({ query : getUser,variables:{id: id}})
        let data = res.data.getUser
        setName(data.name)
        setUsername(data.username)
        setCover(data.media)

    } catch (error) {
        console.log(error)
    }finally{setLoading(false)}
  }

  useEffect(() => {
   getData()
  }, [])

  if (loading)return <img src="/images/loader.svg" alt="loader" className="loader" />;
  
  return (
    <div className="container-xl container-fluid">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">Profile</h6>
            <h1 className="header-title">Edit User Profile</h1>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div>
          <form
            className="tab-content py-2"
            id="wizardSteps"
            onSubmit={editData}
          >
            <div>
              <label  className="form-label mx-1 mt-2">
                Username <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label  className="form-label mx-1">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="my-3">
                <DropUpload
                  name="Avatar"
                  state={cover}
                  setState={setCover}
                  handleDrop={handleDrop}
                  required={false}
                />
              </div>

              <div className="nav row align-items-center">
                <div className="col-auto">
                  <button
                    type="submit"
                    className="btn btn-primary upload-btn"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <div
                        className="spinner-border text-white"
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        role="status "
                      ></div>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  {!submitting && (
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="btn btn-light mx-2"
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
