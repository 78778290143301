import { VideoQuality } from "@zoom/videosdk";
import { API } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendStreamPushNotification } from "../pages/PushNotifications";
import { useSelector } from "react-redux";
import { updateZoomSession } from "../graphql/mutations";

export const STREAM_URL = "rtmp://live.restream.io/live";
export const STREAM_KEY = "re_7931084_0776399e5a7b142c7b28";
export const BROADCAST_URL = "https://app.restream.io/";

const VideoCall = ({
  session,
  jwt,
  url,
  sendPush,
  notificationTitle,
  notificationMessage,
  multistreamingEnabled,
  client,
}) => {
  const [inSession, setInSession] = useState(false);
  const [startingSession, setStartingSession] = useState(false);
  const [sessionId, setSessionId] = useState();

  const [liveStreamHelper, setLiveStreamHelper] = useState(null);

  const videoContainerRef = useRef(null);
  const user = useSelector((state) => state.userInfo.payload);

  const navigate = useNavigate();

  const joinSession = useCallback(async () => {
    setStartingSession(true);
    await client.current.init("en-US", "Global", {
      webEndpoint: "zoom.us",
      patchJsMedia: true,
    });
    client.current.on("peer-video-state-change", (payload) =>
      renderVideo(payload)
    );
    const userName = user.name || user.username || "Expert";

    await client.current.join(session, jwt, userName);
    // console.log("client", client.current.getSessionInfo().sessionId);

    setSessionId(client.current.getSessionInfo().sessionId);
    setInSession(true);
    const mediaStream = client.current.getMediaStream();
    await mediaStream.startVideo({
      originalRatio: true,
      mediaFile: {
        url,
      },
    });
    await mediaStream.startAudio({
      mediaFile: {
        url,
      },
    });

    const recordingClient = client.current.getRecordingClient();

    if (recordingClient.canStartRecording()) {
      recordingClient.startCloudRecording();
    }

    if (multistreamingEnabled) {
      const liveStreamClient = client.current.getLiveStreamClient();
      liveStreamClient.startLiveStream(STREAM_URL, STREAM_KEY, BROADCAST_URL);
      setLiveStreamHelper(liveStreamClient);
    }

    await renderVideo({
      action: "Start",
      userId: client.current.getCurrentUserInfo().userId,
    });
    setStartingSession(false);
  }, [jwt, multistreamingEnabled, session, url, sessionId]);

  const renderVideo = async (event) => {
    const mediaStream = client.current.getMediaStream();
    if (event.action === "Stop") {
      const element = await mediaStream.detachVideo(event.userId);
      Array.isArray(element)
        ? element.forEach((el) => el.remove())
        : element.remove();
    } else {
      const userVideo = await mediaStream.attachVideo(
        event.userId,
        VideoQuality.Video_720P
      );
      videoContainerRef.current.appendChild(userVideo);
    }
  };

  const leaveSession = useCallback(async () => {
    client.current.off("peer-video-state-change", (payload) =>
      renderVideo(payload)
    );
    await client.current.leave(true);

    if (client?.current?.getRecordingClient) {
      const recordingClient = client.current.getRecordingClient();
      recordingClient.stopCloudRecording();
    }
    navigate("/livestreams");
  }, [client]);

  useEffect(() => {
    if (jwt && session && url && !inSession) {
      joinSession().then(() => {
        if (sendPush) {
          API.graphql({
            query: sendStreamPushNotification,
            variables: {
              input: {
                title: notificationTitle,
                message: notificationMessage,
                type: "STREAM",
              },
            },
          });
        }
      });
    }

    return () => {
      if (multistreamingEnabled && liveStreamHelper) {
        liveStreamHelper.stopLiveStream();
      }
    };
  }, [
    joinSession,
    jwt,
    session,
    url,
    liveStreamHelper,
    multistreamingEnabled,
    notificationTitle,
    notificationMessage,
    sendPush,
    inSession,
  ]);

  useEffect(() => {
    if (sessionId) {
      // API.graphql({
      //   query: updateZoomSession,
      //   variables: {
      //     input: {
      //       sessionId,
      //       isRecVideo: true,
      //     },
      //   },
      // });
    }

    window.onbeforeunload = leaveSession;
  }, [sessionId, leaveSession]);

  return (
    <div className="col-8">
      <h1>Session: {session}</h1>
      <div style={inSession ? {} : { display: "none" }}>
        <video-player-container ref={videoContainerRef} />
      </div>
      {startingSession ? (
        <div className="d-flex justify-content-center m-4">
          <img src="/images/loader.svg" alt="loader" height={"100px"} />
        </div>
      ) : (
        <div>
          {inSession && (
            <button
              onClick={leaveSession}
              className="btn btn-primary ms-2"
              style={{
                backgroundColor: "red",
                border: "none",
              }}
            >
              End stream
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoCall;
