import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate, useParams } from "react-router-dom";
import { formattedTime, limit } from "../utils/utils";

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState([]);
  const [courses, setCourses] = useState([]);
  const [display, setDisplay] = useState(1);
  const [posts, setPosts] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);

  const getUserQuery = `query getUser{
    getUser(id: "${id}") {
        calls(nextToken: null, limit: ${limit}, sortDirection: DESC) {
          items {
            callAmount
            callDuration
            callEnd
            callStart
            createdAt
            expertFeedback
            userCallRating
            userFeedback
            expert {
              name
              username
            }
            expertCallRating
            payoutAmount
          }
          nextToken
        }
        courses {
          items{
            id
            title
            thumbnail
            lessonCount
            likeCount
            lessons{
              items{
                id
              }
            }
            likes{
              items{
                id
              }
            }
            duration
            instructor{
              id
              media
              username
              name
            }
          } 
        }
        media
        name
        username
    }
  }`;

  const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: [SearchablePostSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        author {
          id
          name
          media
        }
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;


  async function getData() {
    try {
      let res = await API.graphql({
        query: getUserQuery,
      });
      setLoading(false);
      setUser(res.data?.getUser);
      setCourses(res.data?.getUser?.courses?.items);
    } catch (error) {
      console.log(error);
    }
  }

  async function getPosts() {
    try {
      let res = await API.graphql({
        query: searchPosts,
        variables: {
          nextToken: nextToken,
          filter: {
            authorId: { eq: id },
          },
          limit: 10,
        },
      });
      setPosts((prev) => [...prev, ...res.data?.searchPosts.items]);
      setToken(res.data?.searchPosts.nextToken);
      setLoadMore(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    getPosts();
  }, [nextToken]);

  function handleNext() {
    setLoadMore(true);
    setNextToken(token);
    setToken(null);
  }

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="mx-6 my-6">
      <div className="header ">
        <div className="header">
          <div className="header-body mt-n5 mt-md-n6">
            <div className="row align-items-end">
              <div className="col-auto">
                <div className="avatar avatar-lg header-avatar-top">
                  <img
                    src={user?.media || "/images/user.png"}
                    alt="..."
                    className="avatar-img rounded-circle border border-4 border-body"
                  />
                </div>
              </div>
              <div className="col mb-3 ms-n3 ms-md-n2">
                <h6 className="header-pretitle">User</h6>

                <h1 className="header-title">{user?.username || user?.name || "-"}</h1>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col">
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <div
                      className={`nav-link ${display === 1 ? "active" : ""}`}
                      onClick={() => setDisplay(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Posts
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link ${display === 2 ? "active" : ""}`}
                      onClick={() => setDisplay(2)}
                      style={{ cursor: "pointer" }}
                    >
                      Call History
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link ${display === 3 ? "active" : ""}`}
                      onClick={() => setDisplay(3)}
                      style={{ cursor: "pointer" }}
                    >
                      Courses
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {display === 1 && (
        <>
          {posts.length === 0 && <b>No post found</b>}
          {posts.map((post) => (
            <div
              className="card"
              key={post?.id}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/post/${post?.id}`)}
            >
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div className="avatar">
                      <img
                        src={user?.media || "/images/user.png"}
                        alt={user?.name}
                        className="avatar-img rounded-circle"
                      />
                    </div>
                  </div>

                  <div className="col ms-n3">
                    <h4 className="mb-1 text-dark">
                      {user?.username || user?.name || "-"}
                    </h4>
                    <p className="card-text small text-muted">
                      {formattedTime(post?.publishedAt || post?.createdAt)}
                    </p>
                  </div>
                </div>

                <strong className="mt-2 mb-1">{post?.title}</strong>
                <p className="mb-3 small">{post?.description?.slice(0,400)}...</p>
                <div onClick={(e) => e.stopPropagation()} className="mb-2">
                  <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={4000}
                    showThumbs={false}
                  >
                    {post?.attachments && JSON.parse(post?.attachments)?.map((item, index) => (
                      <div className="text-center" key={index}>
                        {item.type === "Image" ? (
                          <img
                            loading="lazy"
                            src={item?.url || "/images/randomImage.jpg"}
                            style={{
                              height: "500px",
                              objectFit: "cover",
                            }}
                            alt="..."
                            className="img-fluid rounded"
                          />
                        ) : (
                          <video
                            controls
                            height={"100%"}
                            width={"100%"}
                          >
                            <source src={item?.url} type="video/mp4"></source>
                          </video>
                        )}
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div>
                <span className="border border-1 px-2 py-1 rounded ms-2">
                    <i className="fe fe-thumbs-up" /> {post?.likeCount || 0}
                  </span>

                  <span className="border border-1 px-2 py-1 rounded ms-2">
                    <i className="fe fe-message-square" />{" "}
                    {post?.commentCount || 0}
                  </span>

                  <span className="border border-1 px-2 py-1 rounded ms-2">
                    <i className="fe fe-flag text-danger" />{" "}
                    {post?.reportCount || 0}
                  </span>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex">
            {loadMore ? (
              <img
                src="/images/loader.svg"
                alt="loader"
                width={"40px"}
                className="mx-auto"
              />
            ) : (
              <>
                {token && (
                  <button
                    className="btn btn-sm upload-btn mx-auto my-3"
                    onClick={handleNext}
                  >
                    Load more
                  </button>
                )}
              </>
            )}
          </div>
        </>
      )}
      {display === 2 && (
        <>
          {user?.calls?.items.length === 0 ? (
            <b>No call records</b>
          ) : (
            <div className="table-responsive card">
              <table className="table table-sm table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span className="text-muted">Amount</span>
                    </th>
                    <th>
                      <span className="text-muted">Duration</span>
                    </th>
                    <th>
                      <span className="text-muted">Start</span>
                    </th>
                    <th>
                      <span className="text-muted">End</span>
                    </th>
                    <th className="text-center">
                      <span className="text-muted">Expert Rating</span>
                    </th>
                    <th className="text-center">
                      <span className="text-muted">User Rating</span>
                    </th>
                    <th>
                      <span className="text-muted">Payout Amount</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="list fs-base">
                  {user?.calls?.items?.map((item) => (
                    <tr key={item?.id}>
                      <td>
                        <span className="item-title">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(item?.callAmount)}
                        </span>
                      </td>
                      <td>
                        <span className="item-title">
                          {item?.callDuration ? item?.callDuration > 1 ? item?.callDuration +" mins" : item?.callDuration + " min" : "-" } 
                        </span>
                      </td>
                      <td>
                        <span className="item-title">
                          {item?.callStart && new Date(item?.callStart).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        <span className="item-title">
                          {item?.callEnd && new Date(item?.callEnd).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        {item?.expertCallRating && 
                        ( <div className="item-title d-flex gap-2 align-items-center justify-content-center" title={item?.expertFeedback || "No Feedback"}>
                          {item?.expertCallRating} <i className="fe fe-message-circle"></i>
                        </div>
                          )}
                      </td>
                      <td>
                        {item?.userCallRating && (
                          <div className="item-title d-flex gap-2 align-items-center justify-content-center" title={item?.userFeedback || "No Feedback"}>
                          {item?.userCallRating} <i className="fe fe-message-circle"></i>
                        </div>
                          )}
                      </td>
                      <td>
                        <span className="item-title">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(item?.payoutAmount)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
      {display === 3 && (
        <>
          {courses.length === 0 ? (
            <b>No course found</b>
          ) : (
            <div className="table-responsive card">
              <table className="table table-sm table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th style={{ width: "40rem" }}>
                      <span className="text-muted">Title</span>
                    </th>
                    <th style={{ width: "40rem" }}>
                      <span className="text-muted">Instructor</span>
                    </th>
                    <th className="text-center">
                      <span className="text-muted">Lessons</span>
                    </th>
                    <th className="text-center">
                      <span className="text-muted">Dutation</span>
                    </th>
                    <th className="text-center">
                      <span className="text-muted">Likes</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="list fs-base">
                  {courses?.map((item) => (
                    <tr
                      key={item?.id}
                      onClick={() => navigate(`/courses/${item?.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>
                        <span className="item-title">{item?.title}</span>
                      </td>

                      <td className="w-25">
                        <div className="avatar avatar-xs align-middle me-2">
                          <img
                            className="avatar-img rounded-circle"
                            src={ item?.instructor?.media ||"/images/user.png"}
                            alt="user"
                          />
                        </div>
                        <span
                          style={{ cursor: "pointer" }}
                          className="item-name"
                        >
                          {item?.instructor?.name || "-"}
                        </span>
                      </td>

                      <td className="text-center">
                        <span className="item-title ">
                          {item?.lessons?.items?.length || 0}
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="item-title">
                          {item?.duration || "_"}
                        </span>
                      </td>
                      <td className="text-center">
                        {item?.likes?.items?.length || 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserDetails;

