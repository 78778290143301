import { API } from "aws-amplify";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from "chart.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { options } from "./Dashboard";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name
      role
      expert
      media
      tagline
      createdAt
      verified
      courses {
        items {
          id
          instructorId
          instructor {
            id
            username
            name
            media
          }
          title
          duration
          lessonCount
          lessons{
            items{
              id
            }
          }
          likes{
            items{
              id
            }
          }
          publishedAt
          createdAt
          updatedAt
        }
      }
      expertType{
        name
      }
      expertCalls {
        items {
          id
          callStart
          callEnd
          callDuration
          callAmount
          payoutRate
          payoutAmount
          userCallRating
          userFeedback
          expertCallRating
          expertFeedback
          user {
            name
            username
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const ExpertDetail = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [currentExpert, setCurrentExpert] = useState([]);
  const [courses, setCourses] = useState([]);
  const [display, setDisplay] = useState(1);
  const [chartData,setChartData] = useState([])

  const data = {
    labels : chartData.map(item=>item.date.slice(0,5)),
    datasets: [
      {
        label: "Calls Per Day",
        data: chartData.map(item=>item.count),
        borderColor: "#50c7c3",
        backgroundColor: "#50c7c3",
        borderRadius: 4,
      },
    ],
  };

  useEffect(() => {
    API.graphql({
      query: getUser,
      variables: {
        id: id,
      },
    })
      .then((res) => {
        setLoading(false);
        setCurrentExpert(res.data?.getUser);
        setCourses(res.data?.getUser?.courses?.items);

        let dateArray = [];

        for (let i = 30; i >= 0; i--) {
          let date = new Date();
          date.setDate(date.getDate() - i);
          let day = ("0" + date.getDate()).slice(-2);
          let month = ("0" + (date.getMonth() + 1)).slice(-2);
          let year = date.getFullYear().toString().substr(-2);
          let formattedDate = `${day}-${month}-${year}`;
          dateArray.push(formattedDate);
        }

        let data = res.data?.getUser?.expertCalls?.items;

        const ordersByDay = data?.reduce((acc, order) => {
          const dateStr = order.createdAt.slice(0, 10);
          const date = new Date(dateStr);
          const formattedDate = date
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
            .replace(/\//g, "-");

          if (acc[formattedDate]) {
            acc[formattedDate]++;
          } else {
            acc[formattedDate] = 1;
          }
          return acc;
        }, {});

        if (dateArray.length !== 0) {
          dateArray.forEach((date) => {
            if (!ordersByDay[date]) {
              ordersByDay[date] = 0;
            }
          });
        } else {
          dateArray.forEach((date) => {
            if (!ordersByDay[date]) {
              ordersByDay[date] = 0;
            }
          });
        }

        let ordersByDayArr = Object.keys(ordersByDay).map((dateStr) => ({
          date: dateStr,
          count: ordersByDay[dateStr],
        }));
        let x = ordersByDayArr.sort((a, b) => {
          const dateA = new Date(`20${a.date.split("-").reverse().join("-")}`);
          const dateB = new Date(`20${b.date.split("-").reverse().join("-")}`);
          return dateA - dateB;
        });
        setChartData(x);
      })
      .catch((e) => console.log(e));
  }, [id]);


  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="mx-6 my-6">
      <div className="header ">
        <div className="header">
          <div className="header-body mt-n5 mt-md-n6">
            <div className="row align-items-end">
              <div className="col-auto">
                <div className="avatar avatar-lg header-avatar-top">
                  <img
                    src={currentExpert?.media || "/images/user.png"}
                    alt="..."
                    className="avatar-img rounded-circle border border-4 border-body"
                  />
                </div>
              </div>
              <div className="col mb-3 ms-n3 ms-md-n2">
                <h6 className="header-pretitle">Expert</h6>
                <h1 className="header-title">
                  {currentExpert?.username || currentExpert?.name || "-"} {currentExpert?.expertType?.name && `(${currentExpert?.expertType?.name})`}
                </h1>
              </div>
              <div className="col-auto">
              <Link to={`/expert/${currentExpert?.id}`}>
                <button className="btn btn-primary upload-btn">
                  Edit
                </button>
              </Link>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col">
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <div
                      className={`nav-link ${display === 1 ? "active" : ""}`}
                      onClick={() => setDisplay(1)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>Overview</b>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link ${display === 2 ? "active" : ""}`}
                      onClick={() => setDisplay(2)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>Call History</b>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link ${display === 3 ? "active" : ""}`}
                      onClick={() => setDisplay(3)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>Courses</b>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {display === 1 && (
        <div>
         
          <div className="card p-4">
            <p>Onboarded on - <b>{moment(currentExpert?.createdAt).format("DD-MM-YYYY")}</b></p>
            <p>
            Current Status -  
            <span
            className={`item-score badge ${currentExpert?.verified ? "bg-success-soft" : "bg-danger-soft"}`}>
            {currentExpert?.verified === null && "Pending"}
            {currentExpert?.verified && "Verfied"}
            {currentExpert?.verified === false && "Rejected"}
          </span>
          </p>
          </div>
          <h3 className="header-pretitle">Call Rate</h3>
          <div
            style={{ height: "40vh", width: "80vw", overflowX: "auto" }}
            className="card p-5"
          >
            <Bar key={chartData} options={options} data={data} />
          </div>
        </div>
      )}
      {display === 2 && (
        <>{currentExpert?.expertCalls?.items?.length === 0 ? <b>No Calls Found</b>:(
          <div className="table-responsive card">
            {loading ? (
              <div className="d-flex justify-content-center m-4">
                <img src="/images/loader.svg" alt="loader" height={"100px"} />
              </div>
            ) : (
              <table className="table table-sm table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span className="text-muted">User Name</span>
                    </th>
                    <th>
                      <span className="text-muted">Amount</span>
                    </th>
                    <th>
                      <span className="text-muted">Duration</span>
                    </th>
                    <th>
                      <span className="text-muted">Start</span>
                    </th>
                    <th>
                      <span className="text-muted">End</span>
                    </th>
                    <th className="text-center">
                      <span className="text-muted">Expert Rating</span>
                    </th>
                    <th className="text-center">
                      <span className="text-muted">User Rating</span>
                    </th>
                    <th>
                      <span className="text-muted">Payout Amount</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="list fs-base">
                  {currentExpert?.expertCalls?.items?.map((item) => (
                    <tr key={item?.id}>
                      <td>
                        <span>{item?.user?.username || item?.user?.name || "-"}</span>
                      </td>
                      <td>
                        <span className="item-title">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(item?.callAmount)}
                        </span>
                      </td>
                      <td>
                        <span className="item-title">
                        {item?.callDuration ? item?.callDuration > 1 ? item?.callDuration +" mins" : item?.callDuration + " min" : "-" } 
                        </span>
                      </td>
                      <td>
                        <span className="item-title">
                          {item?.callStart && new Date(item?.callStart).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        <span className="item-title">
                          {item?.callEnd && new Date(item?.callEnd).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        <span className="item-title">
                        {item?.expertCallRating && (
                          <div className="item-title d-flex gap-2 align-items-center justify-content-center" title={item?.expertFeedback || "No Feedback"}>
                          {item?.expertCallRating} <i className="fe fe-message-circle"></i>
                        </div>)}
                        </span>
                      </td>
                      <td>
                      {item?.userCallRating && (
                        <div className="item-title d-flex gap-2 align-items-center justify-content-center" title={item?.userFeedback || "No Feedback"}>
                        {item?.userCallRating} <i className="fe fe-message-circle"></i>
                      </div>)}
                      </td>
                      <td>
                        <span className="item-title">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(item?.payoutAmount)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>)} 
        </>
      )}
      {display === 3 && (
        <>
        {courses?.length === 0 ? <b>No Course Found</b> : (
          <div className="table-responsive card">
          {loading ? (
            <div className="d-flex justify-content-center m-4">
              <img src="/images/loader.svg" alt="loader" height={"100px"} />
            </div>
          ) : (
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th style={{ width: "40rem" }}>
                    <span className="text-muted">Title</span>
                  </th>
                  <th className="text-center">
                    <span className="text-muted">Lessons</span>
                  </th>
                  <th className="text-center">
                    <span className="text-muted">Dutation</span>
                  </th>
                  <th className="text-center">
                    <span className="text-muted">Likes</span>
                  </th>
                </tr>
              </thead>
              <tbody className="list fs-base">
                {courses.map((item) => (
                  <tr
                    key={item?.id}
                    onClick={() => navigate(`/courses/${item.id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                      <span className="item-title">{item?.title}</span>
                    </td>

                    <td className="text-center">
                      <span className="item-title ">
                        {item?.lessons?.items?.length || 0}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="item-title">{item?.duration || "_"}</span>
                    </td>
                    <td className="text-center">{item?.likes?.items?.length || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        )}
       
        </>
       
      )}
    </div>
  );
};

export default ExpertDetail;

