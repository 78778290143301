import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Pagination from "../components/Pagination";
import { deleteUserFromCognito } from "../graphql/mutations";
import { limit, selectColor } from "../utils/utils";

const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        media
        name
        role
        email
        expert
        verified
        active
        deleted
        credits
      }
      nextToken
      total
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      active
      verified
      username
      name
      expert
      credits
    }
  }
`;

const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [type, setType] = useState(null);

  const [total, setTotal] = useState(0);
  const [tokens, setTokens] = useState([]);

  const [display, setDisplay] = useState(0);

  const user = useSelector((state) => state.userInfo.payload);
  let allowAdmin = user.role.includes("ADMIN");

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }
  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }

  async function getData() {
    try {
      setLoading(true);
      let filter;
      if (search.length !== 0) {
        filter = {
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }
      if (display === 1) {
        filter = { active: { eq: true }, deleted: { ne: true } };
      }

      if (display === 1 && search.length !== 0) {
        filter = {
          active: { eq: true },
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }

      if (display === 2) {
        filter = { active: { eq: false }, deleted: { ne: true } };
      }
      if (display === 3) {
        filter = { deleted: { eq: true } };
      }

      if (display === 2 && search.length !== 0) {
        filter = {
          active: { eq: false },
          deleted: { ne: true },
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }
      if (display === 3 && search.length !== 0) {
        filter = {
          deleted: { eq: true },
          or: [
            { username: { matchPhrasePrefix: search } },
            { email: { matchPhrasePrefix: search } },
            { name: { matchPhrasePrefix: search } },
          ],
        };
      }
      if (type) {
        if (type?.value === "INSTRUCTOR") {
          filter = { expert: { eq: true }, deleted: { ne: true } };
        } else filter = { role: { eq: type?.value } };
      }

      // if(type && display === 1){filter = {role : {eq : type?.value},active : {eq : true}}}
      // if(type && display === 2){filter = {role : {eq : type?.value},active : {eq : false}}}
      // if(type && display === 3){filter = {role : {eq : type?.value},username : {eq : 'deleted'}}}

      let res = await API.graphql({
        query: searchUsers,
        variables: {
          limit,
          nextToken,
          filter,
          sort: { direction: "desc", field: "createdAt" },
        },
      });
      search.length === 0 && setTotal(res.data?.searchUsers.total);
      setLoading(false);
      if (
        res.data?.searchUsers?.items.length !== 0 ||
        res.data?.searchUsers?.token !== null
      ) {
        setUsers(res.data?.searchUsers?.items);
        setTokens((prev) => [...prev, res.data?.searchUsers.nextToken]);
        setToken(res.data?.searchUsers?.nextToken);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function changeRole(value, id, idx, label) {
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Change role to ${label}`,
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          let input = {
            id: id,
            role: [value],
            expert: false,
          };
          if (value === "INSTRUCTOR") {
            input = { ...input, expert: true };
          }
          API.graphql({
            query: updateUser,
            variables: {
              input: input,
            },
          })
            .then((res) => {
              setLoading(false);
              let temp = structuredClone(users);
              temp[idx].role = [value];
              setUsers(temp);
              toast.success(`Role Changed to ${label}`);
            })
            .catch((e) => {
              setLoading(false);
              console.log(e);
            });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function deleteUser(id,name,idx) {
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Delete ${name || "user"}`,
      }).then(async(result) => {
        try {
          if (result.isConfirmed) {
            setLoading(true);
            await API.graphql({query : deleteUserFromCognito ,  variables : {input : {userId : id}}})
            let input = {
              id: id,
              deleted: true,
              active:false
            };
            await API.graphql({
              query: updateUser,
              variables: {
                input: input,
              },
            })
            setLoading(false);
            let temp = structuredClone(users);
            temp[idx].deleted = true;
            temp[idx].active = true;
            setUsers(temp);
            toast.success(`User deleted successfully. `);
          }
        } catch (error) {
          console.log(error)
        }finally{setLoading(false)}
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function changeStatus(active, id, idx) {
    setLoading(true);
    let res = await API.graphql({
      query: updateUser,
      variables: {
        input: {
          id: id,
          active: active,
        },
      },
    });
    let temp = structuredClone(users);
    temp[idx].active = !temp[idx].active;
    active
      ? toast.success(
          `${
            res.data.updateUser.username || res.data.updateUser.name
          } unblocked.`
        )
      : toast.warning(
          `${res.data.updateUser.username || res.data.updateUser.name} blocked.`
        );
    setUsers(temp);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    let debounce = setTimeout(() => {
      getData();
    }, 1400);
    return () => clearTimeout(debounce);
  }, [search, nextToken, display, type]);


  useEffect(() => {
    setType(null);
    setToken(null);
    setTokens([]);
    setNextToken(null);
  }, [display]);

  return (
    <>
      <div className="container-fluid" style={{ marginTop: "30px" }}>
        {/* header starts */}
        <div className="row align-items-center">
          <div className="col">
            <h6 className="header-pretitle">Overview</h6>
            <h1 className="header-title text-truncate">Users ({total || 0})</h1>
          </div>
        </div>
        {/* header ends */}

        <div className="border-bottom border-1 my-1">
          <div className="row align-items-center">
            <div className="col">
              <ul className="nav nav-tabs nav-overflow header-tabs">
                <li className="nav-item">
                  <div
                    className={`nav-link ${display === 0 ? "active" : ""}`}
                    onClick={() => setDisplay(0)}
                    style={{ cursor: "pointer" }}
                  >
                    <b>All</b>
                  </div>
                </li>
                <li className="nav-item">
                  <div
                    className={`nav-link ${display === 1 ? "active" : ""}`}
                    onClick={() => setDisplay(1)}
                    style={{ cursor: "pointer" }}
                  >
                    <b>Active</b>
                  </div>
                </li>
                <li className="nav-item ">
                  <div
                    className={`nav-link ${
                      display === 2 ? "active" : ""
                    } d-flex`}
                    onClick={() => setDisplay(2)}
                    style={{ cursor: "pointer" }}
                  >
                    <b>Blocked</b>
                  </div>
                </li>
                <li className="nav-item ">
                  <div
                    className={`nav-link ${
                      display === 3 ? "active" : ""
                    } d-flex`}
                    onClick={() => setDisplay(3)}
                    style={{ cursor: "pointer" }}
                  >
                    <b>Deleted</b>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="recommended-experts-container-content mx-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            value={search}
                            onChange={(e) => {
                              setTokens([]);
                              setSearch(e.target.value);
                              setToken(null);
                              setType(null);
                            }}
                            className="form-control list-search"
                            type="search"
                            placeholder="Search by name, username or email"
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                      {display === 0 && (
                        <div className="col-auto">
                          <Select
                            isClearable
                            key={type}
                            placeholder="Select User Type"
                            theme={selectColor}
                            defaultValue={type}
                            options={[
                              { v: "ADMIN", l: "ADMIN" },
                              { v: "INSTRUCTOR", l: "EXPERT" },
                              { v: "EDITOR", l: "EDITOR" },
                              { v: "VIEWER", l: "VIEWER" },
                            ].map((item) => ({
                              value: item?.v,
                              label: item?.l,
                            }))}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setToken(null);
                              setTokens([]);
                              setType(e);
                            }}
                          />
                        </div>
                      )}

                      <div className="col-auto">
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            setToken(null);
                            setTokens([]);
                            setSearch("");
                            setType(null);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    {loading ? (
                      <div className="d-flex justify-content-center m-4">
                        <img
                          src="/images/loader.svg"
                          alt="loader"
                          height={"100px"}
                        />
                      </div>
                    ) : (
                      <table className="table table-sm table-hover card-table">
                        <thead>
                          <tr>
                            <th>
                              <span className="text-muted">Username</span>
                            </th>
                            <th>
                              <span className="text-muted">Name</span>
                            </th>
                            <th>
                              <span className="text-muted">Email</span>
                            </th>
                            <th>
                              <span className="text-muted">Status</span>
                            </th>
                            <th>
                              <span className="text-muted">Role</span>
                            </th>
                            {allowAdmin && (
                              <th>
                              <span className="text-muted"></span>
                            </th>
                              )}
                          </tr>
                        </thead>
                        <tbody className="list fs-base">
                          {users?.map((data, idx) => (
                            <tr key={data?.id}>
                              <td>
                                <div
                                  className="avatar avatar-xs align-middle me-2"
                                  onClick={() => navigate(`/user/${data?.id}`)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    className="avatar-img rounded-circle pointer"
                                    src={data?.media || "/images/user.png"}
                                    alt="avatar"
                                  />
                                </div>{" "}
                                <span
                                  className="item-name text-reset"
                                  onClick={() => navigate(`/user/${data?.id}`)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {data?.username || "_"}
                                </span>
                              </td>
                              <td>
                                <span>{data?.name || "_"}</span>
                              </td>
                              <td>
                                <span className="item-title">
                                  {data?.email}
                                </span>
                              </td>
                              <td>
                                {data?.deleted ? (
                                  <span className="item-score badge bg-danger">
                                    Deleted
                                  </span>
                                ) : (
                                  <span
                                    className={`item-score badge ${
                                      data?.active === true
                                        ? "bg-success-soft"
                                        : data?.active === false
                                        ? "bg-danger-soft"
                                        : "bg-white"
                                    }`}
                                  >
                                    {data?.active === true
                                      ? "Active"
                                      : data?.active === false
                                      ? "Blocked"
                                      : "-"}
                                  </span>
                                )}
                              </td>

                              {allowAdmin ? (
                                <td style={{ width: "160px" }}>
                                  <Select
                                    key={new Date().getMilliseconds()}
                                    theme={selectColor}
                                    defaultValue={{
                                      value: data?.role && data?.role[0],
                                      label: data?.role
                                        ? data?.role[0] === "INSTRUCTOR"
                                          ? "EXPERT"
                                          : data?.role[0]
                                        : "",
                                    }}
                                    value={{
                                      value: data?.role && data?.role[0],
                                      label: data?.role
                                        ? data?.role[0] === "INSTRUCTOR"
                                          ? "EXPERT"
                                          : data?.role[0]
                                        : "",
                                    }}
                                    options={[
                                      { v: "ADMIN", l: "ADMIN" },
                                      { v: "INSTRUCTOR", l: "EXPERT" },
                                      { v: "USER", l: "USER" },
                                      { v: "EDITOR", l: "EDITOR" },
                                      { v: "VIEWER", l: "VIEWER" },
                                    ].map((item) => ({
                                      value: item?.v,
                                      label: item?.l,
                                    }))}
                                    onChange={(e) => changeRole( e?.value,data.id,idx,e?.label)}
                                  />
                                </td>
                              ) : (
                                <td>
                                  {data.role ? (
                                    data.role.map((item) => <span>{item}</span>)
                                  ) : (
                                    <span>USER</span>
                                  )}
                                </td>
                              )}
                              {allowAdmin && (
                              <td className="text-end">
                                <div className="dropdown">
                                  <span
                                    className="dropdown-ellipses dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical"></i>
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    {data?.active ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          changeStatus(false, data?.id, idx);
                                        }}
                                      >
                                        Block
                                      </span>
                                    ) : (
                                      <>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            changeStatus(true, data?.id, idx);
                                          }}
                                        >
                                          Unblock
                                        </span>
                                      </>
                                    )}
                                   <span  style={{ cursor: "pointer" }}
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(`/edit-user/${data?.id}`)
                                        }}>Edit</span>
                                        {data?.deleted !== true && (

                                      <span  style={{ cursor: "pointer" }}
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          deleteUser(data?.id,data?.name||data?.username, idx);
                                        }}>Delete</span>)}
                                  </div>
                                </div>
                              </td>)}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {users.length === 0 && !loading && (
                    <p className="text-center m-3">No user found</p>
                  )}
                  <Pagination
                    handleNext={handleNext}
                    handleFirst={handleFirst}
                    length={tokens.length}
                    token={token}
                    total={users?.length}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
