import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { formattedTime, handleDelete } from "../utils/utils";
import { deleteCourse, deleteLesson } from "../graphql/mutations";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const searchLessons = /* GraphQL */ `
  query SearchLessons(
    $filter: SearchableLessonFilterInput
    $sort: [SearchableLessonSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchLessons(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        position
        title
        description
        takeaways
        thumbnail
        cover
        audio
        video
        content
        slides
        attachments
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      instructorId
      instructor {
        id
        media
        username
        name
      }
      title
      description
      duration
      thumbnail
      cover
      trailer
      lessonCount
      likeCount
      publishedAt
      lessons {
        items {
          id
        }
        nextToken
      }
      reviews {
        nextToken
      }
      likes {
        items {
          id
          createdAt
          user {
            media
            username
          }
        }
        nextToken
      }
      createdAt
    }
  }
`;

const CourseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentCourse, setCurrentCourse] = useState([]);

  const [lessons, setLessons] = useState([]);
  const [display, setDisplay] = useState(1);

  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => state.userInfo.payload);
  let allowViewer = user.role.includes("VIEWER");

  useEffect(() => {
    getData();
  }, [id]);

  async function getData() {
    try {
      let res = await API.graphql({
        query: getCourse,
        variables: {
          id: id,
        },
      });
      let lessons = await API.graphql({
        query: searchLessons,
        variables: {
          filter: { courseId: { eq: id } },
          sort: { direction: "desc", field: "createdAt" },
        },
      });
      setLessons(lessons.data.searchLessons.items);
      setCurrentCourse(res.data.getCourse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDeleteCourse() {
    if (currentCourse?.lessons?.items?.length !== 0) {
      Swal.fire(
        "Oops !",
        "Kindly delete the lessons associated with this course first.",
        "info"
      );
      return;
    }
    handleDelete(currentCourse.id, deleteCourse, "course", () => {
      navigate("/courses");
    });
  }

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <>
      {currentCourse && (
        <div className="mx-5 my-4">
          <div className="header">
            <div className="row justify-content-between align-items-center">
              <div className="col">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div className="avatar">
                      <img
                        src={currentCourse?.thumbnail}
                        alt="..."
                        className="avatar-img rounded"
                      ></img>
                    </div>
                  </div>
                  <div className="col ms-n2">
                    <small className="text-muted">Course</small>
                    <h2
                      className="header-title text-truncate"
                      style={{ whiteSpace: "initial" }}
                    >
                      {currentCourse?.title}
                    </h2>
                  </div>
                </div>
              </div>
              {!allowViewer && (
                <div className="col-auto">
                  <button
                    className="btn btn-primary upload-btn mx-2"
                    onClick={() => navigate(`/edit-course/${id}`)}
                  >
                    Edit Course
                  </button>
                  <button
                    className="btn btn-light mx-2"
                    onClick={() => handleDeleteCourse()}
                  >
                    Delete Course
                  </button>
                  <button
                    className="btn btn-primary upload-btn "
                    onClick={() => navigate(`/add-lesson/${id}`)}
                  >
                    Add Lesson
                  </button>
                </div>
              )}
            </div>

            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <ul className="nav nav-tabs nav-overflow header-tabs">
                    <li className="nav-item">
                      <div
                        className={`nav-link ${display === 1 ? "active" : ""}`}
                        onClick={() => setDisplay(1)}
                        style={{ cursor: "pointer" }}
                      >
                        <b>Overview</b>
                      </div>
                    </li>
                    <li className="nav-item ">
                      <div
                        className={`nav-link ${
                          display === 2 ? "active" : ""
                        } d-flex`}
                        onClick={() => setDisplay(2)}
                        style={{ cursor: "pointer" }}
                      >
                      <b>Lessons</b>
                        <span
                          className="mx-2 badge_info"
                          style={{ color: "white" }}
                        >
                          {currentCourse?.lessons?.items?.length || 0}
                        </span>
                      </div>
                    </li>
                    <li className="nav-item ">
                      <div
                        className={`nav-link ${
                          display === 3 ? "active" : ""
                        } d-flex`}
                        onClick={() => setDisplay(3)}
                        style={{ cursor: "pointer" }}
                      >
                        <b>Likes</b>
                        <span
                          className="mx-2 badge_info"
                          style={{ color: "white" }}>
                          {currentCourse?.likes?.items?.length || 0}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {display === 1 && (
            <div>
              <div className="row">
                <div className="col-12 col-xl-8">
                  <div className="card mb-6">
                    <div className="card-body">
                      <h3>Description</h3>
                      <div className="mb-3">
                        {currentCourse.description ?
                          parse(currentCourse?.description) : "No description available"}
                      </div>

                      <img
                        src={currentCourse?.cover}
                        alt="..."
                        className="img-fluid rounded"
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-header-title">Instructor</h4>
                    </div>
                    <div className="card-body">
                      <div className="row g-0 align-items-center justify-content-center" style={{cursor:"pointer"}} onClick={()=>navigate(`/expert_detail/${currentCourse?.instructor?.id}`)}>
                        <img
                          src={
                            currentCourse?.instructor?.media ||
                            "/images/user.png"
                          }
                          className="rounded-circle"
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                          alt="Avatar"
                        />
                      </div>
                      <div className="my-4">
                        <h3 className="text-uppercase text-center text-muted">
                          {currentCourse?.instructor?.username || currentCourse?.instructor?.name || "-"}
                        </h3>
                        <p className="text-center text-muted">
                          {currentCourse?.instructor?.tagline}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {display === 2 && (
            <>
              {lessons?.length === 0 ? (
                <p className="text-center"><b>No Lessons added yet !</b></p>
              ) : (
                <div className="card">
                  <div className="card-body">
                    <ul className="list-group list-group-lg list-group-flush list my-n4">
                      {lessons?.map((item) => (
                        <li className="list-group-item" key={item?.id}>
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="avatar avatar-lg">
                                <img
                                  src={
                                    item?.thumbnail || "/images/fallback.jpg"
                                  }
                                  alt="thumbnail"
                                  className="avatar-img rounded"
                                />
                              </span>
                            </div>
                            <div className="col ms-n2">
                              <h4 className="mb-1 name">{item?.title}</h4>

                              <p className="card-text small text-muted mb-1">
                                {(
                                  JSON.parse(item?.video || item?.audio)
                                    ?.duration / 60000 || 0
                                ).toFixed(2)}{" "}
                                min
                              </p>
                            </div>

                            {!allowViewer && (
                              <div className="col-auto">
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="dropdown-ellipses dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <Link
                                      to={`/edit-lesson/${item?.id}`}
                                      className="dropdown-item"
                                    >
                                      Edit
                                    </Link>
                                    <button
                                      href="#!"
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleDelete(
                                          item?.id,
                                          deleteLesson,
                                          "lesson",
                                          getData
                                        )
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </>
          )}
          {display === 3 && (
            <>
              {currentCourse?.likes?.items?.length === 0 && (
                <p className="text-center"><b>No Likes Yet !</b></p>
              )}
              {currentCourse?.likes?.items?.map((item) => (
                <div className="card mb-3" key={item?.id}>
                  <div className="card-body p-3">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <img
                          src={item?.user?.media || "/images/user.png"}
                          alt="liked_user"
                          className="rounded-circle avatar"
                        />
                      </div>
                      <div className="col ms-n2">
                        <h4 className="mb-1">{item?.user?.username}</h4>

                        <p className="card-text small text-muted mb-1">
                          {formattedTime(item?.createdAt)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CourseDetails;


