import React from "react";
import { Link, useLocation } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();

  return (
    <nav
      className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
      id="sidebar"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarCollapse"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link className="navbar-brand p-2">
          <img
            src="https://clarityapp.io/wp-content/uploads/brizy/imgs/LOGOv2-70x70x0x0x70x70x1676058053.png"
            alt=".."
            style={{ width: "50px", height: "50px", maxHeight: "none" }}
          />
        </Link>
        <div className="navbar-user d-md-none">
          <div className="dropdown">
            <a
              href="#"
              className="dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className={"avatar avatar-md mx-auto"}>
                <img
                  className="avatar-img rounded-circle"
                  alt={"avatar"}
                  src={
                    "https://dashkit.goodthemes.co/assets/img/avatars/profiles/avatar-1.jpg"
                  }
                />
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              <Link to="/profile" className="dropdown-item">
                My Profile
              </Link>
              <Link to="/logout" className="dropdown-item">
                Logout
              </Link>
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="sidebarCollapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("dashboard") ? "active" : ""
                }`}
                to={"/dashboard"}
                role="button"
                aria-expanded="false"
              >
                <i className="fe fs-2 fe-grid"></i> Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("posts") ? "active" : ""
                }`}
                to={"/posts"}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarAuth"
              >
                <i className="fe fs-2 fe-book-open"></i> Forum
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("articles") ? "active" : ""
                }`}
                to={"/articles"}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarAuth"
              >
                <i className="fe fs-2 fe-clipboard"></i> Articles
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("courses") ? "active" : ""
                }`}
                to={"/courses"}
                role="button"
                aria-expanded="false"
              >
                <i className="fe fs-2 fe-book"></i> Courses
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("livestreams") ? "active" : ""
                }`}
                to={"/livestreams"}
                role="button"
                aria-expanded="false"
              >
                <i className="fe fs-2 fe-radio"></i> Clarity TV
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("subscriptions") ? "active" : ""
                }`}
                to={"/subscriptions"}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarAuth"
              >
                <i className="fe fs-2 fe-pocket"></i> Subscriptions
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("experts") ? "active" : ""
                }`}
                to={"/experts"}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarAuth"
              >
                <i className="fe fs-2 fe-user-check"></i> Experts
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("users") ? "active" : ""
                }`}
                to={"/users"}
                role="button"
                aria-expanded="false"
              >
                <i className="fe fs-2 fe-users"></i> Users
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#sidebarDashboards"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarDashboards"
              >
                <i className="fe fs-2 fe-phone"></i> Hotline
              </a>
              <div className="collapse" id="sidebarDashboards">
                <ul className="nav nav-sm flex-column">
                  {/* <li className="nav-item">
                    <Link to={"/hotline-credits"} className="nav-link ">
                      Hotline Credits
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link to={"/transactions"} className="nav-link ">
                      Transactions
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/callhistory"} className="nav-link ">
                      Call History
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("promocode") ? "active" : ""
                }`}
                to={"/referral-codes"}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarAuth"
              >
                <i className="fe fs-2 fe-tag"></i> Referral Codes
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname.includes("push-notifications")
                    ? "active"
                    : ""
                }`}
                to={"/push-notifications"}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarAuth"
              >
                <i className="fe fs-2 fe-bell"></i> Push Notifications
              </Link>
            </li>
          </ul>
          <hr className="navbar-divider my-3" />
        </div>
      </div>
    </nav>
  );
};

export default SideBar;
