import { useNavigate } from "react-router-dom";
import { formatDuration } from "../utils/utils";
import moment from "moment";

const SingleStream = ({ streamData }) => {
  const navigate = useNavigate();

  return (
    <tr
      onClick={(e) => {
        e.preventDefault();
        navigate(`/livestreams/${encodeURIComponent(streamData.sessionId)}`);
      }}
      style={{ cursor: "pointer" }}
    >
      <td>
        <span className="item-title">{streamData?.sessionName}</span>
      </td>
      <td className="text-center">
        <span className="item-title">
          {formatDuration(streamData.endTime, streamData.startTime) || "-"}
        </span>
      </td>
      <td className="text-center">
        <span className="item-title">
          {streamData?.active ? (
            <span style={{ color: "red" }}>Live</span>
          ) : (
            moment(streamData.endTime).format("MMM Do YYYY")
          )}
        </span>
      </td>
    </tr>
  );
};

export default SingleStream;
