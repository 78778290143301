import React, { useRef } from "react";
import { fileUpload } from "../utils/utils";
import Swal from "sweetalert2";

export default function DropUpload({
  name,
  state,
  setState,
  handleDrop,
  required,
}) {
  const imgInputRef = useRef();

  return (
    <div className="mb-4">
      <label  className="form-label mx-1">
        {name} {required && <span className="text-danger">*</span>}
      </label>
      <div
        className="card"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          e.dataTransfer.files[0].type.includes("image")
            ? handleDrop(e, e.dataTransfer.files[0], setState)
            : Swal.fire("Oops !", "Only Images are allowed.", "info");
        }}
      >
        <div className="card-body">
          <div
            className="dropzone dropzone-multiple"
            role="presentation"
            tabIndex="0"
          >
            <input
              key={new Date().getMilliseconds()}
              type="file"
              accept="image/*"
              tabIndex="-1"
              hidden
              ref={imgInputRef}
              onChange={async (e) =>{
               if(!e.target.files[0].type.includes('image')){
                Swal.fire("Oops !", 'Only Images are allowed.' , 'error')
                return;
               }
                setState(await fileUpload(e.target.files[0]))}
              }
            />
            <div className="dz-message">
              Drop files or{" "}
              <button
                type="button"
                className="btn btn-primary btn-sm mx-2 upload-btn"
                onClick={() => imgInputRef.current.click()}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      {state && (
        <img
          style={{ borderRadius: ".4rem" }}
          src={state}
          alt="cover_image"
          height={"100px"}
          key={new Date().getMilliseconds()}
        />
      )}
    </div>
  );
}
