import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import AsyncSelect from "react-select/async";
import CloseButton from "react-bootstrap/CloseButton";
import { formattedTime, limit, selectColor } from "../utils/utils";
import { API } from "aws-amplify";
import { updateUser } from "./Users";
import Swal from "sweetalert2";

export default function UpdateCredits({
  showUpdateCredits,
  setShowUpdateCredits,
  selectColor,
  promiseUsers,
  setToken,
  setTokens,
  getData,
  create,
}) {
  const [user, setUser] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [credits, setCredits] = useState("");
  const searchUsers = /* GraphQL */ `
    query SearchUsers(
      $filter: SearchableUserFilterInput
      $sort: [SearchableUserSortInput]
      $limit: Int
      $nextToken: String
    ) {
      searchUsers(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          username
          name
          credits
        }
      }
    }
  `;
  const createUserHotlineCredits = /* GraphQL */ `
    mutation CreateUserHotlineCredits(
      $input: CreateUserHotlineCreditsInput!
      $condition: ModelUserHotlineCreditsConditionInput
    ) {
      createUserHotlineCredits(input: $input, condition: $condition) {
        id
        userId
        description
        credits
      }
    }
  `;
  useEffect(() => {
    if (user?.label?.length > 0) {
      setCredits("");
      async function getUserData() {
        try {
          let res = await API.graphql({
            query: searchUsers,
            variables: {
              filter: { username: { eq: user.label } },
            },
          });
          if (res?.data?.searchUsers?.items) {
            let data = res.data.searchUsers;
            setSelectedUser(data.items[0]);
          }
        } catch (error) {
          throw error;
        }
      }
      getUserData();
    } else {
      setCredits("");
    }
  }, [user]);
  const updateUserCredits = async () => {
    try {
      let updateHotineCredits = await API.graphql({
        query: createUserHotlineCredits,
        variables: {
          input: {
            userId: selectedUser?.id,
            credits: create ? credits : "-" + credits,
          },
        },
      });
      setShowUpdateCredits(false);
      Swal.fire("Success", "Credits Updated.", "success");
      setUser("");
      setTimeout(() => {
        getData();
      }, 1000);
    } catch (error) {
      Swal.showValidationMessage(`${"Enter valid credits"}`);
    }
  };
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showUpdateCredits}
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ width: "100%" }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "30px", color: "#545454" }}
          >
            {create ? "Add" : "Remove"} Credits
            {/* <CloseButton onClick={() => setShowAddCredits(false)} /> */}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AsyncSelect
          isClearable
          placeholder="Search user"
          key={user}
          theme={selectColor}
          cacheOptions
          defaultOptions
          loadOptions={promiseUsers}
          defaultValue={user}
          onChange={(e) => {
            setTokens([]);
            setToken(null);
            setUser(e);
          }}
        />
        {selectedUser?.username && user ? (
          <input
            value={credits}
            onChange={(e) =>
              setCredits(
                !create && e.target.value > selectedUser.credits
                  ? selectedUser.credits
                  : e.target.value
              )
            }
            placeholder={`${create ? "Add" : "Remove"} Credits`}
            className="form-control mt-4"
            style={{ width: "100%" }}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="col-auto">
          {selectedUser?.username && user ? (
            <button
              type="submit"
              style={{ background: "#50c7c3", border: "none" }}
              className="btn btn-primary mx-3"
              onClick={updateUserCredits}
            >
              <span>Submit</span>
            </button>
          ) : null}
          <button
            className="btn btn-light"
            onClick={() => {
              setShowUpdateCredits(false);
              setUser("");
            }}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
