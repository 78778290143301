import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";


export const sendStreamPushNotification = /* GraphQL */ `
  mutation SendStreamPushNotification(
    $input: SendStreamPushNotificationInput!
  ) {
    sendStreamPushNotification(input: $input)
  }
`;

const PushNotifications = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const notificationTypes = [{title: "NOTIFICATION"}, { title: "STREAM" }];

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);



  async function handleSendNotification() {
    setSubmitting(true);
    try {
      let res = await API.graphql({
        query: sendStreamPushNotification,
        variables: {
          input: {
            message,
            title,
            type: notificationType,
          },
        },
      });
      console.log(res);
      toast.success("Notification has been sent.");
      setMessage("");
      setTitle("");
      setNotificationType("NOTIFICATION");
      setSubmitting(false);
    } catch (error) {
      toast.error("Failed to send Notification.");
      setSubmitting(false);
      console.log(error);
    }
  }

  useEffect(() => {
    if(notificationType === 'STREAM') {
      setTitle('Clarity TV')
      setMessage('We are live now! Join us!')
    }
    else{
      setTitle('')
      setMessage('')
    }
  }, [notificationType])
  

  return (
    <div className="container-xl container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-12 text-center mt-6">
                <h1 className="mb-3">
                  Please enter a title and a message for the push notification.
                </h1>

                <p className="mb-5 text-muted">
                  It will be sent to everyone who has the Clarity app installed,
                  has a user account, and has enabled push notifications.
                </p>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSendNotification();
              }}
            >
              <div className="form-group">
                <label className="form-label mx-1">Title</label>

                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Enter the title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label className="form-label mx-1">Message</label>
                {/* <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={setDescription}
                  placeholder="Content goes here..."
                /> */}
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  placeholder="Enter the message "
                />
              </div>

              <div className="form-group">
                <label className="form-label mx-1">Type</label>
                <Select
                  onChange={(e) => {
                    setNotificationType(e.value);
                  }}
                  options={notificationTypes.map((item) => ({
                    value: item.title,
                    label: item.title,
                  }))}
                />
              </div>

              <div className="nav row align-items-center">
                <div className="col-auto">
                  <button
                    type="submit"
                    style={{ background: "#50c7c3", border: "none" }}
                    className="btn btn-primary "
                  >
                    {submitting ? (
                      <div
                        className="spinner-border text-white"
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        role="status "
                      ></div>
                    ) : (
                      <span>Send</span>
                    )}
                  </button>
                  {!submitting && (
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="btn btn-light mx-2"
                      disabled={submitting}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PushNotifications;
