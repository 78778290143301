import { API, Auth, Hub } from "aws-amplify";
import { Children, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const SignIn = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [disableBtn, setDisableBtn] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    handleAuth();
  });

  Hub.listen("auth", (data) => {
    if (data.payload.event === "signIn_failure") {
      Swal.fire("Oops!", "Failed to Login", "error");
    }
  });

  async function handleAuth() {
    try {
      let cogntioRes = await Auth.currentAuthenticatedUser();
      let currentUser = await API.graphql({
        query: getUser,
        variables: { id: cogntioRes.attributes.sub },
      });
      let roleArray = currentUser.data.getUser?.role;
      if(!currentUser.data.getUser?.active){
        Auth.signOut();
        navigate("/");
        toast.warning("Unauthorized !")
        return;
      }
      console.log(roleArray)
      if (
        !roleArray?.includes("ADMIN") &&
        !roleArray?.includes("VIEWER") &&
        !roleArray?.includes("EDITOR")
      ) {
        setLoading(false);
        toast.warning("Unauthorized !")
        Auth.signOut();
        return;
      }
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <>
      <div
        className="d-flex align-items-center "
        style={{ display: "block", height: "100vh" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-md-0">
              <div className="text-center">
                <img
                  src="https://dashkit.goodthemes.co/assets/img/illustrations/happiness.svg"
                  alt="..."
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
              <div className="signin-page-logo">
                <div className="navbar-brand p-2 mb-1 d-flex align-items-center justify-content-center">
                  <img
                    src="https://clarityapp.io/wp-content/uploads/brizy/imgs/LOGOv2-70x70x0x0x70x70x1676058053.png"
                    alt=".."
                    style={{
                      width: "50px",
                      height: "50px",
                      maxHeight: "none",
                    }}
                  />
                </div>
              </div>

              <h1
                className="display-4 text-center mb-1"
                style={{ color: "#50C7C3", border: "none" }}
              >
                Sign in
              </h1>

              <p className="text-muted text-center mb-5">
                Clarity Admin Panel.
              </p>

              {!disableBtn && (
                <>
                  <button
                    onClick={() => {
                      setDisableBtn(true);
                      Auth.federatedSignIn({ provider: "Google" });
                    }}
                    className="btn btn-lg w-100 btn-primary mb-3 "
                    type="submit"
                    style={{ backgroundColor: "#50C7C3", border: "none" }}>
                     <b>Google</b>
                  </button>

                  <button
                    onClick={() => {
                      setDisableBtn(true);
                      Auth.federatedSignIn({ provider: "SignInWithApple" });
                    }}
                    className="btn btn-lg w-100 btn-primary mb-3 "
                    type="submit"
                    style={{ backgroundColor: "#50C7C3", border: "none" }}>
                   <b>Apple</b>
                  </button>
                </>
              )}

              {disableBtn && (
                <button
                  className="btn btn-lg w-100 btn-primary mb-3"
                  type="submit"
                  disabled={disableBtn}
                  style={{ backgroundColor: "#50C7C3", border: "none" }}
                >
                  <div
                    className="spinner-border text-white"
                    style={{ height: "25px", width: "25px" }}
                    role="status "
                  ></div>
                </button>
              )}
            </div>
          </div>
        </div>
        {/* <button onClick={siggnup}>hello</button> */}
      </div>
    </>
  );
};

export default SignIn;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name
      email
      phone
      role
      expert
      verified
      active
      online
      media
      createdAt
      updatedAt
    }
  }
`;