import { API } from "aws-amplify";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Pagination from "../components/Pagination";
import { limit, selectColor } from "../utils/utils";
import AsyncSelect from "react-select/async";
import { promiseUsers } from "./Subscriptions";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const searchReferralCodes = /* GraphQL */ `
  query SearchReferralCodes(
    $filter: SearchableReferralCodeFilterInput
    $sort: [SearchableReferralCodeSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchReferralCodes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        spentByReferals
        referralCodeUserId
        userPurchaseCredits
        userRegistrationCredits
        referralCodeUsage {
          items {
            spent,
            user {
              username
            }
          }
        }
        user {
          username
          name
        }
        code
        expireAt
        limit
      }
      nextToken
      total
    }
  }
`;
const updateReferralCode = /* GraphQL */ `
  mutation UpdateReferralCode($input: UpdateReferralCodeInput!) {
    updateReferralCode(input: $input) {
      id
    }
  }
`;

const ReferralCodes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [referral, setReferral] = useState([]);
  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [user, setUser] = useState(null);
  const [referalCode, setReferalCode] = useState("");
  const [uid, setUid] = useState(null);
  const [value, onChange] = useState(null);
  const [dateFilters, setDateFilters] = useState(null);
  const [dateChange, setDateChange] = useState({
    id: null,
    index: null,
  });
  const [addReferal, setAddreferal] = useState(false);
  const [adduser, setAdduser] = useState(null);
  const [modreferal, setModreferal] = useState([]);

  async function getData() {
    setLoading(true);
    try {
      let filter;
      if (user) {
        filter = {
          referralCodeUserId: { eq: user?.value },
        };
      }
      let res = await API.graphql({
        query: searchReferralCodes,
        variables: {
          limit: limit,
          filter: filter,
          nextToken: nextToken,
          sort: { direction: "desc", field: "createdAt" },
        },
      });
      setLoading(false);
      setToken(res.data.searchReferralCodes.nextToken);
      // if (res.data.searchReferralCodes.items.length === 0) {
      //   return;
      // }
      setReferral(res.data.searchReferralCodes.items);
      setTokens((prev) => [...prev, res.data.searchReferralCodes.nextToken]);
    } catch (error) {
      console.log(error);
    }
  }
  async function getModalData() {
    setLoading(true);
    try {
      let filter;
      if (adduser) {
        filter = {
          referralCodeUserId: { eq: adduser?.value },
        };
      }
      let res = await API.graphql({
        query: searchReferralCodes,
        variables: {
          limit: limit,
          filter: filter,
          nextToken: nextToken,
          sort: { direction: "desc", field: "createdAt" },
        },
      });
      setLoading(false);
      setToken(res.data.searchReferralCodes.nextToken);
      // if (res.data.searchReferralCodes.items.length === 0) {
      //   return;
      // }
      setModreferal(res.data.searchReferralCodes.items);
      setTokens((prev) => [...prev, res.data.searchReferralCodes.nextToken]);
    } catch (error) {
      console.log(error);
    }
  }

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    setNextToken(null);
    setToken(null);
  }

  async function handleEditLimit(id, limit, idx) {
    Swal.fire({
      title: "Enter Limit",
      input: "text",
      inputValue: limit || "",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: `Enter referral limit for user`,
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (rate) => {
        await API.graphql({
          query: updateReferralCode,
          variables: {
            input: {
              id: id,
              limit: rate,
            },
          },
        })
          .then((res) => {
            let temp = structuredClone(referral);
            temp[idx].limit = rate;
            setReferral(temp);
            toast.success("Limit Updated.");
          })
          .catch((err) => {
            console.log(err);
            Swal.showValidationMessage(`${"Enter valid payout rate"}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }
  async function handlePurchaseCredits(id, credits, idx) {
    Swal.fire({
      title: "Enter Subsciption Credits",
      input: "number",
      inputValue: credits || "",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: `Enter subsciption credits for user`,
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (rate) => {
        await API.graphql({
          query: updateReferralCode,
          variables: {
            input: {
              id: id,
              referrerPurchaseCredits: rate,
              userPurchaseCredits: rate,
            },
          },
        })
          .then((res) => {
            let temp = structuredClone(referral);
            temp[idx].userPurchaseCredits = rate;
            setReferral(temp);
            toast.success("Credits Updated.");
          })
          .catch((err) => {
            console.log(err);
            Swal.showValidationMessage(`${"Enter valid credits"}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }
  async function handleRegistrationCredits(id, credits, idx) {
    Swal.fire({
      title: "Enter Registration Credits",
      input: "number",
      inputValue: credits || "",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: `Enter registration credits for user`,
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (rate) => {
        await API.graphql({
          query: updateReferralCode,
          variables: {
            input: {
              id: id,
              userRegistrationCredits: rate,
              referrerRegistrationCredits: rate,
            },
          },
        })
          .then((res) => {
            let temp = structuredClone(referral);
            temp[idx].userRegistrationCredits = rate;
            setReferral(temp);
            toast.success("Credits Updated.");
          })
          .catch((err) => {
            console.log(err);
            Swal.showValidationMessage(`${"Enter valid credits"}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }
  useEffect(() => {
    getData();
  }, [nextToken, user]);
  useEffect(() => {
    getModalData();
  }, [nextToken, adduser]);

  // ============REFERAL CODE GENERATOR============== //
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    setCopied(false);
    console.log("entered");
  }, [referalCode]);

  const handleCopyReferal = async (id) => {
    setReferalCode("");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "https://clarityio.page.link",
        link: `https://clarityio.page.link//referal?referal=${id}`,
        androidInfo: {
          androidPackageName: "com.clarity.io",
        },
        iosInfo: {
          iosBundleId: "global.smartdate.appv2",
          iosAppStoreId: '6448745081',
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBGZYijNBDA_4Yk9g_vbGFFUPa4aes7r7E",
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        const shortLink = result.shortLink;
        setReferalCode(shortLink);
        setCopied(true);
        navigator.clipboard.writeText(
          `Check out Clarity's 24/7 Relationship Hotline! Speak to certified relationship coaches, psychics, and astrologers on-demand anytime, anywhere. Sign up with my link: ${shortLink}`
        );
        toast.success("Copied to clipboard");
      } else {
        console.error("Error fetching short link:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching short link:", error);
    }
  };
  const handleDateApplied = async (event, picker) => {
    // setTokens([]);
    const fromDate = moment(picker.startDate).toISOString();
    if (fromDate) {
      await API.graphql({
        query: updateReferralCode,
        variables: {
          input: {
            id: dateChange.id,
            expireAt: fromDate,
          },
        },
      })
        .then((res) => {
          let temp = structuredClone(referral);
          temp[dateChange.index].expireAt = fromDate;
          setReferral(temp);
          toast.success("Date Updated.");
        })
        .catch((err) => {
          console.log(err);
          Swal.showValidationMessage(`${"Enter valid credits"}`);
        })
        .finally(() => {
          setDateChange({
            id: null,
            index: null,
          });
        });
    }
  };
  const createReferralCode = /* GraphQL */ `
    mutation CreateReferralCode(
      $input: CreateReferralCodeInput!
      $condition: ModelReferralCodeConditionInput
    ) {
      createReferralCode(input: $input, condition: $condition) {
        id
        __typename
      }
    }
  `;
  const handleCreateReferal = async () => {
    try {
      let res = await API.graphql({
        query: createReferralCode,
        variables: {
          input: {
            code: adduser?.value,
            expireAt: moment()
              .add(1, "year")
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            limit: 5,
            referralCodeUserId: adduser?.value,
            referrerPurchaseCredits: 5,
            referrerRegistrationCredits: 1,
            userPurchaseCredits: 5,
            userRegistrationCredits: 1,
          },
        },
      });
      console.log(res.data.createReferralCode);
      setAddreferal(false);
      Swal.fire("Success", "Referal code generated.", "success");
      setAdduser(null);
      setTimeout(() => {
        getData();
      }, 2000);
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <div className="container-fluid my-5">
        {/* header starts */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="col">
            <h6 className="header-pretitle">Overview</h6>
            <h1 className="header-title text-truncate">Referral Codes</h1>
          </div>
          <div>
            <button
              type="submit"
              style={{ background: "#50c7c3", border: "none" }}
              className="btn btn-primary "
              onClick={() => setAddreferal(true)}
            >
              <span>Add Referral Code</span>
            </button>
          </div>
        </div>
        {/* header ends */}
        <div className="recommended-experts-container-content">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="contactsListPane"
                  role="tabpanel"
                  aria-labelledby="contactsListTab"
                >
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col"></div>
                        <div className="col-auto" style={{ minWidth: 200 }}>
                          <AsyncSelect
                            isClearable
                            placeholder="Search by user"
                            key={user}
                            theme={selectColor}
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseUsers}
                            defaultValue={user}
                            onChange={(e) => {
                              setTokens([]);
                              setToken(null);
                              setUser(e);
                            }}
                          />
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-light"
                            onClick={() => {
                              setTokens([]);
                              setToken(null);
                              setNextToken(null);
                              setUser(null);
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <div className="d-flex justify-content-center m-4">
                          <img
                            src="/images/loader.svg"
                            alt="loader"
                            height={"100px"}
                          />
                        </div>
                      ) : (
                        <table className="table table-sm table-hover table-nowrap card-table">
                          <thead>
                            <tr>
                              <th className="text-center">
                                <span className="text-muted">User Name</span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">
                                  Registration Credits
                                </span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">
                                  Subscription Credits
                                </span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">Usage Limit</span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">Expires date</span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">Invite Link</span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">Spend Amount</span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">Registered customers</span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">Purchase History</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list fs-base">
                            {referral?.map((item, idx) => (
                              <tr key={item?.id}>
                                <td className="text-center">
                                  <span>
                                    {item?.user?.username ||
                                      item?.user?.name ||
                                      "-"}
                                  </span>
                                </td>
                                <td className="text-center">
                                  {item?.userRegistrationCredits}{" "}
                                  <i
                                    className="fe fe-edit-3 pointer"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleRegistrationCredits(
                                        item?.id,
                                        item?.userRegistrationCredits,
                                        idx
                                      )
                                    }
                                  ></i>
                                </td>
                                <td className="text-center">
                                  {item?.userPurchaseCredits}{" "}
                                  <i
                                    className="fe fe-edit-3 pointer"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handlePurchaseCredits(
                                        item?.id,
                                        item?.userPurchaseCredits,
                                        idx
                                      )
                                    }
                                  ></i>
                                </td>
                                <td className="text-center">
                                  <span>
                                    {item?.limit}{" "}
                                    <i
                                      className="fe fe-edit-3 pointer"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleEditLimit(
                                          item?.id,
                                          item?.limit,
                                          idx
                                        )
                                      }
                                    ></i>
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span className="item-title">
                                    {/* {moment(item?.expireAt).format(
                                      "DD-MM-YYYY[, ]hh:mm A"
                                    )}{" "}
                                    <i
                                      className="fe fe-edit-3 pointer"
                                      style={{ cursor: "pointer" }}
                                    ></i> */}
                                    <DateRangePicker
                                      initialSettings={{
                                        timePicker: true,
                                        singleDatePicker: true,
                                        startDate: moment(
                                          item?.expireAt
                                        ).format("MM-DD-YYYY hh:mm A"),
                                        // endDate: moment(dateFilters?.end).format("MM-DD-YYYY"),
                                        linkedCalendars: true,
                                        showCustomRangeLabel: true,
                                        showDropdowns: true,
                                        alwaysShowCalendars: true,
                                      }}
                                      onApply={handleDateApplied}
                                    >
                                      <input
                                        className={`btn ${dateFilters
                                            ? "btn-primary"
                                            : "btn-white"
                                          } ml-2 pointer`}
                                        value={moment
                                          .unix(item?.expireAt)
                                          .toISOString()}
                                        onChange={() => {
                                          return null;
                                        }}
                                        onClick={() => {
                                          setDateChange({
                                            id: item.id,
                                            index: idx,
                                          });
                                        }}
                                      />
                                    </DateRangePicker>
                                  </span>
                                </td>

                                <td className="text-center">
                                  <div
                                    text={referalCode}
                                    onClick={() => {
                                      if (copied) {
                                        toast.success("Link Copied!");
                                      } else {
                                        handleCopyReferal(
                                          item.referralCodeUserId
                                        );
                                      }
                                    }}
                                  >
                                    <span
                                      className="item-title"
                                      style={{
                                        cursor: "pointer",
                                        color: "#666",
                                      }}
                                    >
                                      <i className="fe fe-copy" /> Copy Link
                                    </span>
                                  </div>
                                </td>
                                <td className="text-center">
                                <span>
                                  {
                                    (item?.spentByReferals / 100)?.toLocaleString("en-US", {style:"currency", currency:"USD"})
                                  }
                                  </span>
                                </td>
                                <td className="text-center">
                                <span>
                                  {
                                    [...new Set(item?.referralCodeUsage?.items.map(obj => obj.user.username))].length
                                  }
                                  </span>
                                </td>
                                <td className="text-center">
                                <span>
                                <button
                        type="submit"
                        style={{ background: "#50c7c3", border: "none" }}
                        className="btn btn-primary mx-3"
                        onClick={() => navigate(`/referral-purchase-history/${item.id}`)}
                      >
                        <span>View History</span>
                      </button>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {referral?.length === 0 && loading !== true && (
                      <p className="text-center mt-3">No Data Found</p>
                    )}
                    <Pagination
                      handleNext={handleNext}
                      handleFirst={handleFirst}
                      length={tokens.length}
                      token={token}
                      total={referral?.length}
                    />
                  </div>
                </div>
              </div>
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={addReferal}
              >
                <Modal.Header>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ fontSize: "30px", color: "#545454" }}
                    >
                      Add Referal
                      {/* <CloseButton onClick={() => setShowAddCredits(false)} /> */}
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AsyncSelect
                    isClearable
                    placeholder="Search by user"
                    key={adduser}
                    theme={selectColor}
                    cacheOptions
                    defaultOptions
                    loadOptions={promiseUsers}
                    defaultValue={adduser}
                    onChange={(e) => {
                      setTokens([]);
                      setToken(null);
                      setAdduser(e);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  {modreferal?.length > 0 && adduser?.label?.length > 0 ? (
                    <div className="text-muted">
                      This user already have referal code
                    </div>
                  ) : null}
                  <div className="col-auto">
                    {modreferal?.length == 0 && adduser?.label?.length > 0 ? (
                      <button
                        type="submit"
                        style={{ background: "#50c7c3", border: "none" }}
                        className="btn btn-primary mx-3"
                        onClick={handleCreateReferal}
                      >
                        <span>Add Referal</span>
                      </button>
                    ) : null}
                    <button
                      className="btn btn-light"
                      onClick={() => {
                        setAddreferal(false);
                        setAdduser("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralCodes;
