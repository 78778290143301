import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DropUpload from "../components/DropUpload";
import DropVideo from "../components/DropVideo";
import { createLesson, updateLesson } from "../graphql/mutations";
import { fileUpload } from "../utils/utils";
import { toast } from "react-toastify";


const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
      }
      position
      title
      description
      takeaways
      thumbnail
      cover
      audio
      video
      content
      slides
      attachments
      users {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

const AddLessons = () => {
  const { courseId } = useParams();

  const location = useLocation();
  const url = location.pathname;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cover, setCover] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [audio, setAudio] = useState(null);
  const [video, setVideo] = useState(null);


  const [lessonsCourseId, setLessonCourseId] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function handleDrop(e, file, setState) {
    e.preventDefault();
    setState(await fileUpload(file));
  }

  async function mediaUpload(e, file, setState) {
    if(!file.type.includes("audio") && !file.type.includes("video")){
      toast.warning("Invalid file type !")
      return;
    }
    const audio = new Audio();
    let url = await fileUpload(file);
    audio.addEventListener("loadedmetadata", () => {
      setState({
        type: file?.type,
        url,
        duration: audio.duration * 1000,
      });
    });
    audio.src = URL.createObjectURL(file);
  }

  const addLesson = async () => {
    setSubmitting(true);
    try {
      await API.graphql({
        query: createLesson,
        variables: {
          input: {
            courseId: courseId,
            audio: JSON.stringify(audio),
            cover: cover,
            description,
            thumbnail,
            title,
            video: JSON.stringify(video),
          },
        },
      });
      navigate(`/courses/${courseId}`);
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      Swal.fire("Oops!", error.message, "error");
    }
  };

  const editLesson = async () => {
    setSubmitting(true);
    try {
      await API.graphql({
        query: updateLesson,
        variables: {
          input: {
            id: courseId,
            audio: JSON.stringify(audio),
            cover: cover,
            description,
            thumbnail,
            title,
            video: JSON.stringify(video),
          },
        },
      });
      navigate(`/courses/${lessonsCourseId}`);
    } catch (error) {
      setSubmitting(false);
      Swal.fire("Oops!", error.message, "error");
    }
  };

  async function getLessonData() {
    setLoading(true);
    try {
      let result = await API.graphql({
        query: getLesson,
        variables: {
          id: courseId,
        },
      });
      let data = result?.data?.getLesson;

      setTitle(data?.title);
      setDescription(data?.description);
      setCover(data?.cover);
      setThumbnail(data?.thumbnail);
      setAudio(JSON.parse(data?.audio));
      setVideo(JSON.parse(data?.video));
      setLessonCourseId(data.courseId);
      setLoading(false);
    } catch (error) {
      Swal.fire("Oops!", error.message, "error");
      console.log(error);
    }
  }

  useEffect(() => {
    url.includes("edit") && getLessonData();
  }, []);

  useEffect(() => {
    audio?.url && setVideo(null);
  }, [audio]);
  useEffect(() => {
    video?.url && setAudio(null);
  }, [video]);

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="container-xl container-fluid">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">Lesson</h6>
            <h1 className="header-title">
              {" "}
              {url.includes("edit") ? "Edit" : "Add a new"} lesson
            </h1>
          </div>
        </div>
      </div>
      <hr />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <form
            className="tab-content py-1"
            id="wizardSteps"
            onSubmit={(e) => {
              e.preventDefault();
              url.includes("edit") ? editLesson() : addLesson();
            }}
          >
            <div>
              <div className="form-group">
                <label  className="form-label mx-1">
                  Title <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Lesson Title"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label  className="form-label mx-1">
                  Description
                </label>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                />
              </div>

              <DropUpload
                name="Thumbnail"
                state={thumbnail}
                setState={setThumbnail}
                handleDrop={handleDrop}
                required={true}
              />
              <DropUpload
                name="Cover"
                state={cover}
                setState={setCover}
                handleDrop={handleDrop}
                required={true}
              />
              <DropVideo
                name="Audio"
                state={audio}
                setState={setAudio}
                handleDrop={mediaUpload}
                type="audio"
                accept="audio/*"
              />
              <DropVideo
                name="Video"
                state={video}
                setState={setVideo}
                handleDrop={mediaUpload}
                type="video"
                accept="video/mp4"
              />

              <hr className="my-5" />

              <div className="nav row align-items-center">
                <div className="col-auto mb-3">
                  <button
                    type="submit"
                    disabled={
                      submitting ||
                      !thumbnail ||
                      !cover ||
                      (!audio && !video)
                    }
                    style={{ background: "#50c7c3", border: "none" }}
                    className="btn btn-primary"
                  >
                    {submitting ? (
                      <div
                        className="spinner-border text-white"
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        role="status "
                      ></div>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  {!submitting && (
                    <button
                      onClick={() => navigate(`/courses/${courseId}`)}
                      type="button"
                      className="btn btn-light mx-2"
                      disabled={submitting}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddLessons;


