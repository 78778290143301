/* eslint-disable no-dupe-keys */
import React, { useState, useEffect, useRef } from "react";
import { formattedTime, limit } from "../utils/utils";
import { API } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/Pagination";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import moment from "moment";

const getReferralCode = /* GraphQL */ `
query GetReferralCode($id: ID!) {
  getReferralCode(id: $id) {
    id
    user {
      id
      username
      name
    }
    spentByReferals
}
}
`;
const searchReferralCodeUsages = /* GraphQL */ `
query SearchReferralCodeUsages(
  $filter: SearchableReferralCodeUsageFilterInput
  $sort: [SearchableReferralCodeUsageSortInput!]
  $limit: Int
  $nextToken: String
) {
  searchReferralCodeUsages(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
    nextToken
    items {
      id
      referralCodeId
      spent
      user {
        username
        id
      }
      createdAt
    }
  }
}
`;

export default function ReferralPurchaseHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [referralCodeData, setReferralCodeData] = useState([]);
  const [referralUser, setReferralUser] = useState([]);
  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [tokens, setTokens] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const queryUsageData = async () => {
    try {
      setIsLoading(true);
      const result = await API.graphql({
        query: searchReferralCodeUsages,
        variables: { 
          filter: {
            referralCodeId: { eq: id },
            spent: {ne: 0}
          },
          nextToken,
          sort: [{field: "createdAt", direction: "asc" }],
          limit: limit
         },
      });
      setTokens((prev) => [
        ...prev,
        result?.data?.searchReferralCodeUsages.nextToken
      ]);
      setToken(result?.data?.searchReferralCodeUsages.nextToken);
      setReferralCodeData(result?.data?.searchReferralCodeUsages.items
      );
      setIsLoading(false);
    }
    catch(e) {
      setIsLoading(false);
    }
  };
  const queryReferralUserData = async () => {
      const result = await API.graphql({
        query: getReferralCode,
        variables: { 
          id: id
         },
      });
      setReferralUser(result?.data?.getReferralCode);
      setIsLoading(false);
    
  };
  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }
  useEffect(() => {
    queryUsageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, nextToken]);
  useEffect(() => {
    queryReferralUserData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);
  const handleDownloadReport = async () => {
    try {
      setDownloading(true);
      let token = null;
      let data = [];
      let total = 0
      do {
        let res = await API.graphql({
          query: searchReferralCodeUsages,
          variables: {
            filter: {
              referralCodeId: { eq: id },
              spent: {ne: 0}
            },
            nextToken,
            sort: [{field: "createdAt", direction: "asc" }],
            nextToken: token,
          },
        });

        token = res.data.searchReferralCodeUsages.nextToken;
        data = data.concat(res.data.searchReferralCodeUsages.items);
        // total  = res.data.searchReferralCodeUsages.spentByReferals;
      } while (token);

      if (data.length === 0) return toast.error("No Data Found.");

      let xlData = data.map((item) => {
        return {
          ["Date"]: moment(item?.createdAt).format(
            "MM-DD-YYYY, h:mm:ss a"
          ),
          User: item?.user?.username || item?.user?.name,
          ["Purchase Amount ($)"]: (item?.spent / 100)?.toLocaleString(
            "en-US",
            { style: "currency", currency: "USD" }
          )
        };
      });
      const totalSpendRow = {
        ["Date"]: "Total spend",
        ["Purchase Amount ($)"]: (referralUser?.spentByReferals / 100)?.toLocaleString(
          "en-US",
          { style: "currency", currency: "USD" }
        ),
      };
      xlData.push(totalSpendRow);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(
        wb,
        `Referral spend Report for ${referralUser?.user?.username}.xlsx`
      );
    } catch (error) {
      toast.error("Failed to download report !");
    } finally {
      setDownloading(false);
    }
  };
  
  return (
    <div className="container-fluid my-5">
      <div className="d-flex justify-content-between align-items-center">
        <div className="col">
          <h6 className="header-pretitle">Overview</h6>
          <h1 className="header-title text-truncate">
            {referralUser?.user?.username || "Customer"}
          </h1>
          <h3 className="header-title my-2">
            {`Spend amount ${
              referralUser?.spentByReferals
                ? (referralUser?.spentByReferals / 100)?.toLocaleString(
                    "en-US",
                    { style: "currency", currency: "USD" }
                  )
                : `$0.00`
            }`}
          </h3>
        </div>
        <div>
            <button
              disabled={!referralCodeData.length || isLoading || downloading}
              type="submit"
              style={{ background: "#50c7c3", border: "none" }}
              className="btn btn-primary "
              onClick={handleDownloadReport}
            >
              <span>Download report</span>
            </button>
          </div>
      </div>
      <div className="recommended-experts-container-content">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div className="table-responsive">
                    {isLoading ? (
                      <div className="d-flex justify-content-center m-4">
                        <img
                          src="/images/loader.svg"
                          alt="loader"
                          height={"100px"}
                        />
                      </div>
                    ) : (
                      <table className="table table-sm table-hover table-nowrap card-table">
                        <thead>
                          <tr>
                            <th>
                              <span className="text-muted">User Name</span>
                            </th>
                            <th>
                              <span className="text-muted">Date</span>
                            </th>
                            <th className="text-center">
                              <span className="text-muted">Amount</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list fs-base">
                          {referralCodeData?.map(
                            (item, index) => (
                              <tr key={index.toString()}>
                                <td>
                                  <span
                                    className="item-title"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigate(`/user/${item?.user?.id}`)
                                    }
                                  >
                                    {item?.user?.username}
                                  </span>
                                </td>
                                <td>
                                  <span>{formattedTime(item.createdAt)}</span>
                                </td>

                                <td className="text-center">
                                  <span
                                    className={`item-score badge bg-success-soft`}
                                  >
                                    <b className="fs-4">
                                      {(item?.spent / 100)?.toLocaleString(
                                        "en-US",
                                        { style: "currency", currency: "USD" }
                                      )}
                                    </b>
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                      {referralCodeData.length === 0 && !isLoading && (
                        <p className="text-center m-3">No Data </p>
                      )}
                        <Pagination
                        handleNext={handleNext}
                        handleFirst={handleFirst}
                        length={tokens?.length}
                        total={referralCodeData.length}
                        limitItems={limit}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
