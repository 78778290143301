import { Outlet, useNavigate } from "react-router-dom";
import TopMenu from "./TopMenu";
import SideBar from "./SideBar";
import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../store/userInfo";
import { toast } from "react-toastify";


const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name
      email
      phone
      role
      expert
      verified
      active
      media
      tagline
      profile
    }
  }
`;

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allow, setAllow] = useState(false);

  useEffect(() => {
    handleAuth();
  });

  async function handleAuth() {
    try {
      let cogntioRes = await Auth.currentAuthenticatedUser();
      let currentUser = await API.graphql({
        query: getUser,
        variables: { id: cogntioRes.attributes.sub },
      });
      if(!currentUser.data.getUser?.active){
        Auth.signOut();
        navigate("/");
        toast.warning("Unauthorized !")
        return;
      }
      let roleArray = currentUser.data.getUser?.role;
      if (
        !roleArray?.includes("ADMIN") &&
        !roleArray?.includes("VIEWER") &&
        !roleArray?.includes("EDITOR")
      ) {
        Auth.signOut();
        toast.warning("Unauthorized !")
        navigate("/");
        return;
      }
      dispatch(setUserInfo(currentUser.data.getUser));
      setAllow(true);
    } catch (error) {
      console.log(error)
      navigate("/");
    }
  }

  return (
    <>
      {allow && (
        <>
          <SideBar />
          <TopMenu />
          <div className="main-content">
            <Outlet />
          </div>
        </>
      )}
    </>
  );
};

export default Layout;


