import { API } from "aws-amplify";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DropUpload from "../components/DropUpload";
import { fileUpload } from "../utils/utils";

const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      id
    }
  }
`;


const Profile = () => {
  const navigate = useNavigate();
  const data = useSelector((state) => state);
  const userInfo = data.userInfo.payload;

  const [name, setName] = useState(userInfo?.name || "");
  const [username,setUsername] = useState(userInfo?.username || "")
  const [cover, setCover] = useState(userInfo?.media || "");

  const [submitting, setSubmitting] = useState(false);

  const editData = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: userInfo.id,
            name,
            username,
            media: cover,
          },
        },
      });
      setSubmitting(false);
      Swal.fire("Voila !", "Profile updated successfully.", "success");
      navigate(-1);
    } catch (e) {
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
      setSubmitting(false);
    }
  };

  async function handleDrop(e, file, setState) {
    try {
      e.preventDefault();
      setState(await fileUpload(file));
    } catch (error) {
      Swal.fire("Oops!", "Try again", "error");
      console.log(error);
    }
  }

  return (
    <div className="container-xl container-fluid">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">Profile</h6>
            <h1 className="header-title">User Profile</h1>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div>
          <form
            className="tab-content py-2"
            id="wizardSteps"
            onSubmit={editData}
          >
            <div>
              <label  className="form-label mx-1">
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label  className="form-label mx-1 mt-2">
                Username <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="my-3">
                <DropUpload
                  name="Avatar"
                  state={cover}
                  setState={setCover}
                  handleDrop={handleDrop}
                  required={true}
                />
              </div>

              <div className="nav row align-items-center">
                <div className="col-auto">
                  <button
                    type="submit"
                    className="btn btn-primary upload-btn"
                    disabled={submitting || !cover}
                  >
                    {submitting ? (
                      <div
                        className="spinner-border text-white"
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        role="status "
                      ></div>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  {!submitting && (
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="btn btn-light mx-2"
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
