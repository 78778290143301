import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DropUpload from "../components/DropUpload";
import { fileUpload } from "../utils/utils";
import Swal from "sweetalert2";

const updatePostQuery = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
  ) {
    updatePost(input: $input) {
      id
    }
  }
`;
const EditPost = () => {
  const  { postId }  = useParams();
  const {pathname} = useLocation()
  const navigate = useNavigate();
  
  const getPostQuery = `query GetPost{
    getPost(id: "${postId}") {
        description
        attachments
        publishedAt
        thumbnail
        title
      }
  }`;

  const [postDetails,setPostDetails] = useState({})
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [attachments,setAttachments] = useState([])
  const [thumbnail,setThumbnail] = useState(null)

  async function handleDrop(e, file, setState) {
    try {
      e.preventDefault();
      setState(await fileUpload(file));
    } catch (error) {
      Swal.fire("Oops!", "Try again", "error");
      console.log(error);
    }
  }

  async function getData(){
    try {
      let res = await API.graphql({query : getPostQuery})
      setPostDetails(res.data.getPost)
      res.data.getPost?.attachments && setAttachments(JSON.parse(res.data.getPost?.attachments))
      setThumbnail(res.data.getPost?.thumbnail)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }  

  async function updatePostFn(){
    setSubmitting(true)
    try {
      await API.graphql({query : updatePostQuery,variables:{
        input :{
          id : postId,
          title : postDetails?.title,
          description:postDetails?.description,
          attachments:attachments?.length > 0  ? JSON.stringify(attachments) : null,
          thumbnail : thumbnail || null
        }
      }})
      toast.success(`${pathname.includes("article") ? "Article" : "Post"} updated successfully.`)
      navigate(-1)
      setSubmitting(false)
    } catch (error) {
      toast.error('Failed to edit post !')
      console.log(error)
    }
  }

  useEffect(()=>{
    getData()
  },[])

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="container-xl container-fluid">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">{pathname.includes("article") ? "Article" : "Post"}</h6>
            <h1 className="header-title">
              Edit {pathname.includes("article") ? "Article" : "Post"}
            </h1>
          </div>
        </div>
      </div>
      <hr />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <form
            className="tab-content py-1"
            id="wizardSteps"
            onSubmit={(e) => {
              e.preventDefault();
              updatePostFn()
            }}
          >
            <div>
              <div className="form-group">
                <label  className="form-label mx-1">
                  Title <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter title"
                  value={postDetails?.title}
                  onChange={(e)=>setPostDetails({...postDetails,title:e.target.value})}
                />
              </div>

              <div className="form-group">
                <label  className="form-label mx-1">
                  Description <span className="text-danger">*</span>
                </label>
                <textarea 
                  required
                  className="form-control"
                  placeholder="Enter description"
                  value={postDetails?.description}
                  rows="4" cols="50"
                  onChange={(e)=>setPostDetails({...postDetails,description:e.target.value})}
                />
              </div>
              <div className="my-4">
                {attachments && attachments?.length > 0 && (
                  <label className="form-label mx-1">
                  Attachments <span className="text-danger">*</span>
                </label>
                  )}
                <div>
                  {attachments && attachments?.map(item=>(
                    <div key={item?.url}>
                    {item?.type === 'Image' && (
                      <>
                      <img src={item?.url} height={100} width={100}/>
                      <i className="fe fe-x fs-3 position-absolute top-10" style={{cursor:"pointer"}} onClick={()=>{
                          let temp = structuredClone(attachments)
                          temp = temp?.filter(media=>media.url !== item?.url)
                          setAttachments(temp)
                        }}></i>
                      </>
                    )}
                    {item?.type === 'Video' && (
                      <div className="position-relative">
                        <video width={100} height={100} controls>
                          <source src={item?.url} type="video/mp4"/>
                        </video>
                        <i className="fe fe-x fs-3 position-absolute" style={{cursor:"pointer"}} onClick={()=>{
                          let temp = structuredClone(attachments)
                          temp = temp?.filter(media=>media.url !== item?.url)
                          setAttachments(temp)
                        }}></i>
                     </div>)}
                    </div>
                  ))}
                </div>
              </div>
              {postDetails?.thumbnail && (
                <div>
                  <DropUpload
                    name="Thumbnail"
                    state={thumbnail}
                    setState={setThumbnail}
                    handleDrop={handleDrop}
                    required={true}
                  />
                <div>
                </div>
              </div>
              )}
              <div className="nav row align-items-center">
                <div className="col-auto mb-3">
                  <button
                    type="submit"
                    style={{ background: "#50c7c3", border: "none" }}
                    className="btn btn-primary"
                  >
                    {submitting ? (
                      <div
                        className="spinner-border text-white"
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        role="status"
                      ></div>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  {!submitting && (
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="btn btn-light mx-2"
                      disabled={submitting}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPost;
