import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Pagination from "../components/Pagination";
import { formattedTime, limit, selectColor, timeFormatter } from "../utils/utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { promiseUsers } from "./Subscriptions";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: [SearchablePostSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        author {
          id
          name
          username
          media
        }
        category {
          id
          name
        }
        reports {
          items {
            id
          }
        }
        comments {
          items {
            id
          }
        }
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        likes{
          items{
            id
          }
        }
        commentCount
        reportCount
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
  ) {
    deletePost(input: $input) {
      id
    }
  }
`;

const Articles = () => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [sort,setSort] = useState({field: "createdAt",direction : 'desc'})
  const [search,setSearch] = useState("")

  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [dateFilters, setDateFilters] = useState(null);
  const [user,setUser] = useState(null)
  const navigate = useNavigate();
  const [flagged,setFlagged] = useState(false)
  const [total,setTotal] = useState(0)

  const handleDateApplied = (event, picker) => {
    setTokens([]);
    const fromDate = Math.floor(moment(picker.startDate).startOf("day").valueOf());
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setDateFilters({ start, end });
  };

  async function getData() {
    setLoading(true);
    try {
      let filter = {};
      let sortable = {field:sort?.field ,direction:sort?.direction}
      if(dateFilters) filter = { publishedAt: { lte: dateFilters.end, gte: dateFilters.start }}
      if(flagged){filter = {status  : {eq : "FLAGGED"}}}
      if(user){filter = {authorId:{eq : user?.value}}}

      if(flagged && user){filter = {status  : {eq : "FLAGGED"},authorId:{eq : user?.value}}}

      if(search.length !== 0){filter = { title : { matchPhrasePrefix: search,}}}
      if(search.length !== 0 && user){filter = { title : { matchPhrasePrefix: search,},authorId:{eq : user?.value}}}
      if(search.length !== 0 && user && flagged){filter = { title : { matchPhrasePrefix: search,},authorId:{eq : user?.value},status  : {eq : "FLAGGED"}}}
      
      let res = await API.graphql({
        query: searchPosts,
        variables: {
          limit: limit,
          nextToken: nextToken,
          filter: {...filter,postCategoryId: { eq: process.env.REACT_APP_POST_CATEGORY_ID }},
          sort: sortable,
        },
      });
      setLoading(false);
      setTotal(res.data.searchPosts.total)
      setToken(res.data.searchPosts.nextToken);
      setPosts(res.data.searchPosts.items);
      setTokens((prev) => [...prev,res.data.searchPosts.nextToken]);
    } catch (error) {
      console.log(error);
    }
  }

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }

  async function handleDeleteArticle(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete Article",
    })
      .then((result) => {
        if (result.isConfirmed) {
          API.graphql({
            query: deletePost,
            variables: {
              input: {
                id: id,
              },
            },
          })
            .then(() => {
              let temp = structuredClone(posts)
              temp = temp.filter(item=>item.id !== id)
              setPosts(temp)
              toast.success("Article deleted successfully.")
            })
            .catch((e) => toast.error("Failed to remove article !"));
        }
      })
      .catch((e) => console.log(e));
  }


  useEffect(() => {
    setLoading(true)
   let timer = setTimeout(() => {
    getData()
   }, 600);
   return ()=>clearTimeout(timer)
  }, [nextToken,dateFilters,user,flagged,search,sort?.key,sort?.direction])
  

  return (
    <>
      <div className="container-fluid my-5">
        {/* header starts */}
        <div className="row align-items-center">
          <div className="col">
            <h6 className="header-pretitle">Overview</h6>
            <h1 className="header-title text-truncate">Articles ({total || 0}) </h1>
          </div>
          <div className="col-auto">
          <button
            className="btn btn-primary upload-btn"
            onClick={() => navigate("/articles/add")}
          >
            Add Article
          </button>
          </div>
        </div>
        {/* header ends */}
        <div className="recommended-experts-container-content">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="contactsListPane"
                  role="tabpanel"
                  aria-labelledby="contactsListTab">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            className="form-control list-search"
                            type="search"
                            placeholder="Search by title"
                            onChange={(e) => {
                              setTokens([]);
                              setToken(null)
                              setSearch(e.target.value);
                            }}
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                        </div>
                        <div className="col-auto" style={{minWidth:200}}>
                          <AsyncSelect 
                          isClearable
                          placeholder="Search by User"
                          key={user}
                          theme={selectColor} 
                          cacheOptions defaultOptions loadOptions={promiseUsers} 
                          defaultValue={user}
                          onChange={(e)=>{
                            setTokens([])
                            setToken(null)
                            setUser(e)}}/>
                        </div>
                        <div className="col-auto">
                          <DateRangePicker
                            key={dateFilters?.start}
                            initialSettings={{
                              startDate:  moment(dateFilters?.start).format("MM-DD-YYYY"),
                              endDate: moment(dateFilters?.end).format("MM-DD-YYYY"),
                              linkedCalendars: true,
                              showCustomRangeLabel: true,
                              showDropdowns: true,
                              alwaysShowCalendars: true,
                              opens: "right",
                            }}
                            onApply={handleDateApplied}
                          >
                            <input
                              className={`btn ${
                                dateFilters ? "upload-btn" : "btn-white"
                              } ml-2`}
                              style={{ cursor: "pointer" }}
                            />
                          </DateRangePicker>
                        </div>
                        <div className="col-auto">
                          <button className={`btn ${flagged ? "upload-btn" : "btn-white"}`} onClick={()=>{
                            setDateFilters(null);
                            setLoading(true)
                            setFlagged((prev) => !prev);
                            setToken(null)
                            setTokens([])
                          }}><i className="fe fe-flag text-danger" /> Flagged</button>
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-light"
                            onClick={() => {
                              setDateFilters(null);
                              setTokens([]);
                              setToken(null);
                              setNextToken(null);
                              setUser(null)
                              setFlagged(false)
                              setSort({field: "createdAt",direction : 'desc'})
                            }}>
                            Reset 
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <div className="d-flex justify-content-center m-4">
                          <img
                            src="/images/loader.svg"
                            alt="loader"
                            height={"100px"}
                          />
                        </div>
                      ) : (
                        <table className="table table-sm table-hover table-nowrap card-table">
                          <thead>
                            <tr>
                              <th>
                                <span className="text-muted">User Name</span>
                              </th>
                              <th>
                                <span className="text-muted">Title</span>
                              </th>
                              <th>
                                <span className="text-muted">
                                  Flagged
                                </span>
                              </th>
                              <th className="text-center" style={{cursor:"pointer"}} data-sort="item-name"  onClick={()=>{
                                setSort({field:"likeCount",direction : sort.direction === 'asc' ? 'desc' : 'asc'})
                                 setTokens([]); setToken(null)
                              }}>
                                <span className="text-muted">Likes</span>
                              </th>
                              <th style={{cursor:"pointer"}} data-sort="item-name" onClick={()=>{
                                 setSort({field:"publishedAt",direction : sort.direction === 'asc' ? 'desc' : 'asc'})
                                 setTokens([]); setToken(null)
                              }}>
                                <span className="text-muted" >Posted On</span>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody className="list fs-base">
                            {posts.map((post) => (
                              <tr key={post?.id} style={{ cursor: "pointer" }} onClick={()=>navigate(`/post/${post?.id}`)}>
                                <td>
                                   <div>
                                    <div className="avatar avatar-xs align-middle me-2" >
                                      <img
                                        className="avatar-img rounded-circle"
                                        src={post?.author?.media|| "/images/user.png"}
                                        alt="user"
                                        onClick={(e)=>{
                                          e.stopPropagation();
                                          navigate(`/user/${post?.author?.id}`)
                                        }}
                                      />
                                    </div>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="item-name text-reset"
                                      href="profile-posts.html"
                                      onClick={(e)=>{
                                        e.stopPropagation();
                                        navigate(`/user/${post?.author?.id}`)
                                      }}
                                    >
                                      {post?.author?.username || post?.author?.name || "-"}
                                    </span>
                                    </div>
                                </td>
                                <td>{post?.title?.slice(0,40)} {post?.title?.length > 40 && "..."}</td>
                                <td>
                                {post?.status === "FLAGGED" ? (
                                    <span className="item-score badge  bg-danger">
                                    Flagged
                                    </span>
                                    ): (
                                      <span className="item-score badge bg-success-soft">
                                      Not Flagged
                                      </span>
                                    )}
                                </td>
                                <td className="text-center w-25">
                                  {post?.likes?.items?.length}
                                </td>
                                <td>
                                  <span>{formattedTime(post?.createdAt)} - {timeFormatter(post?.createdAt)} ago</span>
                                </td>
                                <td onClick={(e)=>e.stopPropagation()}>
                                <div className="dropdown">
                                  <span
                                    className="dropdown-ellipses dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical"></i>
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <span className="dropdown-item" onClick={()=>navigate(`/edit-article/${post?.id}`)}>Edit</span>
                                    <span className="dropdown-item" onClick={()=>handleDeleteArticle(post?.id)}>Delete</span>
                                  </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                      {posts.length === 0 && !loading && (
                        <p className="text-center m-3">No Articles Found</p>
                      )}
                        <Pagination
                          handleNext={handleNext}
                          handleFirst={handleFirst}
                          length={tokens?.length}
                          token={token}
                          total={posts?.length}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;


