import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteExpertType } from "../graphql/mutations";
import { handleDelete } from "../utils/utils";
import Swal from "sweetalert2";

const ExpertTypes = () => {
  const user = useSelector((state) => state.userInfo.payload);
  let allowViewer = user.role.includes("VIEWER");

  const [expertsData, setExpertsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function getData() {
    setLoading(true);
    API.graphql({
      query: listExpertTypes,
    }).then((res) => {
      setExpertsData(res.data.listExpertTypes?.items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="expertspage-innerContainer">
        <div className="recommended-experts-container-outer">
          {/* header starts */}
          <div className="row align-items-center">
            <div className="col">
              <h6 className="header-pretitle">Overview</h6>

              <h1 className="header-title text-truncate">Expert Types</h1>
            </div>
            <div className="col-auto">
              <button
                onClick={() => navigate("/add-expert-type")}
                className="btn btn-primary ms-2"
                style={{
                  backgroundColor: "#50C7C3",
                  border: "none",
                  display: allowViewer && "none",
                }}
              >
                Add Expert Type
              </button>
            </div>
          </div>
          {/* header ends */}
          <div className="recommended-experts-container-content">
            {/* starts here */}
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="contactsListPane"
                    role="tabpanel"
                    aria-labelledby="contactsListTab"
                  >
                    <div className="card">
                      {loading ? (
                        <img
                          src="/images/loader.svg"
                          alt="loader"
                          height={"100px"}
                        />
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-sm table-hover table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>
                                  <span className="text-muted" href="#">
                                    Name
                                  </span>
                                </th>
                                <th>
                                  <span className="text-muted" href="#">
                                    Call Rate
                                  </span>
                                </th>
                                <th>
                                  <span className="text-muted" href="#"></span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list fs-base">
                              {expertsData.map((data) => (
                                <tr key={data?.id}>
                                  <td>
                                    <div className="avatar avatar-xs align-middle me-2">
                                      <img
                                        className="avatar-img rounded-circle"
                                        src={data?.media || "/images/user.png"}
                                        alt="avatar"
                                      />
                                    </div>{" "}
                                    <span className="item-name text-reset">
                                      {data?.name}
                                    </span>
                                  </td>

                                  <td>
                                    <span className="item-email text-reset">
                                      {new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(data?.callRate)}
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <div className="dropdown">
                                      <span
                                        className="dropdown-ellipses dropdown-toggle"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical"></i>
                                      </span>
                                      <div className="dropdown-menu dropdown-menu-end">
                                        <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            navigate(
                                              `/edit-expert-type/${data.id}`
                                            );
                                          }}
                                        >
                                          Edit
                                        </span>
                                        <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (data?.experts?.items?.length !== 0) {
                                              Swal.fire(
                                                "Oops !",
                                                "Kindly remove the experts associated with this type first.",
                                                "info"
                                              );
                                              return;
                                            }
                                            handleDelete(
                                              data?.id,
                                              deleteExpertType,
                                              "Expert type",
                                              getData
                                            );
                                          }}
                                        >
                                          Delete
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {expertsData.length === 0 && !loading && (
                        <p className="text-center m-3">No Expert found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Ends here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertTypes;

export const listExpertTypes = /* GraphQL */ `
  query ListExpertTypes(
    $filter: ModelExpertTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpertTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        media
        experts{
          items{
            id
          }
        }
        callRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
