import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DropUpload from "../components/DropUpload";
import { createExpertType, updateExpertType } from "../graphql/mutations";

const AddExpertType = () => {
  const navigate = useNavigate();

  const { typeId } = useParams();

  const [name, setName] = useState("");
  const [cover, setCover] = useState(null);
  const [description, setDescription] = useState("");
  const [callRate, setCallRate] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  const addData = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await API.graphql({
        query: createExpertType,
        variables: {
          input: {
            name : name?.trim(),
            media: cover,
            description : description?.trim(),
            callRate,
          },
        },
      });
      setSubmitting(false);
      navigate("/expert-types");
    } catch (e) {
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
      setSubmitting(false);
    }
  };
  const editData = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await API.graphql({
        query: updateExpertType,
        variables: {
          input: {
            id: typeId,
            name : name?.trim(),
            media: cover,
            description : description?.trim(),
            callRate,
          },
        },
      });
      setSubmitting(false);
      navigate("/expert-types");
    } catch (e) {
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
      setSubmitting(false);
    }
  };

  async function handleDrop(e, file, setState) {
    try {
      e.preventDefault();
      console.log(file)
      // setState(await fileUpload(file));
    } catch (error) {
      Swal.fire("Oops!", "Try again", "error");
      console.log(error);
    }
  }

  async function getData() {
    try {
      let res = await API.graphql({
        query: getExpertType,
        variables: {
          id: typeId,
        },
      });
      let data = res.data.getExpertType;
      setName(data?.name);
      setCover(data?.media);
      setDescription(data?.description);
      setCallRate(data?.callRate);
      setLoading(false);
    } catch (e) {
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
      setLoading(false);
    }
  }

  useEffect(() => {
    typeId && getData();
    !typeId && setLoading(false);
  }, []);

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="container-xl container-fluid">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">Expert Type</h6>
            <h1 className="header-title">
              {!typeId ? "Add Expert Type" : "Edit Expert Type"}
            </h1>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div>
          <form
            className="tab-content py-2"
            id="wizardSteps"
            onSubmit={typeId ? editData : addData}
          >
            <div>
              <label id="tag" className="form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input
                id="tag"
                type="text"
                className="form-control"
                required
                placeholder="Enter expert type name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="my-3">
                <DropUpload
                  name="Image"
                  state={cover}
                  setState={setCover}
                  handleDrop={handleDrop}
                />
              </div>

              <div>
                <label id="tag" className="form-label">
                  Description <span className="text-danger">*</span>
                </label>
                <input
                  id="tag"
                  type="text"
                  className="form-control"
                  required
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="my-3">
                <label id="tag" className="form-label">
                  Call Rate <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <div className="">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    type="number"
                    step={0.01}
                    className="form-control"
                    value={callRate}
                    placeholder="Enter call rate"
                    onChange={(e) => setCallRate(e.target.value)}
                    aria-label="Amount (to the nearest dollar)"
                  />
                </div>
              </div>

              <div className="nav row align-items-center">
                <div className="col-auto  mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary upload-btn"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <div
                        className="spinner-border text-white"
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        role="status "
                      ></div>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  {!submitting && (
                    <button
                      onClick={() => navigate("/expert-types")}
                      type="button"
                      className="btn btn-light mx-2"
                      disabled={submitting}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddExpertType;

export const getExpertType = /* GraphQL */ `
  query GetExpertType($id: ID!) {
    getExpertType(id: $id) {
      id
      name
      description
      media
      callRate
      createdAt
      updatedAt
    }
  }
`;
