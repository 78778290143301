import React from "react";
import { limit } from "../utils/utils";

export default function Pagination({
  handleNext,
  handleFirst,
  length,
  token,
  total,
  limitItems
}) {
  return (
    <div className="card-footer d-flex justify-content-between">
      {length > 1 && (
        <div
          className="p-0 d-flex align-items-center"
          onClick={handleFirst}
          style={{ cursor: "pointer" }}
        >
          <i
            className="fe fe-arrow-left ms-1"
            style={{ fontSize: "1.2rem" }}
          ></i>
          {/* First */}
        </div>
      )}
      <ul className="list-pagination pagination pagination-tabs card-pagination">
        <li className="active"></li>
      </ul>
      {(limitItems || limit) <= total && (
        <div
          style={{ cursor: "pointer" }}
          className="p-0 d-flex align-items-center"
          onClick={handleNext}
        >
          <i
            className="fe fe-arrow-right ms-1 "
            style={{ fontSize: "1.2rem" }}
          ></i>
        </div>
      )}
    </div>
  );
}
