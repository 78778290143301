import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const [showCode, setShowCode] = useState(false);

  const gotoLogin = () => {
    navigate("/");
  };

  const handleForgetPassword = () => {
    setLoading(true);
    Auth.forgotPassword(email)
      .then(() => {
        setShowCode(true);
        setLoading(false);
      })
      .catch((e) => Swal.fire("Oops !", e.message, "error"));
  };

  const handleVerify = () => {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        navigate("/");
      })
      .catch((e) => Swal.fire("Oops !", e.message, "error"));
  };

  // const ForgotPassword = async (email) => {
  //   if (!email.includes("@")) {
  //     setError("Invalid Email");
  //     return;
  //   }

  //   setShowCode(false);
  //   setError(null);
  //   await Auth.forgotPassword(email)
  //     .then(() => {
  //       setShowCode(true);
  //       setTimer(30);
  //     })
  //     .catch((e) => setError(e.message));
  // };

  // const handleFormSubmit = async ({ code, password }) => {
  //   setOtpError(null);
  //   code = code.toString();
  //   await Auth.forgotPasswordSubmit(email, code, password)
  //     .then(() => {
  //       navigate("/login");
  //     })
  //     .catch((e) => setOtpError(e.message));
  //   console.log(email, code, password);
  // };

  return (
    <div
      className="d-flex align-items-center  border-top border-top-2 border-primary"
      style={{ display: "block", height: "100vh" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
            <div className="text-center">
              <img
                src="https://dashkit.goodthemes.co/assets/img/illustrations/coworking.svg"
                alt="..."
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
            <h1
              className="display-4 text-center mb-3"
              style={{ color: "#50C7C3" }}
            >
              Password reset
            </h1>

            <p className="text-muted text-center mb-5">
              Enter your email to get a password reset link.
            </p>

            {!showCode && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleForgetPassword();
                }}
              >
                <div className="form-group">
                  <label id="email" className="form-label">
                    Email Address
                  </label>

                  <input
                    autoComplete="false"
                    id="email"
                    type="email"
                    className="form-control"
                    placeholder="name@address.com"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-lg w-100 btn-primary mb-3"
                  style={{ backgroundColor: "#50C7C3", border: "none" }}
                >
                  {loading ? (
                    <div
                      className="spinner-border text-white"
                      style={{ height: "25px", width: "25px" }}
                      role="status "
                    ></div>
                  ) : (
                    <span>Reset Password</span>
                  )}
                </button>

                <div className="text-center">
                  <small className="text-muted text-center">
                    Remember your password?{" "}
                    <span
                      style={{ cursor: "pointer", color: "#50C7C3" }}
                      onClick={() => gotoLogin()}
                    >
                      Log in
                    </span>
                    .
                  </small>
                </div>
              </form>
            )}
            {showCode && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleVerify();
                }}
              >
                <div className="form-group d-flex flex-column gap-2">
                  <div className="d-flex flex-column gap-0">
                    <label className="form-label">Code</label>
                    <input
                      autoComplete="false"
                      type="number"
                      className="form-control"
                      placeholder="Enter 6-digit verification code"
                      required
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-column gap-0 justify-content-center">
                    <label id="password" className="d-flex align-items-center gap-2 my-2">
                      Password :
                      <div
                        data-toggle="tooltip"
                        data-placement="top"
                        title={`                    
                        Password Must be atleast 8 Characters
                        1.One Upper Case 
                        2.One Lower Case
                        3.One Number
                        4.One Special Character
                      `}>
                        <i className="fe fe-info"></i>
                      </div>
                    </label>

                    <div className="input-group input-group-merge">
                      <input
                       autoComplete="false"
                        id="password"
                        className="form-control"
                        type={passwordType}
                        placeholder="Enter your password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <span className="input-group-text">
                        {passwordType === "password" ? (
                          <i
                            className="fe fe-eye-off"
                            onClick={() => togglePassword()}
                          ></i>
                        ) : (
                          <i
                            className="fe fe-eye"
                            onClick={() => togglePassword()}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-0">
                    <label className="form-label">Confirm Password</label>

                    <div className="input-group input-group-merge">
                      <input
                        autoComplete="false"
                        id="password"
                        className="form-control"
                        type={passwordType}
                        placeholder="Enter your password"
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />

                      <span className="input-group-text">
                        {passwordType === "password" ? (
                          <i
                            className="fe fe-eye-off"
                            onClick={() => togglePassword()}
                          ></i>
                        ) : (
                          <i
                            className="fe fe-eye"
                            onClick={() => togglePassword()}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>

                  {password !== confirmPassword && (
                    <p className="text-danger">Password must match</p>
                  )}

                  <button
                    type="submit"
                    disabled={password !== confirmPassword}
                    className="btn btn-lg w-100 btn-primary  mt-3"
                    style={{ backgroundColor: "#50C7C3", border: "none" }}
                  >
                    {loading ? (
                      <div
                        className="spinner-border text-white"
                        style={{ height: "25px", width: "25px" }}
                        role="status "
                      ></div>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
