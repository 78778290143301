import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getZoomJWT } from "../utils/utils";
import VideoCall from "../components/VideoCall";
import ZoomChat from "../components/ZoomChat";
import ZoomVideo from "@zoom/videosdk";

const StreamRoom = () => {
  const location = useLocation();
  const {
    title,
    urlToVideo,
    sendPush,
    notificationTitle,
    notificationMessage,
    multistreamingEnabled,
  } = location.state;

  const [zoomJWT, setZoomJWT] = useState();

  const client = useRef(ZoomVideo.createClient());

  useEffect(() => {
    getZoomJWT(title).then(setZoomJWT);
  }, [title]);

  return (
    <div className="container-fluid pt-5 pb-5">
      <div className="row g-3">
        <VideoCall
          session={title}
          jwt={zoomJWT}
          url={urlToVideo}
          sendPush={sendPush}
          notificationTitle={notificationTitle}
          notificationMessage={notificationMessage}
          multistreamingEnabled={multistreamingEnabled}
          client={client}
        />
        <ZoomChat client={client} />
      </div>
    </div>
  );
};

export default StreamRoom;
