import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createPostCategory, updatePostCategory } from "../graphql/mutations";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const AddPostTopic = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const addTopic = async () => {
    setSubmitting(true);

    try {
      await API.graphql({
        query: createPostCategory,
        variables: {
          input: {
            name,
          },
        },
      });
      setSubmitting(false);
      toast.success("Topic added successfully.")
      navigate("/post-topics");
    } catch (error) {
      setSubmitting(false);
      Swal.fire("Oops !", error.message, "error");
    }
  };

  const editTopic = async () => {
    setSubmitting(true);
    try {
      await API.graphql({
        query: updatePostCategory,
        variables: {
          input: {
            id: id,
            name,
          },
        },
      });
      setSubmitting(false);
      toast.success("Topic updated successfully.")
      navigate("/post-topics");
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      Swal.fire("Oops !", error.message, "error");
    }
  };

  async function getData() {
    try {
      let res = await API.graphql({
        query: getPostCategory,
        variables: {
          id: id,
        },
      });
      setName(res.data.getPostCategory.name);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    !id && setLoading(false);
    setTimeout(() => {
      id && getData();
    }, 1200);
  }, []);

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="container-xl container-fluid">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">Topics</h6>
            <h1 className="header-title">{id ? "Edit" : "Add"} a post topic</h1>
          </div>
        </div>
      </div>
      <hr />
      <div className="row justify-content-center">
        <form
          className="tab-content"
          id="wizardSteps"
          onSubmit={(e) => {
            e.preventDefault();
            id ? editTopic() : addTopic();
          }}
        >
          <div className="form-group">
            <label  className="form-label mx-1">
              Name:
            </label>

            <input
              
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter topic name"
              required
            />
          </div>

          <div className="nav row align-items-center">
            <div className="col-auto">
              <button
                type="submit"
                style={{ background: "#50c7c3", border: "none" }}
                className="btn btn-primary"
                disabled={submitting}
              >
                {submitting ? (
                  <div
                    className="spinner-border text-white"
                    style={{
                      height: "25px",
                      width: "25px",
                    }}
                    role="status "
                  ></div>
                ) : (
                  <span>Save</span>
                )}
              </button>
              {!submitting && (
                <button
                  onClick={() => navigate("/post-topics")}
                  type="button"
                  className="btn btn-light mx-2"
                  disabled={submitting}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPostTopic;

export const getPostCategory = /* GraphQL */ `
  query GetPostCategory($id: ID!) {
    getPostCategory(id: $id) {
      id
      name
      thumbnail
      cover
      createdAt
      updatedAt
    }
  }
`;
