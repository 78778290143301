import React from "react";

export default function TermsOfUse() {
  return (
    <div>
      <div className="text-center">
        <img src="/images/Yelling.png" alt="yelling" height={"200px"} />
        <h1>Terms of Use</h1>
        <h3 className="text-muted">Updated on August 10, 2023</h3>
      </div>
      <div className="card p-5 container">
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Clarity Terms of Use
          </strong>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            Welcome to Clarity!&nbsp;We provide an online{" "}
          </span>
          <span style={{ color: "rgb(34, 34, 34)", background: "transparent" }}>
            coaching and advice platform that provides useful courses and
            community interaction that helps singles better navigate the dating
            world.
          </span>
          <span style={{ background: "transparent" }}>
            &nbsp;These Terms of Use govern your use of Clarity, a website and
            mobile application owned by SmartDate, Inc. d/b/a Clarity
            (“Clarity,” “Company,” “We,” “Our”), which also includes all related
            widgets, tools, data, software, and other services provided by us
            (the “Services”). We may come out with new features from time to
            time in the future, which we will communicate to you through our
            website and mobile application.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            This document, together with our Privacy Policy and any other terms
            specifically referred to therein, constitute a legally binding
            agreement (the “Agreement”) between you and the Company in relation
            to your use of our Services. If you do not agree with these Terms,
            do not use Clarity.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transparent", color: "orange" }}>
            <u>Hotline Services</u>
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent", color: "orange" }}>
            <u>
              Part of our Services is a 24/7 hotline.&nbsp;However, we are
              trained to deal with common dating and relationship situations and
              not trained to advise on domestic violence situations. If you’re
              in danger at the time of the call, please hang up and dial 911.
              You may also call the domestic abuse hotline at: +1800-799-7233.
              If you are in fear of hurting yourself, please dial 911 or call
              the 988 suicide and crisis lifeline in the United States.
            </u>
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Acceptance of Terms of Use
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            Please read these Terms of Use, and our Privacy Policy, very
            carefully.&nbsp;When prompted, please click or press “Accept” if you
            agree to be legally bound by all the terms and conditions herein.
            Your acceptance of these Terms of Use creates a legally binding
            contract between you and the Company. If you do not agree with any
            aspect of these Terms of Use, then do not click or press “Accept,”
            in which case you may not use the Services. By accepting the Terms
            of Use and creating an account, you represent and warrant that the
            information you include on the Services is accurate and that you
            have the capacity to enter into and abide by these terms and
            conditions.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Changes to Terms of Use
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            We reserve the right to change, alter, replace or otherwise modify
            (collectively “Changes”) these Terms of Use at any time. The date of
            last modification is stated in the footer of these Terms of
            Use.&nbsp;
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            When we make any updates to these Terms of Use, we will highlight
            this fact on the website or online platform. In addition, if you
            register an account and these Terms of Use are subsequently changed
            in any material respect (for example, for security, legal, or
            regulatory reasons), we will notify you in advance by sending an
            email to the email address that you have provided to us. You will
            have no obligation to continue using the Services following any such
            notification, but if you do not terminate your account as described
            in the Termination section below, your continued use of the Services
            will constitute your acceptance of the revised Terms of Use.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Your Clarity Account
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            When you first create a Clarity account, we ask that you register by
            including your name, basic information about yourself, email address
            and creating a password.&nbsp;Access to the Services are only
            available to registered users who have expressly agreed to these
            Terms of Use and our Privacy Policy.&nbsp;You are solely responsible
            for maintaining the confidentiality and security of your login and
            account information, and you will remain responsible for all
            activity emanating from your account, whether or not such activity
            was authorized by you.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>&nbsp;</strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            We reserve the right to disallow, cancel, remove, or reassign
            certain usernames and permalinks in appropriate circumstances, as
            determined by us in our sole discretion, and may, with or without
            prior notice, suspend, terminate, and delete your account if
            activities occur on that account which, in our sole discretion,
            would or might constitute a violation of these Terms of Use or an
            infringement or violation of the rights of any third party, or of
            any applicable laws or regulations.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>&nbsp;</strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            You may terminate your account at any time through our Services, or
            you can contact us at{" "}
          </strong>
          <span style={{ color: "rgb(0, 0, 255)", background: "transparent" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transparent" }}>. </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Your Use of the Services
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Subject to your strict compliance with these Terms of Use, Clarity
            grants you a limited, personal, non-exclusive, revocable,
            non-assignable, and non-transferable right and license to use the
            Services in order to generate text, graphics, software, photographs,
            videos, data, and other materials (collectively “Content”), view
            Content, share and download Content using the features of the
            Services where the appropriate functionality has been enabled.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>&nbsp;</strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            The above licenses are conditional upon your strict compliance with
            these Terms of Use including, without limitation, the following:
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>&nbsp;</strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            (i) You must not copy, rip or capture, or attempt to copy, rip or
            capture, any Content from the Services or any part of the Services,
            other than by means of download or sharing in circumstances where we
            have elected to permit downloads and sharing of the relevant
            Content.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            (ii) You must not employ scraping or similar techniques to
            aggregate, repurpose, republish or otherwise make use of any
            Content.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            &nbsp;(iii) You must not alter or remove, attempt to alter or remove
            any trademark, copyright or other proprietary or legal notices
            contained in, or appearing on, the Services or any Content appearing
            on the Services (other than your Content).
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            (iv) You must not, and must not permit any third party to, copy or
            adapt the object code of the Website or any of the Services, or
            reverse engineer, reverse assemble, decompile, modify or attempt to
            discover any source or object code of any part of the Services, or
            circumvent or attempt to circumvent or copy any copy protection
            mechanism or access any rights management information pertaining to
            Content other than your Content.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            (v) You must respect the wishes of other users. If you are blocked
            by another user, you agree to not attempt to contact them on Clarity
            either from the account you were blocked from or any other account.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            (vi) You must not use the Services to upload, post, store, transmit,
            display, copy, distribute, promote, make available or otherwise
            communicate to the public:
          </strong>
        </p>
        <ul>
          <li>
            <span style={{ background: "transparent" }}>
              any Content that is offensive, abusive, libelous, defamatory,
              obscene, racist, ethnically or culturally offensive, indecent,
              that promotes violence, terrorism, or illegal acts, incites hatred
              on grounds of race, gender, religion or sexual orientation, or is
              otherwise objectionable in the Company’s reasonable discretion;
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              any information, Content, or other material that violates,
              plagiarizes, misappropriates, or infringes the rights of third
              parties including, without limitation, copyright, trademark
              rights, rights of privacy or publicity, confidential information
              or any other right; or
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              any Content that violates, breaches, or is contrary to any law,
              rule, regulation, court order, or is otherwise is illegal or
              unlawful in the Company’s reasonable opinion;
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              any material of any kind that contains any virus, Trojan horse,
              spyware, adware, malware, bot, time bomb, worm, or other harmful
              or malicious component, which actually or potentially could
              overburden, impair or disrupt the Services or servers or networks
              forming part of, or connected to, the Services, or which actually
              or potentially could restrict or inhibit any other user’s use and
              enjoyment of the Services; or
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              any unsolicited or unauthorized advertising, promotional messages,
              spam or any other form of solicitation.
            </span>
          </li>
        </ul>
        <p>
          <span style={{ background: "transparent" }}>
            (vii) You must not commit or engage in, or encourage, induce,
            solicit or promote, any conduct that would constitute a criminal
            offence, give rise to civil liability or otherwise violate any law
            or regulation.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            (viii) You must not deliberately impersonate any person or entity or
            otherwise misrepresents your affiliation with a person or entity,
            for example, by registering an account in the name of another person
            or company or sending messages or making comments using the name of
            another person.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            You agree to comply with the above conditions and acknowledge and
            agree that Clarity has the right, in its sole discretion, to
            terminate your account or take such other action as we see fit if
            you breach any of the above conditions or any of the other terms of
            these Terms of Use. This may include taking court action and/or
            reporting offending users to the relevant authorities.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            User License Grant to Clarity
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Subject to the obligation to maintain the confidentiality of
            Personal Information about a user as identified in the Privacy
            Policy, You, as a user of the Services grant Clarity a
            non-exclusive, fully paid-up, royalty-free, perpetual, and
            irrevocable license, throughout the universe, to use, reproduce,
            distribute, modify, adapt, prepare derivative works of, publicly
            display, publicly perform, communicate to the public, and otherwise
            utilize and exploit Personal Information and information about Your
            use of the Service to:
          </strong>
        </p>
        <ul>
          <li>
            <span style={{ background: "transparent" }}>
              improve the Service or the functionality of Clarity;
            </span>
          </li>
        </ul>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <ul>
          <li>
            <span style={{ background: "transparent" }}>
              to respond to user inquiries or technological issues or problems;{" "}
            </span>
          </li>
          <li>
            <br />
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              to comply with applicable laws; for example, to respond to
              regulatory authorities responsible for oversight of government
              benefit programs or our operations; to parties or courts in the
              course of judicial or administrative proceedings; to law
              enforcement officials during an investigation{" "}
            </span>
          </li>
          <li>
            <br />
          </li>
        </ul>
        <p>
          <strong
            style={{ color: "rgb(37, 37, 37)", background: "transparent" }}
          >
            Intellectual Property Rights
          </strong>
        </p>
        <p>
          <span style={{ color: "rgb(37, 37, 37)", background: "transparent" }}>
            The Services and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by the Company, its licensors, or
            other providers of such material and are protected by United States
            and international copyright, trademark, patent, trade secret, and
            other intellectual property or proprietary rights laws.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(37, 37, 37)", background: "transparent" }}>
            All related names, logos, product and service names, designs, and
            slogans are trademarks of the Company or its affiliates or
            licensors. You must not&nbsp;use&nbsp;such marks without the prior
            written permission of the Company. All other names, logos, product
            and service names, designs, and slogans on this Website are the
            trademarks of their respective owners.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Copyright Infringement and the Digital Millennium Copyright Act
            (DMCA) Safe Harbor
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            We take the intellectual property rights of others seriously and
            require that users of Clarity do the same. The Digital Millennium
            Copyright Act (DMCA) established a process for addressing claims of
            copyright infringement that we have implemented for our services. If
            you own a copyright or have authority to act on behalf of a
            copyright owner and want to report a claim that a third party is
            infringing that material on or through Clarity, please send a notice
            to our copyright agent that includes all of the items below and we
            will expeditiously take appropriate action:
          </strong>
        </p>
        <ol>
          <li>
            <span style={{ background: "transparent" }}>
              A description of the copyrighted work that you claim is being
              infringed;
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              A description of the material you claim is infringing and that you
              want removed or access to which you want disabled and the URL or
              other location of that material;
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              Your address, telephone number, and email address;
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              The following statement: “I have a good faith belief that the use
              of the copyrighted material I am complaining of is not authorized
              by the copyright owner, its agent, or the law (e.g., as a fair
              use)”;
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              The following statement: “The information in this notice is
              accurate and, under penalty of perjury, I am the owner, or
              authorized to act on behalf of the owner, of the copyright or of
              an exclusive right that is allegedly infringed”; and
            </span>
          </li>
          <li>
            <span style={{ background: "transparent" }}>
              An electronic or physical signature of the owner of the copyright
              or a person authorized to act on the owner’s behalf.
            </span>
          </li>
        </ol>
        <p>
          <span style={{ background: "transparent" }}>
            Our designated copyright agent to receive such claims can be reached
            at{" "}
          </span>
          <span style={{ color: "rgb(0, 0, 255)", background: "transparent" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transparent" }}>.</span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            We may, in appropriate circumstances, disable or terminate the
            accounts of users who may be repeat infringers.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            This process does not limit our ability to pursue any other remedies
            we may have to address suspected infringement.&nbsp;
          </span>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Third Party Websites and Services
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            The Services may provide you with access to and/or integration with
            third party websites, databases, networks, servers, information,
            software, programs, systems, directories, applications, products or
            services (hereinafter “External Services”).
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            The Company does not have or maintain any control over External
            Services and is not and cannot be responsible for their content,
            operation or use. By linking or otherwise providing access to any
            External Services, the Company does not give any representation,
            warranty, or endorsement, express or implied, with respect to the
            legality, accuracy, quality or authenticity of content, information
            or services provided by such External Services.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            External Services may have their own terms of use and/or privacy
            policy, and may have different practices and requirements to those
            operated by the Company with respect to the Services. You are solely
            responsible for reviewing any terms of use, privacy policy, or other
            terms governing your use of these External Services, which you use
            at your own risk. You are advised to make reasonable inquiries and
            investigations before entering into any transaction, financial or
            otherwise, and whether online or offline, with any third party
            related to any External Services.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            You are solely responsible for taking the precautions necessary to
            protect yourself from fraud when using External Services, and to
            protect your computer systems from viruses, worms, Trojan horses,
            and other harmful or destructive content and material that may be
            included on or may emanate from any External Services.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            The Company disclaims any and all responsibility or liability for
            any harm resulting from your use of External Services, and you
            hereby irrevocably waive any claim against the Company with respect
            to the content or operation of any External Services.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>&nbsp;</strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Informational Purposes Only
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            The content of our Services, such as text, graphics, images,
            information obtained from our third parties, partners, sponsors,
            advertisers, licensors, and from our dating hotline are for
            informational purposes only. Clarity makes no warranty whatsoever
            that any of this information is accurate.&nbsp;The content is not
            intended to be a guarantee of success or positive results – it is
            simply for informational purposes only and the results of your
            actions are the responsibility of you and you alone.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Clarity does not recommend or endorse any specific articles, tests,
            products, procedures, opinions, or other information that may be
            mentioned on Clarity’s Services. Reliance on any information
            provided by Clarity, Clarity employees, or others appearing in our
            Services is solely at your own risk.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            General Disclaimer
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            THE SERVICES, INCLUDING, WITHOUT LIMITATION, THE WEBSITE, ONLINE
            PLATFORM AND ALL CONTENT AND SERVICES ACCESSED THROUGH OR VIA THE
            WEBSITE, ONLINE PLATFORM OR OTHERWISE, ARE PROVIDED “AS IS,” “AS
            AVAILABLE,” AND “WITH ALL FAULTS.”&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            YOU AGREE AND ACKNOWLEDGE THAT YOU ASSUME FULL, EXCLUSIVE, AND SOLE
            RESPONSIBILITY FOR THE USE OF AND RELIANCE ON THE SERVICES, AND YOU
            FURTHER AGREE AND ACKNOWLEDGE THAT YOUR USE OF OR RELIANCE ON THE
            SERVICES IS MADE ENTIRELY AT YOUR OWN RISK. YOU FURTHER ACKNOWLEDGE
            THAT IT IS YOUR RESPONSIBILITY TO COMPLY WITH ALL APPLICABLE LAWS
            WHILE USING THE SERVICE.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            WHILE THE COMPANY USES REASONABLE ENDEAVOURS TO CORRECT ANY ERRORS
            OR OMISSIONS IN THE SERVICES AS SOON AS PRACTICABLE ONCE THEY HAVE
            BEEN BROUGHT TO THE COMPANY’S ATTENTION, THE COMPANY MAKES NO
            PROMISES, GUARANTEES, REPRESENTATIONS, OR WARRANTIES OF ANY KIND
            WHATSOEVER (EXPRESS OR IMPLIED) REGARDING THE SERVICES, OR ANY PART
            OR PARTS THEREOF, ANY CONTENT, OR ANY LINKED SERVICES OR OTHER
            EXTERNAL SERVICES. THE COMPANY DOES NOT WARRANT THAT YOUR USE OF THE
            SERVICES WILL BE ACCURATE OR RELIABLE, UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE
            SERVICES OR ANY PART OR PARTS THEREOF, THE CONTENT, OR THE SERVERS
            ON WHICH THE SERVICES OPERATES ARE OR WILL BE FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS. THE COMPANY DOES NOT WARRANT THAT ANY
            TRANSMISSION OF CONTENT UPLOADED TO THE SERVICES WILL BE SECURE OR
            THAT ANY ELEMENTS OF THE SERVICES DESIGNED TO PREVENT UNAUTHORISED
            ACCESS, SHARING OR DOWNLOAD OF CONTENT WILL BE EFFECTIVE IN ANY AND
            ALL CASES, AND DOES NOT WARRANT THAT YOUR USE OF THE SERVICES IS
            LAWFUL IN ANY PARTICULAR JURISDICTION.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND
            ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS
            AND SHAREHOLDERS, SPECIFICALLY DISCLAIM ALL OF THE FOREGOING
            WARRANTIES AND ANY OTHER WARRANTIES NOT EXPRESSLY SET OUT HEREIN TO
            THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING WITHOUT LIMITATION
            ANY EXPRESS OR IMPLIED WARRANTIES REGARDING NON-INFRINGEMENT,
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            WHERE THE LAW OF ANY JURISDICTION LIMITS OR PROHIBITS THE DISCLAIMER
            OF IMPLIED OR OTHER WARRANTIES AS SET OUT ABOVE, THE ABOVE
            DISCLAIMERS SHALL NOT APPLY TO THE EXTENT THAT THE LAW OF SUCH
            JURISDICTION APPLIES TO THIS AGREEMENT.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Limitation of Liability
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            THE COMPANY’S AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND
            ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS
            AND SHAREHOLDERS, LIABILITY TO YOU IS LIMITED. TO THE MAXIMUM EXTENT
            PERMITTED BY LAW, IN NO EVENT SHALL THE COMPANY BE LIABLE FOR
            DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, DIRECT,
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE
            DAMAGES, LOST PROFITS, LOST DATA, LOSS OF GOODWILL OR BUSINESS
            REPUTATION, ANY COST TO PROCURE SUBSTITUTE GOODS OR SERVICES, OR ANY
            INTANGIBLE LOSS, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES)
            ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE, OR ANY
            OTHER SERVICES PROVIDED TO YOU BY THE COMPANY.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            This limitation shall apply regardless of whether the damages arise
            out of breach of contract, tort, any form of error, or breakdown in
            the function of the service, or any other legal theory or form of
            action.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            ALTHOUGH NOT AN EXHAUSTIVE LIST AND WITHOUT LIMITING THE FOREGOING,
            THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND
            ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS
            AND SHAREHOLDERS, SHALL HAVE NO LIABILITY FOR:
          </strong>
        </p>
        <ol>
          <li>
            <span style={{ background: "transparent" }}>
              ANY LOSS OR DAMAGE ARISING FROM:
            </span>
          </li>
        </ol>
        <p>
          <span style={{ background: "transparent" }}>
            (A) YOUR RELIANCE ON THE CONTENT OF THE SERVICES, INCLUDING WITHOUT
            LIMITATION, CONTENT ORIGINATING FROM THIRD PARTIES, OR FROM ANY
            COMMUNICATION WITH THE SERVICES;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            (B) YOUR INABILITY TO ACCESS OR USE THE SERVICES OR ANY PART OR
            PARTS THEREOF, INCLUDING DELETION OR CORRUPTION OF, OR FAILURE TO
            STORE, ANY CONTENT AND OTHER DATA MAINTAINED OR TRANSMITTED BY OR
            THROUGH YOUR USE OF THE SERVICE, OR YOUR ABILITY TO ACCESS ANY
            CONTENT OR ANY EXTERNAL SERVICES VIA THE SERVICES;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            (C) ANY CHANGES THAT THE COMPANY MAY MAKE TO THE SERVICES OR ANY
            PART THEREOF, OR ANY TEMPORARY OR PERMANENT SUSPENSION OR CESSATION
            OF ACCESS TO THE SERVICES OR ANY CONTENT IN OR FROM ANY OR ALL
            TERRITORIES;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            (D) ANY ACTION TAKEN AGAINST YOU BY THIRD PARTY RIGHTS HOLDERS WITH
            RESPECT TO ANY ALLEGED INFRINGEMENT OF SUCH THIRD PARTY’S RIGHTS
            RELATING TO YOUR CONTENT OR YOUR USE OF THE SERVICES, OR ANY ACTION
            TAKEN AS PART OF AN INVESTIGATION BY THE COMPANY OR ANY RELEVANT LAW
            ENFORCEMENT AUTHORITY REGARDING YOUR USE OF THE SERVICES;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            (E) ANY ERRORS OR OMISSIONS IN THE SERVICES’S TECHNICAL OPERATION,
            OR FROM ANY INACCURACY OR DEFECT IN ANY CONTENT OR ANY INFORMATION
            RELATING TO CONTENT;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            (F) YOUR FAILURE TO PROVIDE THE COMPANY WITH ACCURATE OR COMPLETE
            INFORMATION, OR YOUR FAILURE TO KEEP YOUR ACCOUNT LOGIN INFORMATION
            SUITABLY CONFIDENTIAL;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            (G) ANY LOSS OR DAMAGE TO ANY COMPUTER HARDWARE OR SOFTWARE, ANY
            LOSS OF DATA, OR ANY LOSS OR DAMAGE FROM ANY SECURITY BREACH;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            (H) ANY LOSS OF PROFITS, INCLUDING THOSE CAUSED BY YOUR RELIANCE ON
            THE SERVICES, OR ANY LOSS YOU SUFFER WHETHER OR NOT IT IS
            FORESEEABLE.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO YOUR USE
            OF THE SERVICES MUST BE NOTIFIED TO THE COMPANY AS SOON AS POSSIBLE.
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
            LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, YOU
            ACKNOWLEDGE AND AGREE THAT SUCH LIMITATIONS AND EXCLUSIONS REFLECT A
            REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND THE COMPANY
            AND ARE FUNDAMENTAL ELEMENTS OF THE BARGAIN BETWEEN YOU AND THE
            COMPANY, AND THAT THE COMPANY’S LIABILITY WILL BE LIMITED ENTIRELY,
            TO THE MAXIMUM EXTENT PERMITTED BY LAW.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>Indemnification</strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            You hereby agree to indemnify, defend and hold harmless the Company,
            its successors, assigns, affiliates, agents, directors, officers,
            employees, and shareholders from and against any and all claims,
            obligations, damages, losses, expenses, and costs, including
            reasonable attorneys’ fees, resulting from:
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            (i) any violation by you of these Terms of Use or&nbsp;
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            (ii) any activity related to your account, be it by you or by any
            other person accessing your account with or without your consent.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Data Protection, Privacy, and Cookies
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            All personal data that you provide to us in connection with your use
            of the Services is collected, stored, used, and disclosed by the
            Company in accordance with our&nbsp;Privacy Policy, which is in
            compliance with European Union and California privacy law. In
            addition, in common with most websites, we use cookies, location
            data, and other user information to help us understand how people
            are using the Services, so that we can continue to improve the
            service we offer. The Privacy Policy, as may be updated by the
            Company from time to time in accordance with its terms, is hereby
            incorporated into these Terms of Use, and you hereby agree to the
            collection, use and disclose practices set forth therein.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Applicable Law and Jurisdiction
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            If a dispute arises between you and Clarity, our goal is to provide
            you with a neutral and cost-effective means of resolving the issue
            quickly. In the event of a dispute, we encourage you to contact us
            first to resolve your problem directly with us. You may contact us
            regarding any complaints or disputes in the “Contact us” section
            below in these Terms.
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            These Terms of Use shall be construed in accordance with and
            governed by the laws of the United States and the State of
            California, without reference to their rules regarding conflicts of
            law.&nbsp;
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO THIS
            AGREEMENT OR THE BREACH, TERMINATION, ENFORCEMENT, INTERPRETATION OR
            VALIDITY THEREOF, INCLUDING THE DETERMINATION OF THE SCOPE OR
            APPLICABILITY OF THIS AGREEMENT TO ARBITRATE, SHALL BE DETERMINED BY
            ARBITRATION IN SAN FRANCISCO, CALIFORNIA BEFORE ARBITRATOR(S). THE
            ARBITRATION SHALL BE ADMINISTERED BY JAMS PURSUANT TO ITS
            COMPREHENSIVE ARBITRATION RULES AND PROCEDURES AND IN ACCORDANCE
            WITH THE EXPEDITED PROCEDURES IN THOSE RULES OR PURSUANT TO JAMS’
            STREAMLINED ARBITRATION RULES AND PROCEDURES. JUDGMENT ON THE AWARD
            MAY BE ENTERED IN ANY COURT HAVING JURISDICTION. THIS CLAUSE SHALL
            NOT PRECLUDE PARTIES FROM SEEKING PROVISIONAL REMEDIES IN AID OF
            ARBITRATION FROM A COURT OF APPROPRIATE JURISDICTION.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Availability of Services
          </strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Clarity may discontinue or change our Services at any time with or
            without prior notice and without liability to you. We make no
            guarantee that our Services will be available at all times or
            without interruption.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>Termination</strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            You agree that Clarity may terminate your account at any time for
            your violation of any of the provisions of these Terms. If you are
            dissatisfied with the Clarity Services, please let us know by
            emailing us at{" "}
          </strong>
          <span style={{ color: "rgb(0, 0, 255)", background: "transparent" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transparent" }}>
            . Your only other remedy with respect to any dissatisfaction with
            (a) the Services, (b) any portion of these Terms, (c) any policy or
            practice of Clarity in providing the Services, or (d) any content or
            information transmitted through the Services, is to terminate the
            Services and your account. You may terminate the Services at any
            time by discontinuing your use of any and all parts of our Services
            and providing Clarity notice of termination by emailing us at{" "}
          </span>
          <span style={{ color: "rgb(0, 0, 255)", background: "transparent" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transparent" }}>.&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            Complete Agreement
          </strong>
        </p>
        <p>
          <span style={{ background: "transparent" }}>
            These Terms constitute the entire agreement between you and Clarity
            with respect to the use of the Clarity Site and Content. Your use of
            the Clarity Site is also subject to the Clarity Privacy Policy. If
            any provision of these Terms is found to be invalid by any court
            having competent jurisdiction, the invalidity of such provision
            shall not affect the validity of the remaining provisions, which
            shall remain in full force and effect. No waiver of any of these
            Terms shall be deemed a further or continuing waiver of such term or
            condition or any other term or condition.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong style={{ background: "transparent" }}>Contact Us</strong>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            For questions or comments about the Terms, please email us at{" "}
          </strong>
          <span style={{ color: "rgb(0, 0, 255)", background: "transparent" }}>
            info@clarityapp.io
          </span>
          <span style={{ background: "transparent" }}>.&nbsp;</span>
        </p>
        <p>
          <span style={{ background: "transparent" }}>&nbsp;</span>
        </p>
        <p>
          <strong style={{ background: "transparent" }}>
            <em>Last Update: August 10, 2023</em>
          </strong>
        </p>
      </div>
    </div>
  );
}
