import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { formattedTime, limit, selectColor } from "../utils/utils";
import Pagination from "../components/Pagination";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AsyncSelect from "react-select/async";

const subscriptionQuery = /* GraphQL */ `
  query SearchUserStripeSubscriptions(
    $filter: SearchableUserStripeSubscriptionFilterInput
    $sort: [SearchableUserStripeSubscriptionSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchUserStripeSubscriptions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        startAt
        expireAt
        status
        stripeSubscriptionId
        user {
          username
          name
        }
        subscription {
          price
          name
        }
      }
      nextToken
      total
    }
  }
`;

const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        name
        expert
      }
      nextToken
      total
    }
  }
`;

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(null);
  const [tokens, setTokens] = useState([]);

  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [dateFilters, setDateFilters] = useState(null);
  const [dateFiltersStart, setDateFiltersStart] = useState(null);
  const [user, setUser] = useState(null);
  const [display, setDisplay] = useState(0);

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }

  async function getData() {
    setLoading(true);
    try {
      let filter;
      if (user) {
        filter = { userId: { eq: user?.value } };
      }
      if (display === 1) {
        filter = { status: { eq: "ACTIVE" } };
      }
      if (display === 1 && user) {
        filter = { userId: { eq: user?.value }, status: { eq: "ACTIVE" } };
      }
      if (display === 2) {
        filter = { status: { eq: "CANCELLED" } };
      }
      if (display === 2 && user) {
        filter = { userId: { eq: user?.value }, status: { eq: "CANCELLED" } };
      }
      if (dateFilters) {
        filter = {
          expireAt: {
            lte: dateFilters?.end,
            gte: dateFilters?.start,
          },
        };
      }
      if (dateFiltersStart) {
        filter = {
          startAt: {
            lte: dateFiltersStart?.end,
            gte: dateFiltersStart?.start,
          },
        };
      }
      let res = await API.graphql({
        query: subscriptionQuery,
        variables: {
          limit,
          nextToken: nextToken,
          filter: filter,
          sort: { direction: "desc", field: "createdAt" },
        },
      });
      setTotal(res.data?.searchUserStripeSubscriptions.total);
      setLoading(false);
      // if (res.data.searchUserStripeSubscriptions.items.length !== 0 || dateFilters !== null || user !== null) {
      setSubscriptions(res.data.searchUserStripeSubscriptions.items);
      setToken(res.data?.searchUserStripeSubscriptions?.nextToken);
      setTokens((prev) => [
        ...prev,
        res.data?.searchUserStripeSubscriptions?.nextToken,
      ]);
      // }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDateApplied = (event, picker) => {
    setTokens([]);
    setDateFiltersStart(null);
    const fromDate = Math.floor(
      moment(picker.startDate).startOf("day").valueOf()
    );
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setDateFilters({ start, end });
  };

  const handleDateAppliedStart = (event, picker) => {
    setTokens([]);
    setDateFilters(null);
    const fromDate = Math.floor(
      moment(picker.startDate).startOf("day").valueOf()
    );
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setDateFiltersStart({ start, end });
  };

  useEffect(() => {
    getData();
  }, [nextToken, dateFilters, user, display, dateFiltersStart]);

  useEffect(() => {
    setTokens([]);
    setNextToken(null);
    setToken(null);
  }, [display]);

  return (
    <>
      <div className="container-fluid" style={{ marginTop: "30px" }}>
        {/* header starts */}
        <div className="row align-items-center">
          <div className="col">
            <h6 className="header-pretitle">Overview</h6>

            <h1 className="header-title text-truncate">
              Subscriptions ({total || 0})
            </h1>
          </div>
        </div>
        {/* header ends */}
        <div className="border-bottom border-1 my-1">
          <div className="row align-items-center">
            <div className="col">
              <ul className="nav nav-tabs nav-overflow header-tabs">
                <li className="nav-item">
                  <div
                    className={`nav-link ${display === 0 ? "active" : ""}`}
                    onClick={() => setDisplay(0)}
                    style={{ cursor: "pointer" }}
                  >
                    <b>All</b>
                  </div>
                </li>
                <li className="nav-item">
                  <div
                    className={`nav-link ${display === 1 ? "active" : ""}`}
                    onClick={() => setDisplay(1)}
                    style={{ cursor: "pointer" }}
                  >
                    <b>Active</b>
                  </div>
                </li>
                <li className="nav-item ">
                  <div
                    className={`nav-link ${
                      display === 2 ? "active" : ""
                    } d-flex`}
                    onClick={() => setDisplay(2)}
                    style={{ cursor: "pointer" }}
                  >
                    <b>Cancelled</b>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="recommended-experts-container-content">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="contactsListPane"
                  role="tabpanel"
                  aria-labelledby="contactsListTab"
                >
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col"></div>
                        <div className="col-auto" style={{ minWidth: 200 }}>
                          <AsyncSelect
                            isClearable
                            placeholder="Search by user"
                            key={user}
                            theme={selectColor}
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseUsers}
                            defaultValue={user}
                            onChange={(e) => {
                              setTokens([]);
                              setToken(null);
                              setUser(e);
                            }}
                          />
                        </div>
                        <div className="col-auto">
                          <span className="m-0">Starts on : </span>
                          <DateRangePicker
                            key={dateFiltersStart?.start}
                            initialSettings={{
                              startDate: moment(dateFiltersStart?.start).format(
                                "MM-DD-YYYY"
                              ),
                              endDate: moment(dateFiltersStart?.end).format(
                                "MM-DD-YYYY"
                              ),
                              linkedCalendars: true,
                              showCustomRangeLabel: true,
                              showDropdowns: true,
                              alwaysShowCalendars: true,
                              opens: "right",
                            }}
                            onApply={handleDateAppliedStart}
                          >
                            <input
                              className={`btn ${
                                dateFiltersStart ? "upload-btn" : "btn-white"
                              } ml-2`}
                              style={{ cursor: "pointer" }}
                            />
                          </DateRangePicker>
                        </div>
                        <div className="col-auto">
                          <span className="m-0">Renews on : </span>
                          <DateRangePicker
                            key={dateFilters?.start}
                            initialSettings={{
                              startDate: moment(dateFilters?.start).format(
                                "MM-DD-YYYY"
                              ),
                              endDate: moment(dateFilters?.end).format(
                                "MM-DD-YYYY"
                              ),
                              linkedCalendars: true,
                              showCustomRangeLabel: true,
                              showDropdowns: true,
                              alwaysShowCalendars: true,
                              opens: "right",
                            }}
                            onApply={handleDateApplied}
                          >
                            <input
                              className={`btn ${
                                dateFilters ? "upload-btn" : "btn-white"
                              } ml-2`}
                              style={{ cursor: "pointer" }}
                            />
                          </DateRangePicker>
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-light"
                            onClick={() => {
                              setDateFilters(null);
                              setTokens([]);
                              setToken(null);
                              setNextToken(null);
                              setUser(null);
                              setDateFiltersStart(null);
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <div className="d-flex justify-content-center m-4">
                          <img
                            src="/images/loader.svg"
                            alt="loader"
                            height={"100px"}
                          />
                        </div>
                      ) : (
                        <table className="table table-sm table-hover table-nowrap card-table">
                          <thead>
                            <tr>
                              <th>
                                <span className="text-muted">User Name</span>
                              </th>
                              <th>
                                <span className="text-muted">Subscription</span>
                              </th>
                              <th>
                                <span className="text-muted">Status</span>
                              </th>
                              <th>
                                <span className="text-muted">Price</span>
                              </th>
                              <th>
                                <span className="text-muted">Starts On</span>
                              </th>
                              <th>
                                <span className="text-muted">Renews On</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list fs-base">
                            {subscriptions.map((item) => (
                              <tr key={item?.id}>
                                <td>
                                  <span>{item?.user?.username || "-"}</span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {item?.subscription?.name}
                                  </span>
                                </td>
                                <td>
                                  {item?.status === "CANCELLED" ? (
                                    <span className="badge bg-danger-soft">
                                      Cancelled
                                    </span>
                                  ) : (
                                    <span className="badge bg-success-soft">
                                      Active
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <span className="item-title">
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(item?.subscription?.price)}
                                  </span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {item?.startAt &&
                                      formattedTime(item?.startAt)}
                                  </span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {item?.expireAt &&
                                      formattedTime(item?.expireAt)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>

                    {subscriptions.length === 0 && !loading && (
                      <p className="text-center m-3">No subscriptions</p>
                    )}
                    <Pagination
                      handleNext={handleNext}
                      handleFirst={handleFirst}
                      length={tokens.length}
                      token={token}
                      total={subscriptions?.length}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;

export const filterUsers = async (inputValue) => {
  try {
    let filter;
    if (inputValue.length !== 0) {
      filter = {
        or: [
          { username: { wildcard: "*" + inputValue + "*" } },
          { username: { matchPhrasePrefix: inputValue } },
          { name: { matchPhrasePrefix: inputValue } },
          { id: { wildcard: "*" + inputValue + "*" } },
          { email: { matchPhrasePrefix: inputValue } },
        ],
      };
    }
    let res = await API.graphql({
      query: searchUsers,
      variables: {
        limit: 1000,
        filter: filter,
        sort: { field: "username", direction: "asc" },
      },
    });
    let values = res.data.searchUsers.items.map((item) => {
      return {
        label: item?.username || item?.name || "-",
        value: item.id,
        expert: item.expert,
      };
    });
    return values;
  } catch (error) {
    console.log(error);
  }
};

export const promiseUsers = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterUsers(inputValue));
    }, 1000);
  });
