import { API } from 'aws-amplify'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import DropUpload from '../components/DropUpload'
import { fileUpload } from '../utils/utils'
import { toast } from 'react-toastify'

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
    }
  }
`;

export default function AddPost() {
    const navigate = useNavigate()
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [media, setMedia] = useState("")
    const [submitting,setSubmiting] = useState(false)

    async function handleDrop(e, file, setState) {
        try {
          e.preventDefault();
          setState(await fileUpload(file));
        } catch (error) {
          Swal.fire("Oops!", "Try again", "error");
          console.log(error);
        }
    }
    async function addPost() {
        try {
            setSubmiting(true)
            await API.graphql({query : createPost,variables:{input : {
                title,
                description,
                thumbnail : media || null,
                authorId : process.env.REACT_APP_CLARITY_USER_ID,
                postCategoryId :process.env.REACT_APP_POST_CATEGORY_ID,
                publishedAt : new Date().toISOString()
            }}})
            navigate(-1)
        } catch (error) {
            console.log(error)
            toast.error("Failed to add article !")
        }finally{setSubmiting(false)}
    }
    return (
        <div className='container'>
            <div className="header-body mt-md-5">
                <div className="align-items-center row">
                    <div className="col">
                        <h6 className="header-pretitle">Article</h6>
                        <h1 className="header-title">Add new article</h1>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-12">
                    <form
                        className="tab-content py-2"
                        id="wizardSteps"
                        onSubmit={(e) => {
                            e.preventDefault();
                            addPost()
                        }}
                    >
                        <div>
                            <div className="form-group">
                                <label className="form-label mx-1">
                                    Title <span className="text-danger">*</span>
                                </label>

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter course title"
                                    required
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label mx-1">
                                    Description <span className="text-danger">*</span>
                                </label>

                                <textarea
                                    rows="6" cols="200"
                                    className="form-control"
                                    placeholder="Enter description"
                                    required
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <DropUpload
                                name="Thumbnail"
                                state={media}
                                setState={setMedia}
                                handleDrop={handleDrop}
                                required={true}
                            />
                        </div>
                        <button className='btn btn-primary upload-btn' disabled ={submitting || !media}>{submitting ? (
                            <div
                                className="spinner-border text-white"
                                style={{
                                height: "25px",
                                width: "25px",
                                }}
                                role="status "
                            ></div>
                            ) : (
                            <span>Save</span>
                            )}</button>
                            <button className='btn btn-light ms-2' onClick={()=>navigate(-1)}>Cancel</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
