import { API } from "aws-amplify";
import moment from "moment";
import { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { formattedTime, limit, selectColor } from "../utils/utils";

const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: [SearchablePostSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        author {
          id
          name
          username
          media
          expert
          deleted
        }
        category {
          id
          name
        }
        reports {
          items {
            id
          }
        }
        comments {
          items {
            id
          }
        }
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        likes{
          items{
            id
          }
        }
        commentCount
        reportCount
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
const searchPostCategories = /* GraphQL */ `
  query SearchPostCategories(
    $filter: SearchablePostCategoryFilterInput
    $sort: [SearchablePostCategorySortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchPostCategories(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
      total
    }
  }
`;
const Posts = () => {
  const navigate = useNavigate();

  const [postsData, setPostsData] = useState([]);
  const [filteredPost, setFilteredPost] = useState([]);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [search,setSearch] = useState("")

  const [flagged, setFlagged] = useState(null);

  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);

  const [category, setCategory] = useState(null);
  const [dateFilters, setDateFilters] = useState(null);

  async function getData(filterType, start, end, date, category) {
    try {
      // setLoading(true)
      let filters;
      if (date) {
        filterType = "date";
        start = dateFilters.start;
        end = dateFilters.end;
      }
      if (filterType === "flag") {
        filters = { status :{eq : "FLAGGED"}};
      }
      if (filterType === "date") {
        filters = { publishedAt: { lte: end, gte: start } };
        start = new Date(start).toISOString();
        end = new Date(end).toISOString();
      }
      if (filterType === "category" || category) {
        filterType = "category";
        filters = { postCategoryId: { eq: category } };
      }
      if(search.length !== 0){
        filters = { title : { matchPhrasePrefix: search}}
      }


      let allPosts = await API.graphql({
        query: searchPosts,
        variables: {
          limit: limit,
          nextToken: nextToken,
          filter: filters,
          sort: { direction: "desc", field: "publishedAt" },
        },
      });
      setLoading(false);
      setLoadMore(false);
      setFilterLoader(false);
 
      if (allPosts.data.searchPosts.items.length === allPosts.data.searchPosts.total) {
        setToken(null);
      } else {
        setToken(allPosts.data.searchPosts.nextToken);
      }

      if (filterType) {
        setPostsData([]);
        setFilteredPost((prev) => [
          ...prev,
          ...allPosts.data.searchPosts.items,
        ]);
        return;
      }
      setToken(allPosts.data.searchPosts.nextToken);
      setPostsData([...postsData, ...allPosts.data.searchPosts.items]);
    } catch (e) {
      console.log(e);
    }
  }

  async function getCategories() {
    try {
      let res = await API.graphql({ query: searchPostCategories });
      setCategories(res.data.searchPostCategories.items);
    } catch (error) {
      console.log(error);
    }
  }

  function handleNext() {
    setLoadMore(true);
    setNextToken(token);
    setToken(null);
  }

  const handleDateApplied = (event, picker) => {
    setCategory("");
    setFilteredPost([]);
    const fromDate = Math.floor(
      moment(picker.startDate).startOf("day").valueOf()
    );
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setDateFilters({ start, end });
    setFilterLoader(true);
    getData("date", start, end);
  };


  function reset() {
    setCategory(null);
    setDateFilters(null);
    setFilterLoader(true);
    setFlagged(null);
    setToken(null);
    setNextToken(null);
    setFilteredPost([]);
    search.length === 0 && getData(null, null, null, null, null);
    setSearch("")
  }

  useEffect(() => {
    setLoadMore(true)
    let timer = setTimeout(() => {
     search !== ""  ? getData(search, null, null, dateFilters, category?.value) : getData(null, null, null, dateFilters, category?.value)
    }, 600);
    return ()=>{
      clearTimeout(timer)
    }
  }, [nextToken,search]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="mx-1 mt-4">
      <div>
        <div className="tab-content">
          <div
            className="tab-pane fade active show"
            id="tabPaneOne"
            role="tabpanel"
          >
            <div className="container-fluid">
              <div className="my-3 d-flex align-items-center gap-3">
                <input className="form-control" style={{width : "210px"}} placeholder="Search Post by Title.." value={search} onChange={(e)=>{
                  setToken(null)
                  e.target.value.length === 0 && setFilteredPost([])
                  setSearch(e.target.value)
                  }}/>
                <div style={{ minWidth: "200px" }}>
                  <Select
                    isClearable
                    required
                    theme={selectColor} 
                    value={category}
                    onChange={(e) => {
                      setDateFilters(null);
                      setFlagged(false);
                      setFilterLoader(true);
                      setFilteredPost([]);
                      setCategory(e);
                      getData("category", null, null, null, e?.value);
                    }}
                    placeholder="Filter by topics"
                    options={categories.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <DateRangePicker
                   key={dateFilters?.start}
                   initialSettings={{
                     startDate:  moment(dateFilters?.start).format("MM-DD-YYYY"),
                     endDate: moment(dateFilters?.end).format("MM-DD-YYYY"),
                      linkedCalendars: true,
                      showCustomRangeLabel: true,
                      showDropdowns: true,
                      alwaysShowCalendars: true,
                      opens: "right",
                    }}
                    onApply={handleDateApplied}
                  >
                    <input
                      className={`btn ${
                        dateFilters ? "upload-btn" : "btn-white"
                      } ml-2`}
                      style={{ cursor: "pointer" }}
                    />
                  </DateRangePicker>
                </div>
                <button
                  className={`btn ${flagged ? "upload-btn" : "btn-white"}`}
                  onClick={async () => {
                    setFilteredPost([]);
                    setDateFilters(null);
                    setCategory("");
                    setFilterLoader(true);
                    setFlagged((prev) => !prev);
                    getData(!flagged ? "flag" :null);
                  }}
                >
                  Only Flagged Post
                </button>
                <button
                  className="btn btn-light"
                  disabled={
                    dateFilters !== null ||
                    category !== null ||
                    flagged !== null || search !== ""
                      ? false
                      : true 
                  }
                  onClick={reset}
                >
                  Reset Filter
                </button>
              </div>
              {loading ? <img src="/images/loader.svg" alt="loader" className="loader" /> : (
                <div className="row">
                <div className="col-12 col-xl-8">
                  {filterLoader ? (
                    <div className="w-100 d-flex text-center">
                      <img
                        src="/images/loader.svg"
                        alt="loader"
                        width={"100px"}
                        className="mx-auto"
                      />
                    </div>
                  ) : (
                    <>
                      {postsData.length === 0 && filteredPost.length === 0 && (
                        <b> {!loadMore && "No Post Found"}</b>
                      )}
                      {filteredPost.map((post) => (
                        <div
                          className="card"
                          key={post?.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/post/${post?.id}`)}
                        >
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <Link
                                  to={`/user/${post?.author?.id}`}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div className="avatar">
                                    <img
                                      src={post?.author?.media || "/images/user.png"}
                                      alt={post?.author?.name}
                                      className="avatar-img rounded-circle"
                                    />
                                  </div>
                                </Link>
                              </div>
                              <div className="col ms-n3">
                                <h4 className="mb-1 text-dark">
                                  {post?.author?.username || post?.author?.name || "-"}{post?.author?.expert && <span  className="text-warning fs-2">*</span>}
                                  {post?.author?.deleted === true &&  <span className="item-score badge bg-danger mb-1">Deleted</span>}
                                </h4>

                                <p className="small text-muted card-text d-flex gap-2">
                                  <time dateTime="2018-05-24">
                                    {formattedTime(post?.publishedAt || post?.createdAt)}
                                  </time>
                                  {post?.status === "FLAGGED" && (
                                    <span className="item-score badge bg-danger">
                                    Flagged
                                    </span>
                                    )}
                                </p>
                              </div>
                            </div>

                            <strong className="mt-2 mb-1">{post?.title}</strong>
                            <p className="badge rounded px-2 mb-0 post_badge ms-2">
                              {post?.category?.name}
                            </p>
                            <p className="mb-3 small">{post?.description}</p>
                            {post?.thumbnail && <img src={post?.thumbnaiil}/>}
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="mb-2"
                            >
                              <Carousel
                                autoPlay={true}
                                infiniteLoop={true}
                                interval={4000}
                                showThumbs={false}
                              >
                                {JSON.parse(post?.attachments)?.map(
                                  (item, index) => (
                                    <div className="text-center" key={index}>
                                      {item.type === "Image" ? (
                                        <img
                                          loading="lazy"  src={ item?.url || "/images/randomImage.jpg"}
                                          style={{
                                            height: "500px",
                                            objectFit: "cover",
                                          }}
                                          alt="..."
                                          className="img-fluid rounded"
                                        />
                                      ) : (
                                        <video controls height={500} width={"100%"}>
                                          <source src={item?.url} type="video/mp4"></source>
                                        </video>
                                      )}
                                    </div>
                                  )
                                )}
                              </Carousel>
                            </div>

                              <div>
                                <span className="border border-1 px-2 py-1 rounded ms-2">
                                  <i className="fe fe-thumbs-up" />{" "}
                                  {post?.likes?.items?.length || 0} 
                                </span>
                                <span className="border border-1 px-2 py-1 rounded ms-2">
                                  <i className="fe fe-message-square" />{" "}
                                  {post?.comments?.items?.length || 0}
                                </span>
                                <span className="border border-1 px-2 py-1 rounded ms-2">
                                  <i className="fe fe-flag text-danger" />{" "}
                                  {post?.reports?.items?.length || 0}
                                </span>
                              </div>
                            </div>
                          </div>
                      ))}
                      {postsData.map((post) => (
                        <div
                          className="card"
                          key={post?.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/post/${post?.id}`)}
                        >
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <Link
                                  to={`/user/${post?.author?.id}`}
                                  onClick={(e) => e.stopPropagation()} >
                                  <div className="avatar">
                                    <img
                                      src={
                                        post?.author?.media ||
                                        "/images/user.png"
                                      }
                                      alt={post?.author?.name}
                                      className="avatar-img rounded-circle"
                                    />
                                  </div>
                                </Link>
                              </div>

                              <div className="col ms-n3">
                                <h4 className="mb-1 text-dark">
                                {post?.author?.username || post?.author?.name || "-"}{post?.author?.expert && <span  className="text-warning fs-2">*</span>}
                                {" "}{post?.author?.deleted === true &&  <span className="item-score badge bg-danger mb-1">Deleted</span>}
                                </h4>

                                <p className="small text-muted card-text d-flex gap-2">
                                  <time dateTime="2018-05-24">
                                    {formattedTime(post?.publishedAt || post?.createdAt)}
                                  </time>
                                  {post?.status === "FLAGGED" && (
                                    <span className="item-score badge ${data?.verified bg-danger">
                                    Flagged
                                    </span>
                                    )}
                                </p>
                              </div>
                            </div>

                            <strong className="mb-1">{post?.title}</strong>
                            <p className="badge rounded px-2 mb-0 post_badge ms-2">
                              {post?.category?.name}
                            </p>
                            <p className="mb-3 small">{post?.description?.slice(0,400)} {post?.description?.length > 400 && <span>...</span>}</p>
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="mb-2"
                            >
                              <Carousel
                                autoPlay={true}
                                infiniteLoop={true}
                                interval={4000}
                                showThumbs={false}
                              >
                              {JSON.parse(post?.attachments)?.map(
                                (item, index) => (
                                  <div className="text-center" key={index}>
                                    {item.type === "Image" ? (
                                      <img
                                        loading="lazy"
                                        src={item?.url || "/images/randomImage.jpg"}
                                        style={{
                                          height: "500px",
                                          objectFit: "cover",
                                        }}
                                        alt="post images"
                                        className="img-fluid rounded"
                                      />
                                    ) : (
                                      <video
                                        controls
                                        height={500} width={"100%"}
                                      >
                                        <source
                                          src={item?.url}
                                          type="video/mp4"
                                        ></source>
                                      </video>
                                    )}
                                  </div>
                                )
                              )}                                
                              </Carousel>
                            </div>
                            <div>
                              <span className="border border-1 px-2 py-1 rounded ms-2">
                                <i className="fe fe-thumbs-up" />{" "}
                                {post?.likes?.items?.length || 0}
                              </span>
                              <span className="border border-1 px-2 py-1 rounded ms-2">
                                <i className="fe fe-message-square" />{" "}
                                {post?.comments?.items?.length || 0}
                              </span>
                              <span className="border border-1 px-2 py-1 rounded ms-2">
                                <i className="fe fe-flag text-danger" />{" "}
                                {post?.reports?.items?.length || 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="w-100 d-flex text-center">
                        {loadMore ? (
                          <img
                            src="/images/loader.svg"
                            alt="loader"
                            width={"40px"}
                            className="mx-auto"
                          />
                        ) : (
                          <>
                            {token !== null && (
                              <button
                                className="btn btn-sm upload-btn mx-auto my-3"
                                onClick={handleNext}>
                                Load more
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="col-12 col-xl-4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-header-title">Topics</h4>
                      <Link to="/post-topics">
                        <button className="btn btn-primary btn-sm upload-btn">
                          Manage topics
                        </button>
                      </Link>
                    </div>
                    <div className="mx-4">
                      {categories.slice(0, 5).map((item) => (
                        <div
                          className="list-group list-group-flush "
                          key={item?.id}>
                          <div className="list-group-item">
                            <h4 className="">{item?.name}</h4>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Posts;