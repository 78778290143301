import {  useNavigate } from "react-router-dom";

const SingleCourse = ({ courseData }) => {
  const navigate = useNavigate();

  return (
    <tr
      onClick={(e) => {
        e.preventDefault();
        navigate(`/courses/${courseData.id}`)}
      }
      style={{ cursor: "pointer" }}
    >
      <td>
        <span className="item-title">{courseData?.title}</span>
      </td>

      <td>
        <div >
        <div className="avatar avatar-xs align-middle me-2" >
          <img
            className="avatar-img rounded-circle"
            src={courseData?.instructor?.media || "/images/user.png"}
            alt="instructor"
            onClick={(e)=>{
              e.stopPropagation();
              navigate(`/expert_detail/${courseData?.instructorId}`)
            }}
          />
        </div>
        <span
          style={{ cursor: "pointer" }}
          className="item-name text-reset"
          href="profile-posts.html"
          onClick={(e)=>{
            e.stopPropagation();
            navigate(`/expert_detail/${courseData?.instructorId}`)
          }}
        >
          {courseData?.instructor?.name || courseData?.instructor?.username || "-"}
        </span>
        </div>
      </td>

      <td className="text-center">
        <span className="item-title ">
          {courseData?.lessons?.items?.length || 0}
        </span>
      </td>
      <td className="text-center">
        <span className="item-title">{courseData?.duration || "_"}</span>
      </td>
      <td className="text-center">{courseData?.likeCount || 0}</td>
    </tr>
  );
};

export default SingleCourse;
