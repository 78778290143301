import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DropUpload from "../components/DropUpload";
import {
  createCourseCategory,
  updateCourseCategory,
} from "../graphql/mutations";
import { fileUpload } from "../utils/utils";

const AddCourseTag = () => {
  const navigate = useNavigate();

  const { catId } = useParams();

  const [name, setName] = useState("");
  const [cover, setCover] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  const addData = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await API.graphql({
        query: createCourseCategory,
        variables: {
          input: {
            name,
            cover,
            thumbnail: cover,
          },
        },
      });
      setSubmitting(false);
      navigate("/course-categories");
    } catch (e) {
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
      setSubmitting(false);
    }
  };
  const editData = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await API.graphql({
        query: updateCourseCategory,
        variables: {
          input: {
            id: catId,
            name,
            cover,
            thumbnail: cover,
          },
        },
      });
      setSubmitting(false);
      navigate("/course-categories");
    } catch (e) {
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
      setSubmitting(false);
    }
  };

  async function handleDrop(e, file, setState) {
    try {
      e.preventDefault();
      setState(await fileUpload(file));
    } catch (error) {
      Swal.fire("Oops!", "Try again", "error");
      console.log(error);
    }
  }

  async function getCategory() {
    try {
      let res = await API.graphql({
        query: getCourseCategory,
        variables: {
          id: catId,
        },
      });
      let data = res.data.getCourseCategory;
      setName(data?.name);
      setCover(data?.cover);
      setLoading(false);
    } catch (e) {
      console.log(e);
      Swal.fire("Oops !", e.message, "error");
      setLoading(false);
    }
  }

  useEffect(() => {
    catId && getCategory();
    !catId && setLoading(false);
  }, []);

  if (loading)
    return <img src="/images/loader.svg" alt="loader" className="loader" />;

  return (
    <div className="container-xl container-fluid">
      <div className="header-body mt-md-5">
        <div className="align-items-center row">
          <div className="col">
            <h6 className="header-pretitle">Course Categories</h6>
            <h1 className="header-title">
              {!catId ? "Add a category" : "Edit Category"}
            </h1>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div>
          <form
            className="tab-content py-2"
            id="wizardSteps"
            onSubmit={catId ? editData : addData}
          >
            <div>
              <label id="tag" className="form-label mx-1">
                Name <span className="text-danger">*</span>
              </label>
              <input
                id="tag"
                type="text"
                className="form-control"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="my-3">
                <DropUpload
                  name="Cover Image"
                  state={cover}
                  setState={setCover}
                  handleDrop={handleDrop}
                  required={true}
                />
              </div>

              <div className="nav row align-items-center">
                <div className="col-auto">
                  <button
                    type="submit"
                    className="btn btn-primary upload-btn"
                    disabled={submitting || !cover}
                  >
                    {submitting ? (
                      <div
                        className="spinner-border text-white"
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        role="status "
                      ></div>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  <button
                    onClick={() => navigate("/course-categories")}
                    type="button"
                    className="btn btn-light mx-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCourseTag;

export const getCourseCategory = /* GraphQL */ `
  query GetCourseCategory($id: ID!) {
    getCourseCategory(id: $id) {
      id
      name
      thumbnail
      cover
      createdAt
      updatedAt
    }
  }
`;
