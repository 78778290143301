import { API } from "aws-amplify";
import moment from "moment";
import { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Pagination from "../components/Pagination";
import { formattedTime, limit, selectColor } from "../utils/utils";
import AsyncSelect from "react-select/async";
import { promiseUsers } from "./Subscriptions";

const searchCallLogs = /* GraphQL */ `
  query SearchCallLogs(
    $filter: SearchableCallLogFilterInput
    $sort: [SearchableCallLogSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchCallLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        userId
        expertId
        callStart
        callEnd
        callDuration
        callRate
        callAmount
        payoutRate
        payoutAmount
        payoutDate
        payout
        userAudioRating
        userCallRating
        userFeedback
        userRatingAt
        expertAudioRating
        expertCallRating
        expertFeedback
        expertRatingAt
        expert {
          name
          username
        }
        user {
          name
          username
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

const CallHistory = () => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [dateFilters, setDateFilters] = useState(null);
  const [user, setUser] = useState(null);

  async function getData() {
    setLoading(true);
    try {
      let filter;
      if (user) {
        filter = { userId: { eq: user?.value } };
      }
      if (dateFilters) {
        filter = {
          createdAt: {
            lte: dateFilters?.end,
            gte: dateFilters?.start,
          },
        };
      }
      if (dateFilters && !user.expert) {
        filter = {
          createdAt: {
            lte: dateFilters?.end,
            gte: dateFilters?.start,
          },
          userId: { eq: user?.value },
        };
      }
      if (dateFilters && user.expert) {
        filter = {
          createdAt: {
            lte: dateFilters?.end,
            gte: dateFilters?.start,
          },
          expertId: { eq: user?.value },
        };
      }

      let res = await API.graphql({
        query: searchCallLogs,
        variables: {
          limit: 1000,
          filter: filter,
          nextToken: nextToken,
          sort: { direction: "desc", field: "createdAt" },
        },
      });

      setLoading(false);
      setToken(res.data.searchCallLogs.nextToken);
      if (
        res.data.searchCallLogs.items.length === 0 &&
        dateFilters === null &&
        user === null
      ) {
        return;
      }
      setLogs(res.data.searchCallLogs.items);
      setTokens((prev) => [...prev, res.data.searchCallLogs.nextToken]);
    } catch (error) {
      console.log(error);
    }
  }

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    setNextToken(null);
    setToken(null);
  }

  const handleDateApplied = (event, picker) => {
    setTokens([]);
    const fromDate = Math.floor(
      moment(picker.startDate).startOf("day").valueOf()
    );
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setDateFilters({ start, end });
  };

  useEffect(() => {
    getData();
  }, [nextToken, dateFilters, user]);

  return (
    <>
      <div className="container-fluid my-5">
        {/* header starts */}
        <div className="row align-items-center">
          <div className="col">
            <h6 className="header-pretitle">Overview</h6>

            <h1 className="header-title text-truncate">Call History</h1>
          </div>
        </div>
        {/* header ends */}
        <div className="recommended-experts-container-content">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="contactsListPane"
                  role="tabpanel"
                  aria-labelledby="contactsListTab"
                >
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col"></div>
                        <div className="col-auto" style={{ minWidth: 200 }}>
                          <AsyncSelect
                            placeholder="Search by user / expert"
                            key={user}
                            theme={selectColor}
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseUsers}
                            defaultValue={user}
                            onChange={(e) => {
                              setTokens([]);
                              setToken(null);
                              setUser(e);
                            }}
                          />
                        </div>
                        <div className="col-auto">
                          <DateRangePicker
                            key={dateFilters?.start}
                            initialSettings={{
                              startDate: moment(dateFilters?.start).format(
                                "MM-DD-YYYY"
                              ),
                              endDate: moment(dateFilters?.end).format(
                                "MM-DD-YYYY"
                              ),
                              linkedCalendars: true,
                              showCustomRangeLabel: true,
                              showDropdowns: true,
                              alwaysShowCalendars: true,
                              opens: "right",
                            }}
                            onApply={handleDateApplied}
                          >
                            <input
                              className={`btn ${
                                dateFilters ? "upload-btn" : "btn-white"
                              } ml-2`}
                              style={{ cursor: "pointer" }}
                            />
                          </DateRangePicker>
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-light"
                            onClick={() => {
                              setDateFilters(null);
                              setTokens([]);
                              setToken(null);
                              setUser(null);
                              setNextToken(null);
                            }}
                          >
                            Reset Filter
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <div className="d-flex justify-content-center m-4">
                          <img
                            src="/images/loader.svg"
                            alt="loader"
                            height={"100px"}
                          />
                        </div>
                      ) : (
                        <table className="table table-sm table-hover table-nowrap card-table">
                          <thead>
                            <tr>
                              <th>
                                <span className="text-muted">User Name</span>
                              </th>
                              <th>
                                <span className="text-muted">Expert Name</span>
                              </th>
                              <th>
                                <span className="text-muted">Amount</span>
                              </th>
                              <th>
                                <span className="text-muted">Duration</span>
                              </th>
                              <th>
                                <span className="text-muted">Start</span>
                              </th>
                              <th>
                                <span className="text-muted">End</span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">
                                  Expert Rating
                                </span>
                              </th>
                              <th className="text-center">
                                <span className="text-muted">User Rating</span>
                              </th>
                              <th>
                                <span className="text-muted">
                                  Payout Amount
                                </span>
                              </th>
                              <th>
                                <span className="text-muted">Payout Date</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list fs-base">
                            {logs.map((item) => (
                              <tr key={item?.id}>
                                <td>
                                  <span>
                                    {item?.user?.username ||
                                      item?.user?.name ||
                                      "_"}
                                  </span>
                                </td>

                                <td>
                                  <span className="item-title">
                                    {item?.expert?.username ||
                                      item?.expert?.name ||
                                      "_"}
                                  </span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(item?.callAmount)}
                                  </span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {item?.callDuration
                                      ? item?.callDuration > 1
                                        ? item?.callDuration + " mins"
                                        : item?.callDuration + " min"
                                      : "-"}
                                  </span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {(item?.callStart || item?.createdAt) &&
                                      formattedTime(
                                        item?.callStart || item?.createdAt
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {(item?.callEnd || item?.createdAt) &&
                                      formattedTime(
                                        item?.callEnd || item?.createdAt
                                      )}
                                  </span>
                                </td>
                                <td>
                                  {item?.expertCallRating && (
                                    <div
                                      className="item-title d-flex gap-2 align-items-center justify-content-center"
                                      title={
                                        item?.expertFeedback || "No Feedback"
                                      }
                                    >
                                      {item?.expertCallRating}{" "}
                                      <i className="fe fe-message-circle"></i>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <span className="item-title">
                                    {item?.userCallRating && (
                                      <div
                                        className="item-title d-flex gap-2 align-items-center justify-content-center"
                                        title={
                                          item?.userFeedback || "No Feedback"
                                        }
                                      >
                                        {item?.userCallRating}{" "}
                                        <i className="fe fe-message-circle"></i>
                                      </div>
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(item?.payoutAmount)}
                                  </span>
                                </td>
                                <td>
                                  <span className="item-title">
                                    {item?.payoutDate &&
                                      new Date(
                                        item?.payoutDate
                                      ).toLocaleDateString()}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {logs?.length === 0 && loading === false && (
                      <p className="text-center m-3">No Calls Found </p>
                    )}
                    <Pagination
                      handleNext={handleNext}
                      handleFirst={handleFirst}
                      length={tokens.length}
                      token={token}
                      total={logs?.length}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallHistory;
