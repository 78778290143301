import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import { limit } from "../utils/utils";
import { useSelector } from "react-redux";


const searchCourseCategories = /* GraphQL */ `
  query SearchCourseCategories(
    $filter: SearchableCourseCategoryFilterInput
    $sort: [SearchableCourseCategorySortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchCourseCategories(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
      total
    }
  }
`;

const CourseTags = () => {
  const [search, setSearch] = useState("");
  const [isUpdating, setIsUpdating] = useState(true);
  const navigate = useNavigate();

  const [courseTags, setCourseTags] = useState([]);
  const [total, setTotal] = useState(0);
  const [tokens, setTokens] = useState([]);

  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);

  const user = useSelector((state) => state.userInfo.payload);
  let allowViewer = user.role.includes("VIEWER");

  function getData() {
    setIsUpdating(true);
    let filter;
    if(search.length !== 0){
      filter = { name: { matchPhrasePrefix: search } }
    }
    API.graphql({
      query: searchCourseCategories,
      variables:{
        limit,
        filter,
        nextToken
      }
    })
      .then((res) => {
        search.length === 0 && setTotal(res.data.searchCourseCategories.total);
        res.data.searchCourseCategories.nextToken &&
          setTokens((prev) => [...prev,res.data?.searchCourseCategories?.nextToken]);
        setToken(res.data.searchCourseCategories.nextToken);
        setIsUpdating(false);
        if (res.data.searchCourseCategories.items.length === 0 && search.length === 0) {
          return;
        }
        setCourseTags(res.data.searchCourseCategories.items);
      })
      .catch((e) => console.log(e));
  }

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }

  
  useEffect(() => {
    let debounce = setTimeout(() => {
      getData();
    }, 1200);
    return () => clearTimeout(debounce);
  }, [search,nextToken]);

  return (
    <div className="container-fluid" style={{ marginTop: "30px" }}>
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h6 className="header-pretitle">Categories</h6>

          <h1 className="header-title text-truncate">
            Course categories ({total})
          </h1>
        </div>
        <div className="col-auto">
          <button
            onClick={() => navigate("/add-course-category")}
            className="btn btn-primary ms-2"
            style={{
              backgroundColor: "#50C7C3",
              border: "none",
              display: allowViewer && "none",
            }}
          >
            Add Category
          </button>
        </div>
      </div>
      {/* header ends */}
      <div className="recommended-experts-container-content">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            className="form-control list-search"
                            type="search"
                            placeholder="Search"
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {isUpdating ? (
                      <div className="d-flex justify-content-center m-4">
                        <img
                          src="/images/loader.svg"
                          alt="loader"
                          height={"100px"}
                        />
                      </div>
                    ) : (
                      <table className="table table-sm table-hover table-nowrap card-table">
                        <thead>
                          <tr>
                            <th>
                              <span className="text-muted">Name</span>
                            </th>

                            <th>
                              <span className="text-muted"></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list fs-base">
                          {courseTags?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span>{item?.name}</span>
                              </td>

                              <td className="text-end">
                                <div className="dropdown">
                                  <span
                                    className="dropdown-ellipses dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical"></i>
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <span
                                      onClick={() =>
                                        navigate(
                                          `/edit-course-category/${item?.id}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="dropdown-item"
                                    >
                                      Edit
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                     {courseTags.length === 0 && !isUpdating && (
                    <p className="text-center m-3">No post topic</p>)}
                  </div>
                    <Pagination
                    handleNext={handleNext}
                    handleFirst={handleFirst}
                    length={tokens.length}
                    token={token}
                    total={courseTags?.length}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseTags;
