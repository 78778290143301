import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { fileUpload } from "../utils/utils";
import Swal from "sweetalert2";
import DropVideo from "../components/DropVideo";

const StreamVideoSettings = () => {
  const [streamTitle, setStreamTitle] = useState();
  const [urlToVideo, setUrlToVideo] = useState();
  const [multistreamingEnabled, setMultistreamingEnabled] = useState(false);

  const [sendPush, setSendPush] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("Clarity TV");
  const [notificationMessage, setNotificationMessage] = useState(
    "We are live now! Join us!"
  );
  const navigate = useNavigate();

  const startStream = () => {
    navigate("/stream-room", {
      state: {
        title: streamTitle,
        urlToVideo,
        sendPush,
        notificationTitle,
        notificationMessage,
        multistreamingEnabled,
      },
    });
  };

  async function handleDrop(e, file, setState) {
    try {
      e.preventDefault();
      setState(await fileUpload(file));
    } catch (error) {
      Swal.fire("Oops!", "Try again", "error");
      console.log(error);
    }
  }

  return (
    <div className="container-xl container-fluid mt-5 pb-5">
      <div className="d-flex flex-row align-items-center justify-content-center">
        <h1 className="header-title text-truncate">Stream Video Settings</h1>
      </div>
      <div className="mt-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            startStream();
          }}
        >
          <div className="form-group">
            <label className="form-label mx-1 fs-3">
              <b>
                Title <span className="text-danger">*</span>
              </b>
            </label>
            <input
              required
              type="text"
              className="form-control"
              placeholder="Enter stream title"
              value={streamTitle}
              onChange={(e) => setStreamTitle(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label className="form-label mx-1 fs-3">
              <b>
                Video <span className="text-danger">*</span>
              </b>
            </label>
            <DropVideo
              videoOnly
              type="mp4"
              accept="video/*"
              state={urlToVideo}
              setState={setUrlToVideo}
              handleDrop={handleDrop}
            />
            {urlToVideo && (
              <p className="mx-1">
                Url to video:{" "}
                <a href={urlToVideo} target="__blank">
                  {urlToVideo}
                </a>
              </p>
            )}
          </div>

          <div className="form-group">
            <label className="form-label mx-1 fs-3">
              <b>Multistreaming</b>
            </label>
            <p className="mx-1">
              Reach more viewers by live streaming to multiple platforms
              simultaneously
            </p>
            <div className="d-flex flex-row justify-content-between bg-white p-4 rounded-4">
              <div>
                <b>Enable Multistreaming</b>
              </div>
              <Switch
                onColor="#50c7c3"
                onChange={() =>
                  setMultistreamingEnabled(!multistreamingEnabled)
                }
                checked={multistreamingEnabled}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label mx-1 fs-3">
              <b>Notification</b>
            </label>
            <p className="mx-1">Notify Customers about stream</p>
            <div className="bg-white p-4 rounded-4">
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <b>Send Notification</b>
                </div>
                <Switch
                  onColor="#50c7c3"
                  onChange={() => setSendPush(!sendPush)}
                  checked={sendPush}
                />
              </div>
              {sendPush && (
                <div>
                  <div className="form-group mt-3">
                    <label className="form-label mx-1">Title</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      value={notificationTitle}
                      onChange={(e) => setNotificationTitle(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label mx-1">Message</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Message"
                      value={notificationMessage}
                      onChange={(e) => setNotificationMessage(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="nav row align-items-center">
            <div className="col-auto">
              <button
                type="submit"
                style={{ background: "#50c7c3", border: "none" }}
                className="btn btn-primary"
                disabled={!streamTitle || !urlToVideo}
              >
                <span>Start stream</span>
              </button>
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="btn btn-light mx-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StreamVideoSettings;
