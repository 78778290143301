import { API } from 'aws-amplify';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from "react-select/async";
import { selectColor, timeFormatter } from '../utils/utils';
import { promiseUsers } from "./Subscriptions";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        name
        expert
        deleted
      }
      nextToken
      total
    }
  }
`;

const searchCallLogs = /* GraphQL */ `
  query SearchCallLogs(
    $filter: SearchableCallLogFilterInput
    $sort: [SearchableCallLogSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCallLogAggregationInput]
  ) {
    searchCallLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        createdAt
        callAmount
        expert {
          username
          name
          expertType {
            name
          }
          media
        }
        id
        user {
          name
          username
          media
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            __typename
            value
          }
        }
      }
      __typename
    }
  }
`;

const searchUserHotlineCredits = /* GraphQL */ `
  query SearchUserHotlineCredits(
    $filter: SearchableUserHotlineCreditsFilterInput
    $sort: [SearchableUserHotlineCreditsSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchUserHotlineCredits(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        userId
        user{
          id
          username
          name
        }
        description
        credits
      }
      nextToken
      total
    }
  }
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export default function Dashboard() {
  const [users,setUsers] = useState({new:null,active:null,total:null,blocked:null})
  const [expert,setExpert] = useState({active:null,blocked:null,total:null,online:null})
  const [calls,setCalls] = useState({today:null,total:null})
  const [callInfo,setCallInfo] = useState([])
  const [user,setUser] = useState(null)
  const [transactions,setTransactions] = useState([])
  const [revenue,setRevenue] = useState({call:null,payout:null})
  const [avgRevenue,setAvgRevenue] = useState([])
  const [dateFilters, setDateFilters] = useState({start : moment().subtract(30, 'days').format().split("T")[0],end : moment().add(1, 'days').format().split("T")[0]});
  const [revenueDateFilter, setRevenueDateFilter] = useState({start : moment().subtract(30, 'days').format().split("T")[0],end : moment().add(1, 'days').format().split("T")[0]});
  const navigate = useNavigate();
  
  const transactionData = {
    labels:transactions.map(item=>item?.createdAt?.split("T")[0]?.substring(5).split("-").join("/")),
    datasets: [
      {
        label: 'Total credit usage',
        data: transactions.map(item=>item?.total),
        borderColor: "#50c7c3",
        backgroundColor: "#50c7c3",
        borderRadius: 4,
      },
    ],
  };

  const revenueData = {
    labels:avgRevenue.map(item=>item?.createdAt?.split("T")[0]?.substring(5).split("-").join("/")),
    datasets: [
      {
        label: 'Revenue per Day',
        data: avgRevenue.map(item=>item?.total),
        borderColor: "#50c7c3",
        backgroundColor: "#50c7c3",
        borderRadius: 4,
      },
    ],
  };

  async function getUserInfo(){
    try {
      let newUsers = await API.graphql({
        query: searchUsers,
        variables: {
          filter: {createdAt : {gte : moment().startOf('day').format().slice(0,19)} },
        },
      });
      let active = await API.graphql({
        query: searchUsers,
        variables: {
          filter: {active : {eq : true},deleted : { ne: true} },
        },
      });
      let blocked = await API.graphql({
        query: searchUsers,
        variables: {
          filter: {active : {eq : false},deleted : { ne: true}},
        },
      });
      let total = await API.graphql({
        query: searchUsers,
      });
      setUsers({...users,new : newUsers?.data?.searchUsers?.total ,active : active?.data?.searchUsers?.total ,blocked : blocked?.data?.searchUsers?.total ,total : total?.data?.searchUsers?.total ,})
    } catch (error) {
        console.log(error)
    }
  }

  async function getExpertInfo(){
    try {
      let active = await API.graphql({
        query: searchUsers,
        variables: {
          filter: {expert : {eq : true} , verified : {eq : true} ,deleted : {ne : true}},
        },
      });
      let blocked = await API.graphql({
        query: searchUsers,
        variables: {
          filter: {expert : {eq : true} , verified : {eq : false}, deleted : {ne : true} },
        },
      });
      
      let total = await API.graphql({query : searchUsers, 
        variables : { filter : {expert : {eq : true}, deleted : {ne : true}}}
      })


      let online = await API.graphql({
        query: searchUsers,
        variables: {
          filter: {expert : {eq : true},online : {eq : true}, deleted : {ne : true},verified : {eq : true}},
        },
      });
      setExpert({...expert , active : active?.data?.searchUsers?.total,blocked:blocked?.data?.searchUsers?.total,total:total?.data?.searchUsers?.total,
        online:online?.data?.searchUsers?.total})
    } catch (error) {
      console.log(error)
    }
   
  }

  async function getCallDetails(){
    try {
      let todays = await API.graphql({
        query: searchCallLogs,
        variables: {filter: {createdAt : {gte : moment().startOf('day').format().slice(0,19)} }},
      });
      let total = await API.graphql({
        query: searchCallLogs,variables:{sort : {field : "createdAt", direction : "desc"}}
      });
      setCallInfo(total?.data?.searchCallLogs?.items)
      setCalls({...calls,today : todays?.data?.searchCallLogs?.total,total : total?.data?.searchCallLogs?.total})
    } catch (error) {
        console.log(error)
    }
  }

  async function getAvgData(query,item,setState,type,start,end){
    try {
      let filter =  {createdAt: {gte: start || moment().subtract(30, 'days').format().slice(0,19),
      lte: end || moment().startOf('day').format().slice(0,19)}};
      if(user){
        filter  = {userId : {eq : user?.value}}
      }
      if(dateFilters && user){
        filter = {createdAt: {gte: start , lte: end },userId : {eq : user?.value} }
      }
      let nextToken;
      let arr = []
      do {
        let res = await API.graphql({
          query: query,
          variables:{
            filter : filter,
            limit:1000,
            nextToken:nextToken
          }
        })
        arr = [...arr,...res.data[type].items]
        nextToken = res.data[type].nextToken
      } while (nextToken);

      const result = arr.reduce((acc, curr) => {
        const date = curr.createdAt.split('T')[0];
        if (!acc[date]) {
            acc[date] = {createdAt: curr.createdAt.split('T')[0], total: 0};
        }
        // acc[date].total += curr[item] || 0;
       if(item === "credits") {
          if(curr[item] < 0){
            acc[date].total -= curr[item] || 0;
          }
       }else{
        acc[date].total += curr[item] || 0;
       }
        return acc;
      }, {});

      let data = Object.values(result)
      const startDate = moment(start ) ;
      const endDate = moment(end)
      for (let m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
        if(data?.find(item=>item?.createdAt)?.createdAt !== m.format().split("T")[0]){
          data.push({
            createdAt: m.format().split("T")[0],
            total: 0
        });
        }
      }
      data =  data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      const filteredArray = [];
      const seenDates = new Set();
      for (const obj of data) {
        const { createdAt, total } = obj;
        if (total !== 0 || !seenDates.has(createdAt)) {
          filteredArray.push(obj);
          seenDates.add(createdAt);
        }
      }
      setState(filteredArray); 
    } catch (error) {
        console.log(error)
    }
  }

  async function getRevenueDetails(){
    let nextToken = null
    // let sum = 0
    // do {
      try {
        let res = await API.graphql({query : searchCallLogs, variables : {aggregates: {field: "payoutAmount", name: "payout", type: "sum"},nextToken}})
        let resCall = await API.graphql({query : searchCallLogs, variables : {aggregates: {field: "callAmount", name: "call", type: "sum"},nextToken}})
        setRevenue({...revenue,payout : res.data.searchCallLogs.aggregateItems[0]?.result?.value,call : resCall.data.searchCallLogs.aggregateItems[0]?.result?.value,})
        nextToken = res?.data?.searchCallLogs?.nextToken
        // sum = 
        // console.log(nextToken)
      } catch (error) {
        console.log(error)
      }
    // } while (nextToken);
   
  }

  const handleDateApplied = (event, picker) => {
    const fromDate = Math.floor(
      moment(picker.startDate).startOf("day").valueOf()
    );
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setDateFilters({ start, end });
  };
  const handleDateAppliedRevenue = (event, picker) => {
    const fromDate = Math.floor(
      moment(picker.startDate).startOf("day").valueOf()
    );
    const toDate = Math.floor(moment(picker.endDate).endOf("day").valueOf());
    let start = new Date(fromDate).toISOString();
    let end = new Date(toDate).toISOString();
    setRevenueDateFilter({ start, end });
  };

  useEffect(() => {
    getUserInfo()
    getExpertInfo()
    getCallDetails()
    getRevenueDetails()
  }, [])


  useEffect(() => {
    setTransactions([])
    getAvgData(searchUserHotlineCredits,"credits",setTransactions,"searchUserHotlineCredits",dateFilters?.start,dateFilters?.end)
  }, [user,dateFilters])
  
  useEffect(() => {
    setAvgRevenue([])
    getAvgData(searchCallLogs,"callAmount",setAvgRevenue,"searchCallLogs",revenueDateFilter?.start,revenueDateFilter?.end)
  }, [revenueDateFilter])
  


  return (
  <div className="main-content">
    <div className="header  pb-5">
      <div className="container-fluid">
        <div className="header-body">
          <div className="align-items-end row">
            <div className="col">
              <h6 className="header-pretitle text-secondary">Clarity</h6>
              <h1 className="header-title">Dashboard</h1>
            </div>
            <div className="col-auto" style={{minWidth:200}}>
              <AsyncSelect 
                  isClearable
                  placeholder="Search by user"
                  key={user}
                  theme={selectColor}  
                  cacheOptions defaultOptions loadOptions={promiseUsers} 
                  defaultValue={user}
                  onChange={(e)=>{
                    setUser(e)}}/>
            </div>
            <div className="col-auto" style={{minWidth:200}}>
              <DateRangePicker
                key={dateFilters?.start}
                initialSettings={{
                  startDate:  moment(dateFilters?.start).format("MM-DD-YYYY"),
                  endDate: moment(dateFilters?.end).format("MM-DD-YYYY"),
                  linkedCalendars: true,
                  showCustomRangeLabel: true,
                  showDropdowns: true,
                  alwaysShowCalendars: true,
                  opens: "right",
                }}
                onApply={handleDateApplied}
              >
                <input
                  className={`btn btn-white ml-2`}
                  style={{ cursor: "pointer" }}
                />
              </DateRangePicker>
            </div>
            <div className='col-auto'>
            <button className="btn btn-light" onClick={()=>{
              setUser(null)
              setDateFilters({start : moment().subtract(30, 'days').format().split("T")[0],end : moment().startOf('day').format().split("T")[0]})
            }}>Reset</button>
            </div>
          </div>
        </div>
        <div className="header-footer ">
          <div style={{height:"400px"}} className='row'>
          {transactions?.length === 0 ? <img src={"/images/loader.svg"} className='text-center' height={100}/> :<Bar options={options} data={transactionData} />}
          </div>
        </div>
      </div>
    </div>
 
   <div className="mt-n6 container-fluid">
    <div className="row">
      <div className="col-xl col-md-6 col-12">
        <div className="card">
          <div className="card-body">
            <div className="align-items-center row">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">Online Experts <img src={"/images/ping.gif"} alt="ping" height={16} /></h6>
                <span className="h2 mb-0">{expert?.online !== null ? expert?.online : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl col-md-6 col-12">
        <div className="card">
          <div className="card-body">
            <div className="align-items-center row">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">Active Experts</h6>
                <span className="h2 mb-0">{expert?.active !== null ? expert?.active : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl col-md-6 col-12">
        <div className="card">
          <div className="card-body">
            <div className="align-items-center row">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">Blocked Experts</h6>
                <span className="h2 mb-0">{expert?.blocked !== null ? expert?.blocked : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl col-md-6 col-12">
        <div className="card">
          <div className="card-body">
            <div className="align-items-center row">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">Total Experts</h6>
                <span className="h2 mb-0">{expert?.total !== null ? expert?.total : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="row">
        <div className="col-xl col-md-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="align-items-center row">
                <div className="col">
                  <h6 className="text-uppercase text-muted mb-2">New Users (Onboarded Today)</h6>
                  <span className="h2 mb-0">{users?.new !== null ? users?.new : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="align-items-center row">
                <div className="col">
                  <h6 className="text-uppercase text-muted mb-2">Active Users</h6>
                  <span className="h2 mb-0">{users?.active !== null ? users?.active : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="align-items-center row">
                <div className="col">
                  <h6 className="text-uppercase text-muted mb-2">Blocked Users</h6>
                  <span className="h2 mb-0">{users?.blocked !== null ? users?.blocked : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="align-items-center row">
                <div className="col">
                  <h6 className="text-uppercase text-muted mb-2">Total Users</h6>
                  <span className="h2 mb-0">{users?.total !== null ? users?.total : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div className="row">
      <div className="col-xl-4 col-12">
        <div className="card-adjust-xl">
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">Recent Calls <span className="text-muted"> (Total : {calls?.total || 0})</span></h4>
              <span className="small" style={{cursor:"pointer"}} onClick={()=>navigate("/callhistory")}>
                View all
              </span>
            </div>
            <div className="card-body">
                <div className="list-group-flush list-group-activity my-n3 list-group">
                {callInfo?.length === 0 ?  <img src={"/images/loader.svg"} className='text-center' height={100}/> :(
                <>
                  {callInfo?.slice(0,10)?.map(item=>(
                    <div className="list-group-item" key={item?.id}>
                    <div className="row">
                      <div className="col-auto">
                        <div className="avatar avatar-sm">
                          <img
                            className="avatar-img rounded-circle"
                            src={item?.user?.media || '/images/user.png'}
                            alt="avatar"
                          />
                        </div>
                      </div>
                      <div className="ms-n2 col">
                        <div className="small">
                          <strong>{item?.user?.username || item?.user?.name || "-"}</strong> 
                          <i className='fe fe-phone-call m-2'></i>
                          <strong>{item?.expert?.username || item?.expert?.name || "-"}</strong> 
                        </div>
                        <small className="text-muted">{timeFormatter(item?.createdAt)} ago</small> 
                        <p className="badge rounded px-2 mb-0 post_badge ms-2">{item?.expert?.expertType?.name}</p>
                      </div>
                    </div>
                  </div> ))}
                </>
                )}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title">Financials</h4>
             <div style={{minWidth:200}}>
              <DateRangePicker
                key={revenueDateFilter?.start}
                initialSettings={{
                  startDate:  moment(revenueDateFilter?.start).format("MM-DD-YYYY"),
                  endDate: moment(revenueDateFilter?.end).format("MM-DD-YYYY"),
                  linkedCalendars: true,
                  showCustomRangeLabel: true,
                  showDropdowns: true,
                  alwaysShowCalendars: true,
                  opens: "right",
                }}
                onApply={handleDateAppliedRevenue}
              >
                <input
                  className={`btn btn-white ml-2`}
                  style={{ cursor: "pointer" }}
                />
              </DateRangePicker>
            </div>
            <button className="btn btn-light ms-3" onClick={()=>{
              setUser(null)
              setRevenueDateFilter({start : moment().subtract(30, 'days').format().split("T")[0],end : moment().startOf('day').format().split("T")[0]})
            }}>Reset</button>
          </div>
          <div className='row ps-4 pt-4 pe-4'>
            <div className="col-xl col-md-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="align-items-center row">
                    <div className="col">
                      <h6 className="text-uppercase text-muted mb-2">Total Earned</h6>
                      <span className="h2 mb-0">{revenue?.call ? new Intl.NumberFormat("en-US", {
                        style: "currency",currency: "USD",}).format(revenue?.call - revenue?.payout) : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl col-md-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="align-items-center row">
                    <div className="col">
                      <h6 className="text-uppercase text-muted mb-2">Total Payout</h6>
                      <span className="h2 mb-0">{revenue?.payout ? new Intl.NumberFormat("en-US", {
                        style: "currency",currency: "USD",}).format(revenue?.payout) : <img src={"/images/dashLoader.svg"} height={10}/>}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <div style={{height:"400px"}} className='p-4 row'>
          {avgRevenue?.length === 0 ? <img src={"/images/loader.svg"} className='text-center' height={100}/> :<Bar options={options} data={revenueData} />}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>)}


export const options = {
  maintainAspectRatio: false,
  animation: {
      delay: (context) => {
          let delay = 0;
          if (context.type === 'data' && context.mode === 'default') {
              delay = context.dataIndex * 20 + context.datasetIndex * 10;
          }
          return delay;
      },
  },
  plugins: {
    datalabels: {
      display: false,
      align:"top",
      anchor:"end",
    },
      title: { display: false },
      legend: { display: true },
  },
  scale: {
      scaleLabel: {
          fontColor: 'red'
      }
  },
  scales: {
      x: {
          stacked: true,
          grid: { display: false, },
          ticks: {}
      },
      y: {
          stacked: true,
          grid: {},
          grace:"10%",
          ticks: {
              borderDash: [5, 5],
              precision: 0,
          },
      },
  },
};